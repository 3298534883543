import { useRef } from 'react';
import { Button, Form } from 'antd';
import { FormsInputEmpresa } from './../../../../../components/FormsInputsEmpresa';
import './style.css';

interface IntefEmpresas {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "ativo": boolean,
    "nome": string,
    "cnpj": string,
    "telefone": string,
    "bairro": string | null,
    "cep": string | null,
    "cidade": string | null,
    "logradouro": string | null,
    "numero": string | null,
    "limite_diferenca_validacao_km": string | number | null,
    "uf": string | null,
    "deletedAt": string | null
}

type interfOpcaoScreen = 'visualizar' | 'editar';

interface interPros {
    empresaSelecionado?: IntefEmpresas,
    alterOpcaoScreen: (opcao: interfOpcaoScreen) => void;
}

export const VisualizarDadosEmpresa = ({ empresaSelecionado, alterOpcaoScreen }: interPros) => {

    const refForm = useRef<any>(null);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    // justifyContent: 'flex-end',
                    // justifyContent: 'flex-end'
                    marginBottom: 15
                }}
            >
                <Button
                    type="default"
                    style={{ margin: 2 }}
                    className="Empresas_BotaoEditar_aergfihbi"
                    onClick={() => {alterOpcaoScreen('editar')}}
                >
                    Editar empresa
                </Button>
            </div>

            <Form
                ref={refForm}
                name="adicionarUsuario"
                layout="vertical"
                // onFieldsChange={onFieldsChange}
                initialValues={{
                    remember: true,
                }}
                // onFinish={onFinish}
                // onFinishFailed={() => { }}
                autoComplete="off"
            >
                <FormsInputEmpresa
                    empresaSelecionado={empresaSelecionado}
                    editarModulos
                    refForm={refForm}
                    readonly={true}
                />
            </Form>
        </>
    );
}
