import { useCallback, useEffect, useState } from 'react';
import {
    Typography, Form, Row, Col, Input, notification, Tree, Divider
} from 'antd';
import type { DataNode } from 'antd/es/tree';
import { mask, unMask } from '../../utils/MascaraDeCampos';

import { colInputs } from './GridStyle';
import { EmitirErro } from '../../services/EmitirErro';
import { api } from '../../services/apiAxios';
import { urlEmpresasModulo, urlEmpresasModuloModulos } from '../../services/urls';
import { getToken } from '../../services/LStorage/token';

const { Text } = Typography;

interface IntefReqEditarModulos {
    "id": string,
    "ativo": boolean,
    "modulo": {
        "id": string,
        "nome": string,
        "descricao": string
    }
}
interface IntefReqModulos {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "nome": string,
    "descricao": string
}
interface IntefEmpresas {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "ativo": boolean,
    "nome": string,
    "cnpj": string,
    "telefone": string,
    "bairro": string | null,
    "cep": string | null,
    "cidade": string | null,
    "logradouro": string | null,
    "numero": string | null,
    "limite_diferenca_validacao_km": string | number | null,
    "uf": string | null,
    "deletedAt": string | null
}
interface interPros {
    empresaSelecionado?: IntefEmpresas,
    refForm: any,
    readonly: boolean,
    editarModulos?: boolean,
}

export const FormsInputEmpresa = ({ empresaSelecionado, refForm, readonly, editarModulos }: interPros) => {

    const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
    const [treeData, setTreeData] = useState<DataNode[]>([]);

    //setando dados das cidades no selected
    const buscaDadosChecked = useCallback(() => {

        const funcBusca = async () => {
            try {
                let result = await api.get(urlEmpresasModulo,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                const requisicaoModulos: Array<IntefReqModulos> = result.data

                let objFormatedReq: Array<{
                    title: string,
                    key: string
                }> = []
                let arrDefaulChecked: Array<string> = []

                requisicaoModulos.forEach(element => {

                    objFormatedReq.push({
                        title: element.descricao,
                        key: element.id
                    })

                    arrDefaulChecked.push(element.id)

                });

                let objChecksd = [
                    {
                        title: 'Todos',
                        key: 'todos',
                        children: objFormatedReq,
                    }
                ]

                setTreeData(objChecksd)

                refForm.current?.setFieldsValue(
                    {
                        todosModulos: result.data,
                    }
                )

                if (editarModulos) {

                    let arrDefaulCheckedEditar: Array<string> = []

                    let resultEditar = await api.get(urlEmpresasModuloModulos + "/" + empresaSelecionado?.id,
                        {
                            headers: {
                                'Authorization': 'Bearer ' + getToken()
                            }
                        });

                    refForm.current?.setFieldsValue(
                        {
                            todosModulosEditar: resultEditar.data,
                        }
                    )

                    const reqModulos: Array<IntefReqEditarModulos> = resultEditar.data

                    reqModulos.forEach(element => {

                        if (element.ativo) {
                            arrDefaulCheckedEditar.push(element.modulo.id)
                        }

                    });

                    refForm.current?.setFieldsValue(
                        {
                            modulos: arrDefaulCheckedEditar,
                        }
                    )
                    setCheckedKeys(arrDefaulCheckedEditar);

                } else {
                    //setDefautl
                    refForm.current?.setFieldsValue(
                        {
                            modulos: arrDefaulChecked,
                        }
                    )
                    setCheckedKeys(arrDefaulChecked);
                }




            } catch (error) {
                EmitirErro(error, true, 'usuarios_dkwdfaiweyi')

            }
        }

        funcBusca();

    }, [editarModulos, empresaSelecionado]);

    useEffect(() => {

        buscaDadosChecked();

    }, [empresaSelecionado]);

    //setando dados das cidades no selected
    const preencherDadosEmpresas = useCallback((objEmpresasfunc: IntefEmpresas) => {

        const buscaCiaddes = async () => {
            try {
                // setLoading(true)

                refForm.current?.setFieldsValue(
                    {
                        nome: objEmpresasfunc.nome ? objEmpresasfunc.nome : undefined,
                        bairro: objEmpresasfunc.bairro ? objEmpresasfunc.bairro : undefined,
                        cep: objEmpresasfunc.cep ? objEmpresasfunc.cep : undefined,
                        cidade: objEmpresasfunc.cidade ? objEmpresasfunc.cidade : undefined,
                        logradouro: objEmpresasfunc.logradouro ? objEmpresasfunc.logradouro : undefined,
                        numero: objEmpresasfunc.numero ? objEmpresasfunc.numero : undefined,
                        limite_diferenca_validacao_km: objEmpresasfunc.limite_diferenca_validacao_km ? objEmpresasfunc.limite_diferenca_validacao_km : undefined,
                        uf: objEmpresasfunc.uf ? objEmpresasfunc.uf : undefined
                    }
                );

                if (objEmpresasfunc.telefone) {
                    const originalValue = unMask(objEmpresasfunc.telefone);
                    const maskedValue = mask(originalValue, [
                        "(99) 9999-9999",
                        "(99) 9 9999-9999"
                    ]);
                    refForm.current?.setFieldsValue({
                        telefone: maskedValue
                    })
                }

                if (objEmpresasfunc.cnpj) {
                    const originalValue = unMask(objEmpresasfunc.cnpj);
                    const maskedValue = mask(originalValue, [
                        "99.999.999/9999-99"
                    ]);
                    refForm.current?.setFieldsValue({
                        cnpj: maskedValue
                    })
                }
                // setLoading(false)


            } catch (error) {
                let msgErro: any = (error as Error);

                EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')

            }
        }

        buscaCiaddes();

    }, []);

    useEffect(() => {

        if (empresaSelecionado) {
            preencherDadosEmpresas(empresaSelecionado)
        }

    }, [empresaSelecionado]);

    const onChangeInputCNPJ = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, [
            "99.999.999/9999-99"
        ]);

        refForm.current.setFieldsValue(
            { cnpj: maskedValue }
        );
        if (maskedValue.length >= 14) {
            refForm.current.validateFields(["cnpj"])
        }

    }, []);

    const onChangeInputTelefone = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, [
            "(99) 9999-9999",
            "(99) 9 9999-9999"
        ]);

        refForm.current.setFieldsValue(
            { telefone: maskedValue }
        );

        if (maskedValue.length >= 14) {
            refForm.current.validateFields(["telefone"])
        }

    }, []);

    const onChangeInputCEP = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, [
            "99.999-999"
        ]);

        refForm.current.setFieldsValue(
            { cep: maskedValue }
        );
        if (maskedValue.length >= 8) {
            refForm.current.validateFields(["cep"])
        }

    }, []);

    const buscarCEPSeJaExiste = useCallback((cep: string): Promise<boolean> => {

        const buscaDadosTipoDeContrato = async (): Promise<boolean> => {
            try {
                let result = await api.get(`https://viacep.com.br/ws/${cep}/json/`);

                if (typeof result.data === 'object' && result.data?.erro) {
                    //cep não existe
                    return true;
                } else {


                    refForm.current.setFieldsValue(
                        {
                            cidade: result.data.localidade,
                            uf: result.data.uf,
                        }
                    );
                    if (result.data.logradouro) {
                        refForm.current.setFieldsValue(
                            {
                                logradouro: result.data.logradouro,
                            }
                        );

                    }
                    if (result.data.bairro) {
                        refForm.current.setFieldsValue(
                            {
                                bairro: result.data.bairro,
                            }
                        );

                    }


                    //cep encontrado
                    return false;

                }
            } catch (error) {
                notification.error({
                    message: 'Erro',
                    description:
                        'CEP não encontrado!',
                });
                return false
            }
        }

        return buscaDadosTipoDeContrato();

    }, []);

    // useEffect(() => {

    //     refForm.current?.setFieldsValue(
    //         {
    //             modulos: checkedKeys,
    //         }
    //     )

    // }, [checkedKeys]);

    const onCheck = useCallback((checkedKeysValue: any) => {
        refForm.current?.setFieldsValue(
            {
                modulos: checkedKeysValue,
            }
        )
        setCheckedKeys(checkedKeysValue);
    }, []);

    return (
        <Row>
            <Col {...colInputs}>
                <Form.Item
                    style={{
                        margin: 5
                    }}
                    name="nome"
                    label="Nome"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor digite seu nome!',
                        },
                    ]}
                >
                    <Input
                        onChange={() => {

                            const text = refForm.current?.getFieldValue('nome')
                            const first = text.charAt(0).toUpperCase()
                            const rest = text.substring(1);

                            refForm.current?.setFieldsValue(
                                { 'nome': first + rest }
                            )

                        }}
                        readOnly={readonly}
                        autoComplete="off"
                        placeholder="Nome"
                    />
                </Form.Item>
            </Col>
            <Col {...colInputs}>
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: "Preencha o cnpj!",
                            max: 18
                        },
                        () => ({
                            validator(rule, value) {
                                return new Promise((resolve: (value?: any) => void, reject) => {
                                    //CUIDADO AO EDITAR PARA PODER ACEITAR MESMO EMAIL
                                    if (value != '' && value != null) {
                                        value = unMask(value);

                                        if (value.length >= 14) {
                                            // buscarCelularSeJaExiste(value).then(valorPromessa => {
                                            //     if (valorPromessa) {
                                            //         reject("Celular já existe!");
                                            //     } else {
                                            //         resolve();
                                            //     }
                                            // }).catch(error => {
                                            //     resolve();
                                            // })
                                            resolve();
                                        } else {
                                            reject("CNPJ inválido");
                                        }
                                    } else {
                                        reject('');
                                    }
                                });
                            },
                        }),
                    ]}
                    name="cnpj"
                    label="CNPJ"
                    style={{ margin: 5 }}
                >
                    <Input
                        // type="tel"
                        readOnly={readonly}
                        autoComplete="off"
                        onChange={(e) => onChangeInputCNPJ(e)}
                        placeholder="99.999.999/9999-99"
                    />
                </Form.Item>
            </Col>
            <Col {...colInputs}>
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: "Preencha o telefone!",
                            max: 16
                        },
                        () => ({
                            validator(rule, value) {
                                return new Promise((resolve: (value?: any) => void, reject) => {
                                    //CUIDADO AO EDITAR PARA PODER ACEITAR MESMO EMAIL
                                    if (value != '' && value != null) {
                                        value = unMask(value);
                                        if (value.length >= 10) {
                                            // buscarCelularSeJaExiste(value).then(valorPromessa => {
                                            //     if (valorPromessa) {
                                            //         reject("Celular já existe!");
                                            //     } else {
                                            //         resolve();
                                            //     }
                                            // }).catch(error => {
                                            //     resolve();
                                            // })
                                            resolve();
                                        } else {
                                            reject("Telefone inválido");
                                        }
                                    } else {
                                        reject('');
                                    }
                                });
                            },
                        }),
                    ]}
                    name="telefone"
                    label="Telefone"
                    style={{ margin: 5 }}
                >
                    <Input
                        type="tel"
                        readOnly={readonly}
                        autoComplete="off"
                        onChange={(e) => onChangeInputTelefone(e)}
                        placeholder="(44) 9 9999-9999"
                    />
                </Form.Item>
            </Col>
            <Col {...colInputs}>
                <Form.Item
                    style={{
                        margin: 5
                    }}
                    name="cep"
                    label="CEP"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor digite o cep!',
                            max: 10
                        },
                        () => ({
                            validator(rule, value) {
                                return new Promise((resolve: (value?: any) => void, reject) => {
                                    //CUIDADO AO EDITAR PARA PODER ACEITAR MESMO EMAIL
                                    if (value != '' && value != null) {
                                        value = unMask(value);

                                        if (value.length >= 8) {
                                            buscarCEPSeJaExiste(value).then(valorPromessa => {
                                                if (valorPromessa) {
                                                    // reject("CEP não encontrado!");
                                                    resolve();
                                                } else {
                                                    resolve();
                                                }
                                            }).catch((error: any) => {
                                                resolve();
                                            })
                                        } else {
                                            reject("CEP inválido");
                                        }
                                    } else {
                                        reject('');
                                    }
                                });
                            },
                        }),
                    ]}
                >
                    <Input
                        readOnly={readonly}
                        autoComplete="off"
                        placeholder="99.999-999"
                        onChange={(e) => onChangeInputCEP(e)}
                    />
                </Form.Item>
            </Col>
            <Col {...colInputs}>
                <Form.Item
                    style={{
                        margin: 5
                    }}
                    name="cidade"
                    label="Cidade"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor digite sua cidade!',
                        },
                    ]}
                >
                    <Input readOnly={readonly} autoComplete="off" placeholder="" />
                </Form.Item>
            </Col>
            <Col {...colInputs}>
                <Form.Item
                    style={{
                        margin: 5
                    }}
                    name="uf"
                    label="Sigla"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor digite a sigla!',
                        },
                    ]}
                >
                    <Input readOnly={readonly} autoComplete="off" placeholder="PR" />
                </Form.Item>
            </Col>
            <Col {...colInputs}>
                <Form.Item
                    style={{
                        margin: 5
                    }}
                    name="logradouro"
                    label="Endereço"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor digite o endereço!',
                        },
                    ]}
                >
                    <Input readOnly={readonly} autoComplete="off" placeholder="Rua A" />
                </Form.Item>
            </Col>
            <Col {...colInputs}>
                <Form.Item
                    style={{
                        margin: 5
                    }}
                    name="bairro"
                    label="Bairro"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor digite o bairro!',
                        },
                    ]}
                >
                    <Input readOnly={readonly} autoComplete="off" placeholder="Centro" />
                </Form.Item>
            </Col>
            <Col
                {...colInputs}
                style={{
                    marginBottom: 10,
                }}
            >
                <Form.Item
                    style={{
                        margin: 5,
                        marginBottom: 10,
                    }}
                    name="numero"
                    label="Número"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor digite o número!',
                        },
                    ]}
                >
                    <Input readOnly={readonly} autoComplete="off" placeholder="9999" />
                </Form.Item>
            </Col>
            <Col
                {...colInputs}
                style={{
                    marginBottom: 10,
                }}
            >
                <Form.Item
                    style={{
                        margin: 5,
                        marginBottom: 10,
                    }}
                    name="limite_diferenca_validacao_km"
                    label="Validação sobre os KM rodados no app"
                    rules={[
                        {
                            required: false,
                            message: 'Por favor digite o km!',
                        },
                    ]}
                >
                    <Input readOnly={readonly} autoComplete="off" placeholder="300" />
                </Form.Item>
            </Col>
            <Divider style={{ margin: 0 }} />
            <Col
                span={24}
                style={{
                    marginTop: 10,
                }}
            >
                <Form.Item
                    style={{
                        margin: 5
                    }}
                    name="modulos"
                    label="Módulos"
                    rules={[
                        {
                            required: false,
                            message: 'Por favor selecione os modulos!',
                        },
                    ]}
                // valuePropName=""
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <Tree
                            checkable
                            // onExpand={onExpand}
                            expandedKeys={['todos']}
                            disabled={readonly}
                            // autoExpandParent={autoExpandParent}
                            onCheck={onCheck}
                            checkedKeys={checkedKeys}
                            selectable={false}
                            treeData={treeData}
                        />
                    </div>
                </Form.Item>
            </Col>

        </Row>
    )
}
