import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
    Row, Col, Modal, Typography, Button, notification, Divider,
    Form, Input, Spin, Checkbox
} from 'antd';
import { UserAddOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import { colInputs, colFull } from './GridStyle';

// import { getToken } from './../../services/LStorage/token';
import { VerificaRota } from './../../../../../../services/VerificaSePodeAcessar';
import LayoutDashboard from './../../../../../../layout/LayoutAdmin';
import { urlUsuariosPapel, urlUsuariosUsuario, urlUsuariosUsuarioEmail, urlUsuariosUsuarioPerfil } from './../../../../../../services/urls';
import { api } from './../../../../../../services/apiAxios';
import { EmitirErro } from '../../../../../../services/EmitirErro';
import { SairDoSistema } from '../../../../../../services/LStorage/SairSistema';
import { getToken } from './../../../../../../services/LStorage/token';
import { mask, unMask } from './../../../../../../utils/MascaraDeCampos';
// import './style.css';

import {
    removeAcento
} from './../../../../../../utils/RemoveAcentos';
import { getIdUsuario } from '../../../../../../services/LStorage/UsuarioERefresh';
import { Loading } from '../../../../../../components/Loading';


const { Text, Title, Link } = Typography;

// const largura = window.innerWidth;
// const altura = window.innerHeight;
interface IntefEmpresas {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "ativo": boolean,
    "nome": string,
    "cnpj": string,
    "telefone": string,
    "bairro": string | null,
    "cep": string | null,
    "cidade": string | null,
    "logradouro": string | null,
    "numero": string | null,
    "uf": string | null,
    "deletedAt": string | null
}

type interfOpcaoScreen = 'listar' | 'addUpdate';

interface dadosRegraPerfilPermissoes {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "nome": string,
    "descricao": string
}
interface dadosRegraPerfil {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "nome": string,
    "descricao": string,
    "permissoes": Array<dadosRegraPerfilPermissoes>
}

interface interPros {
    empresaSelecionado?: IntefEmpresas,
    alterOpcaoScreen: (opcao: interfOpcaoScreen, id?: string) => void;
    id?: any,
}

const AddOrUpdateUsuariosEmpresa = ({ empresaSelecionado, alterOpcaoScreen, id }: interPros) => {

    const navigate = useNavigate();

    const refForm = useRef<any>(null);
    const refUsuarioEditar = useRef<any>(null);
    const refUsuarioVincular = useRef<any>(null);
    const refValorPapel = useRef<any>(null);

    const [estaEditando, setEstaEditando] = useState(false);
    const [vincular, setVincular] = useState(false);

    const [loading, setLoading] = useState(false);

    useEffect(() => () => {

        let dadosLocalStorage: string | null = localStorage.getItem('@SisPortaria:userEditForEmpresa');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData) {
            localStorage.removeItem('@SisPortaria:userEditForEmpresa');
        }

    }, []);

    //setando dados das cidades no selected
    const buscaDadosUsuario = useCallback((id: string) => {

        const buscaCiaddes = async () => {
            try {
                //pega dados do localStorage se existir
                let dadosLocalStorage: string | null = localStorage.getItem('@SisPortaria:userEditForEmpresa');
                let localUserData: any = null;
                if (typeof dadosLocalStorage == 'string') {
                    localUserData = JSON.parse(dadosLocalStorage);
                }
                // localUserData?.step4.horarioDasNotificacoes? localUserData?.step4.horarioDasNotificacoes: undefined,

                if (localUserData) {

                    refUsuarioEditar.current = localUserData

                    refForm.current?.setFieldsValue(
                        {
                            email: localUserData.email ? localUserData.email : undefined,
                            nome: localUserData.nome ? localUserData.nome : undefined,
                        }
                    );

                    if (localUserData.telefone) {
                        const originalValue = unMask(localUserData.telefone);
                        const maskedValue = mask(originalValue, [
                            "(99) 9999-9999",
                            "(99) 9 9999-9999"
                        ]);
                        refForm.current?.setFieldsValue({
                            telefone: maskedValue
                        })
                    }

                    if (localUserData.cpf) {
                        const originalValue = unMask(localUserData.cpf);
                        const maskedValue = mask(originalValue, [
                            "999.999.999-9",
                            "999.999.999-99"
                        ]);
                        refForm.current?.setFieldsValue({
                            cpf: maskedValue
                        })
                    }
                }

            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);

    //setando dados das cidades no selected
    const buscaDadosRegraDeUsuariosPerfil = useCallback(() => {


        const buscaCiaddes = async () => {
            try {

                let result = await api.get(urlUsuariosPapel,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                const papelUserApp = result.data.filter((element: dadosRegraPerfil) => (
                    element.nome === 'user_app'
                ))
                refValorPapel.current = papelUserApp[0]

            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);

    useEffect(() => {

        let arrayUrl = window.location.pathname.split("/");
        VerificaRota(arrayUrl, navigate);

        buscaDadosRegraDeUsuariosPerfil();
        if (typeof id === 'string') {
            if (id === 'criar') {
                setEstaEditando(false)
            } else {
                setEstaEditando(true)
                buscaDadosUsuario(id);
            }
        }


    }, []);


    const validaEmail = useCallback((email: string): boolean => {
        let er = new RegExp(/^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/);

        if (email == '' || !er.test(email)) { return false; }
        return true;

    }, []);

    const buscarEmailSeJaExiste = useCallback((email: string): Promise<'continuar' | 'existe' | 'vinculado'> => {

        const buscaDadosTipoDeContrato = async (): Promise<'continuar' | 'existe' | 'vinculado'> => {
            try {
                let result = await axios.get(urlUsuariosUsuarioEmail + '/' + email,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });


                if (typeof result.data?.id === 'string') {

                    if (estaEditando) {
                        if (result.data.id === id) {
                            return 'continuar'
                        }

                        return 'existe'

                    } else {

                        if (!vincular) {

                            if (empresaSelecionado?.id) {

                                let encontrou = false
                                result.data.empresaUsuarios.forEach((element: any) => {
                                    if (element.empresaId === empresaSelecionado.id) {
                                        encontrou = true
                                    }
                                });
                                if (encontrou) {
                                    return 'vinculado'
                                    Modal.confirm({
                                        title: 'Email já cadastrado!',
                                        icon: <ExclamationCircleOutlined />,
                                        content: 'Usuário ja vinculado a esta empresa?',
                                        okText: undefined,
                                        cancelText: 'Voltar a listagem',
                                        onCancel: () => {
                                            alterOpcaoScreen('listar')
                                        },
                                        onOk: () => {
                                            // setEstaEditando(true)
                                            // localStorage.setItem('@SisPortaria:userEditForEmpresa', JSON.stringify(result.data));
                                            // alterOpcaoScreen('addUpdate', result.data.id)
                                            // buscaDadosUsuario(result.data.id)
                                        }
                                    });
                                } else {
                                    Modal.confirm({
                                        title: 'Email já cadastrado!',
                                        icon: <ExclamationCircleOutlined />,
                                        content: 'Deseja vincular usuário a está empresa?',
                                        okText: 'Sim',
                                        cancelText: 'Voltar a listagem',
                                        onCancel: () => {
                                            alterOpcaoScreen('listar')
                                        },
                                        onOk: () => {
                                            setVincular(true)
                                            refUsuarioVincular.current = result.data
                                            localStorage.setItem('@SisPortaria:userEditForEmpresa', JSON.stringify(result.data));
                                            alterOpcaoScreen('addUpdate', result.data.id)
                                            buscaDadosUsuario(result.data.id)
                                        }
                                    });
                                }
                            }

                        }
                    }

                    return 'continuar'
                } else {
                    return 'continuar'
                }

            } catch (error) {
                console.log(error)
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel consultar email!',
                });
                return 'continuar'
            }
        }

        return buscaDadosTipoDeContrato();

    }, [empresaSelecionado, id, estaEditando, vincular]);

    const onChangeInputCPF = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, [
            "999.999.999-9",
            "999.999.999-99"
        ]);

        refForm.current.setFieldsValue(
            { cpf: maskedValue }
        );
        if (maskedValue.length >= 10) {
            refForm.current.validateFields(["cpf"])
        }

    }, []);

    const onChangeInputTelefone = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, [
            "(99) 9999-9999",
            "(99) 9 9999-9999"
        ]);

        refForm.current.setFieldsValue(
            { telefone: maskedValue }
        );

        if (maskedValue.length >= 14) {
            refForm.current.validateFields(["telefone"])
        }

    }, []);

    const buscarCelularSeJaExiste = useCallback((celular: string): Promise<boolean> => {

        const buscaDadosTipoDeContrato = async (): Promise<boolean> => {
            try {
                let result = await axios.get('urlBuscaUserByCelular' + celular,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                if (typeof result.data == 'string') {
                    return false;
                } else {
                    return true;
                }
            } catch (error) {
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel consultar celular!',
                });
                return false
            }
        }

        return buscaDadosTipoDeContrato();

    }, []);

    const submitBotaoSalvar = useCallback(() => {


        const salvarDadosCriar = async (obj: any) => {
            try {
                setLoading(true);
                let resultUser = await api.post(urlUsuariosUsuario, obj,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram criados com sucesso!',
                });

                setLoading(false);

                alterOpcaoScreen('listar')

            } catch (error) {
                setLoading(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        refForm.current?.validateFields()
            .then((values: any) => {

                values.papeis = [refValorPapel.current.id];
                values.telefone = unMask(values.telefone);
                values.cpf = unMask(values.cpf);
                values.empresas = [
                    {
                        "empresaId": empresaSelecionado?.id,
                        // "gestor": true
                    }
                ]

                salvarDadosCriar(values);

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, [empresaSelecionado]);

    const submitBotaoEditar = useCallback((idUser: string) => {


        const salvarDadosEditar = async (obj: any) => {
            try {
                setLoading(true);

                let resultUser = await api.put(urlUsuariosUsuario + '/' + idUser, obj,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram editados com sucesso!',
                });

                // setDataListCidades(resultCidade.data);

                setLoading(false);
                alterOpcaoScreen('listar')

            } catch (error) {
                setLoading(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        refForm.current?.validateFields()
            .then((values: any) => {

                values.empresas = refUsuarioEditar.current.empresaUsuarios
                // values.papeis = [refValorPapel.current.id];
                values.telefone = unMask(values.telefone);
                values.cpf = unMask(values.cpf);

                salvarDadosEditar(values);

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, []);

    const submitBotaoVincular = useCallback(() => {


        const salvarDadosEditar = async (obj: any) => {
            try {
                setLoading(true);

                let resultUser = await api.put(urlUsuariosUsuario + '/' +
                    refUsuarioVincular.current.id, obj,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram editados com sucesso!',
                });

                // setDataListCidades(resultCidade.data);

                setLoading(false);
                alterOpcaoScreen('listar')

            } catch (error) {
                setLoading(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        refForm.current?.validateFields()
            .then((values: any) => {
                let objEmpresas = []
                refUsuarioVincular.current.empresaUsuarios.forEach((element: any) => {
                    objEmpresas.push({ "empresaId": element.empresaId })
                })
                objEmpresas.push({ "empresaId": empresaSelecionado?.id })

                values.empresas = objEmpresas
                // values.papeis = [refValorPapel.current.id];
                values.telefone = unMask(values.telefone);
                values.cpf = unMask(values.cpf);

                salvarDadosEditar(values);

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, []);

    return (
        <Loading

            loading={loading}
        >
            <Row>
                <Col {...colFull}>
                    <Title level={4}>
                        <UserAddOutlined style={{ fontSize: 25, marginRight: 10 }} />
                        {
                            vincular ?
                                `Vincular Usuário`
                                :
                                `${estaEditando ? 'Editar' : 'Adicionar'} Usuário`
                        }
                        { }
                    </Title>
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <Link
                            onClick={() => [
                                alterOpcaoScreen('listar')
                            ]}
                            style={{
                                marginRight: 5
                            }}
                        >
                            <Text
                                type='secondary' underline
                            >Listagem</Text>
                        </Link>

                        <Text
                            style={{
                                marginRight: 5
                            }} type='secondary'
                        >/</Text>

                        <Text style={{
                            marginRight: 5
                        }}>
                            {
                                vincular ?
                                    'Vincular' :
                                    `${estaEditando ? 'Editar' : 'Adicionar'}`
                            }
                        </Text>
                    </div>
                    <Divider />
                </Col>
            </Row>

            <Form
                ref={refForm}
                name="adicionarUsuario"
                layout="vertical"
                // onFieldsChange={onFieldsChange}
                initialValues={{
                    // gestor: false
                }}
                // onFinish={onFinish}
                // onFinishFailed={() => { }}
                autoComplete="off"
            >
                <Row>
                    <Col {...colInputs}>
                        <Form.Item
                            style={{
                                margin: 5
                            }}
                            name="email"
                            label="Email (Login)"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor digite seu email!',
                                },
                                () => ({
                                    validator(rule, value) {
                                        return new Promise((resolve: (value?: any) => void, reject) => {
                                            //CUIDADO AO EDITAR PARA PODER ACEITAR MESMO EMAIL
                                            if (value != '' && value != null) {
                                                if (validaEmail(value)) {
                                                    buscarEmailSeJaExiste(value).then(valorPromessa => {
                                                        if (valorPromessa === 'existe') {
                                                            reject("Email já existe!");
                                                        } else if (valorPromessa === 'vinculado') {
                                                            reject("Email já cadastrado e vinculado a está empresa!");
                                                        } else if (valorPromessa === 'continuar') {
                                                            resolve();
                                                        } else {
                                                            resolve();
                                                        }
                                                    }).catch(error => {
                                                        resolve();
                                                    })
                                                } else {
                                                    reject("Email inválido");
                                                }
                                            } else {
                                                reject("Email é obrigatório");
                                                // if (propsState.nome == "painel" || propsState.nome == "todos") {
                                                //     reject('');
                                                // } else {
                                                //     resolve();
                                                // }

                                            }
                                        });
                                    },
                                }),
                            ]}
                        >
                            <Input autoComplete="off" placeholder="Digite seu email!" />
                        </Form.Item>
                    </Col>
                    <Col {...colInputs}>
                        <Form.Item
                            style={{
                                margin: 5
                            }}
                            name="nome"
                            label="Nome"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor digite seu nome!',
                                },
                            ]}
                        >
                            <Input autoComplete="off" placeholder="Nome"
                                onChange={() => {

                                    const text = refForm.current?.getFieldValue('nome')
                                    const first = text.charAt(0).toUpperCase()
                                    const rest = text.substring(1);

                                    refForm.current?.setFieldsValue(
                                        { 'nome': first + rest }
                                    )

                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col {...colInputs}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Preencha o telefone!",
                                    max: 16
                                },
                                () => ({
                                    validator(rule, value) {
                                        return new Promise((resolve: (value?: any) => void, reject) => {
                                            //CUIDADO AO EDITAR PARA PODER ACEITAR MESMO EMAIL
                                            if (value != '' && value != null) {
                                                value = unMask(value);
                                                if (value.length >= 10) {
                                                    // buscarCelularSeJaExiste(value).then(valorPromessa => {
                                                    //     if (valorPromessa) {
                                                    //         reject("Celular já existe!");
                                                    //     } else {
                                                    //         resolve();
                                                    //     }
                                                    // }).catch(error => {
                                                    //     resolve();
                                                    // })
                                                    resolve();
                                                } else {
                                                    reject("Celular inválido");
                                                }
                                            } else {
                                                reject('');
                                            }
                                        });
                                    },
                                }),
                            ]}
                            name="telefone"
                            label="Telefone"
                            style={{ margin: 5 }}
                        >
                            <Input
                                type="tel"
                                autoComplete="off"
                                onChange={(e) => onChangeInputTelefone(e)}
                                placeholder="(44) 9 9999-9999"
                            />
                        </Form.Item>
                    </Col>
                    <Col {...colInputs}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Preencha o cpf!",
                                    max: 14
                                },
                                () => ({
                                    validator(rule, value) {
                                        return new Promise((resolve: (value?: any) => void, reject) => {
                                            //CUIDADO AO EDITAR PARA PODER ACEITAR MESMO EMAIL
                                            if (value != '' && value != null) {
                                                value = unMask(value);

                                                if (value.length >= 10) {
                                                    // buscarCelularSeJaExiste(value).then(valorPromessa => {
                                                    //     if (valorPromessa) {
                                                    //         reject("Celular já existe!");
                                                    //     } else {
                                                    //         resolve();
                                                    //     }
                                                    // }).catch(error => {
                                                    //     resolve();
                                                    // })
                                                    resolve();
                                                } else {
                                                    reject("CPF inválido");
                                                }
                                            } else {
                                                reject('');
                                            }
                                        });
                                    },
                                }),
                            ]}
                            name="cpf"
                            label="CPF"
                            style={{ margin: 5 }}
                        >
                            <Input
                                // type="tel"
                                autoComplete="off"
                                onChange={(e) => onChangeInputCPF(e)}
                                placeholder="999.999.999-9"
                            />
                        </Form.Item>
                    </Col>
                    <Col {...colFull}>
                        <div className="botaoSalvarStep" style={{
                            flexDirection: 'row',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            // background: 'red'
                        }}>
                            <Button
                                // type="primary"
                                style={{
                                    marginTop: 20,
                                    marginRight: 5
                                }}
                                onClick={() => {
                                    alterOpcaoScreen('listar')
                                }}
                            >
                                {"Cancelar"}
                            </Button>
                            {
                                vincular ?
                                    <Button
                                        key="submit"
                                        type="primary"
                                        style={{
                                            marginTop: 20,
                                            marginRight: 5
                                        }}
                                        onClick={() => {
                                            submitBotaoVincular()
                                        }}
                                    >
                                        {"Vincular"}
                                    </Button>
                                    :
                                    <Button
                                        key="submit"
                                        type="primary"
                                        style={{
                                            marginTop: 20,
                                            marginRight: 5
                                        }}
                                        onClick={() => {
                                            estaEditando ?
                                                submitBotaoEditar(id ? id : 'criar')
                                                :
                                                submitBotaoSalvar()
                                        }}
                                    >
                                        {"Salvar"}
                                    </Button>
                            }

                        </div>
                    </Col>
                </Row>
            </Form>
        </Loading>
    );
}

export default AddOrUpdateUsuariosEmpresa;
