import { createContext, ReactNode, useCallback, useEffect, useState } from "react";

interface interfaceGlobalContext {
    theme: 'light' | 'dark';
    updateTheme: (theme: 'light' | 'dark') => void;
}
export const GlobalContext = createContext({} as interfaceGlobalContext);

interface PropsTarefasProvider {
    children: ReactNode;
}

export function GlobalProvider(props: PropsTarefasProvider) {

    const [theme, setTheme] = useState<'light' | 'dark'>('light');

    useEffect(() => {

        let dataLSTheme: string | null = localStorage.getItem('@SisPortaria:theme');
        let localUserData: any = null;
        if (typeof dataLSTheme == 'string') {
            localUserData = JSON.parse(dataLSTheme);
        }

        if (localUserData) {
            setTheme(localUserData)
        } else {

            localStorage.setItem('@SisPortaria:theme', JSON.stringify('light'));

            setTheme('light');
            window.location.reload();
        }


    }, []);

    const updateTheme = useCallback((theme: 'light' | 'dark'): void => {

        localStorage.setItem('@SisPortaria:theme', JSON.stringify(theme));

        setTheme(theme);

    }, []);

    return (
        <GlobalContext.Provider value={{
            updateTheme, theme
        }}>
            {props.children}
        </GlobalContext.Provider>
    )
}
