import { useCallback, useEffect, useState, useRef } from 'react';
import { Row, Col, Tabs, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import { VerificaRota } from './../../services/VerificaSePodeAcessar';
import { urlEmpresasUsuarioPainel } from './../../services/urls';
import { api } from './../../services/apiAxios';
import { EmitirErro } from '../../services/EmitirErro';
import { SairDoSistema } from '../../services/LStorage/SairSistema';
import { getToken } from './../../services/LStorage/token';

import './style.css';

import { getIdUsuario } from '../../services/LStorage/UsuarioERefresh';
import { DadosEmpresa } from './components/DadosEmpresa';
import { ListVeiculos } from './components/ListVeiculos';
import { ListColaboradores } from './components/ListColaboradores';
import { ListUsuarios } from './components/ListUsuarios';
import { ListEvento } from './components/ListEvento';
import LayoutDashboard from '../../layout/LayoutAdmin';
import { getEmpresaSelecionada } from '../../services/LStorage/EmpresaSelecionada';

const { TabPane } = Tabs;

interface IntefListEmpresas {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "ativo": boolean,
    "nome": string,
    "cnpj": string,
    "telefone": string,
    "bairro": string | null,
    "cep": string | null,
    "cidade": string | null,
    "logradouro": string | null,
    "numero": string | null,
    "limite_diferenca_validacao_km": string | number | null,
    "uf": string | null,
    "deletedAt": string | null
}


const ListEmpresasGroup: React.FC = () => {

    const navigate = useNavigate();

    const refDataListEmpresas = useRef<Array<IntefListEmpresas>>([]);
    const refEmpresaSelecionada = useRef<IntefListEmpresas | null>(null);

    const [empresaSelecionado, setEmpresaSelecionado] = useState<IntefListEmpresas>();

    const selectEmpresaPorId = useCallback((id: string): IntefListEmpresas | null => {
        let objCidade = null;
        refDataListEmpresas.current.forEach(val => {
            if (val.id === id) {
                objCidade = val
            }
        });
        return objCidade;

    }, []);

    //setando dados das cidades no selected
    const buscaCidades = useCallback(() => {

        const buscaCiaddes = async () => {
            try {

                let idUsuario = getIdUsuario();

                let resultCidade = await api.get(urlEmpresasUsuarioPainel + '/' + idUsuario,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                refDataListEmpresas.current = resultCidade.data

                if (refEmpresaSelecionada.current) {
                    const objSelecionado = selectEmpresaPorId(refEmpresaSelecionada.current.id)

                    if (objSelecionado) {
                        setEmpresaSelecionado(objSelecionado);

                        localStorage.setItem('@SisPortaria:empresa', JSON.stringify(
                            { empresaSelecionada: objSelecionado }
                        ));
                    }

                }

            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);

    useEffect(() => {

        let arrayUrl = window.location.pathname.split("/");
        VerificaRota(arrayUrl, navigate);

        let lsEmpresaSelecionada = getEmpresaSelecionada();

        if (lsEmpresaSelecionada) {
            refEmpresaSelecionada.current = lsEmpresaSelecionada
            setEmpresaSelecionado(lsEmpresaSelecionada);
        }

    }, []);

    const atualizarEmpresas = useCallback(() => {
        buscaCidades();
    }, []);

    return (
        <LayoutDashboard>

            {empresaSelecionado &&
                <Row>
                    <Col span={24}>
                        <Tabs
                            type="card"
                            defaultActiveKey="1"
                            style={{
                                marginTop: 15,
                            }}
                            className='tab-municipios'

                        >
                            <TabPane
                                tab="Dados da Empresa"
                                key="1"
                            >
                                <DadosEmpresa
                                    empresaSelecionado={empresaSelecionado}
                                    atualizarEmpresas={atualizarEmpresas}
                                />
                            </TabPane>
                            <TabPane
                                tab="Colaboradoes"
                                key="2"
                            >
                                <ListColaboradores empresaSelecionado={empresaSelecionado} />
                            </TabPane>
                            <TabPane
                                tab="Veiculos"
                                key="3"

                            >
                                <ListVeiculos empresaSelecionado={empresaSelecionado} />
                            </TabPane>
                            <TabPane
                                tab="Usuários"
                                key="4"

                            >
                                <ListUsuarios empresaSelecionado={empresaSelecionado} />
                            </TabPane>
                            <TabPane
                                tab="Evento"
                                key="evento5"

                            >
                                <ListEvento empresaSelecionado={empresaSelecionado} />
                            </TabPane>
                            {/* {
                                validacaoPermissao(['manage_user']) &&
                                <TabPane
                                    tab="Dados do Prefeito"
                                    key="4"
                                >
                                    <Prefeito empresaSelecionado={empresaSelecionado} />
                                </TabPane>
                            } */}
                        </Tabs>
                    </Col>
                </Row>
            }
        </LayoutDashboard>
    );
}

export default ListEmpresasGroup;
