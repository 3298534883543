import jwt_decode from 'jwt-decode';
interface IntefListEmpresasToken {
    "id": string,
    "nome": string,
}
interface IntefListEmpresas {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "ativo": boolean,
    "nome": string,
    "cnpj": string,
    "telefone": string,
    "bairro": string | null,
    "cep": string | null,
    "cidade": string | null,
    "logradouro": string | null,
    "numero": string | null,
    "limite_diferenca_validacao_km": string | number | null,
    "uf": string | null,
    "deletedAt": string | null
}

/**
 * Get empresa selecionada
 */
export const getEmpresaSelecionada = (): IntefListEmpresas | undefined => {
    let dataLSEmpresa: string | null = localStorage.getItem('@SisPortaria:empresa');
    let dataLocalStoEmpresa: any = null;
    if (typeof dataLSEmpresa == 'string') {
        dataLocalStoEmpresa = JSON.parse(dataLSEmpresa);
        return dataLocalStoEmpresa.empresaSelecionada
    } else {
        return undefined
    }
}

/**
 * Remover empresa selecionada
 */
export const removeEmpresa = (): void => {
    let dadosLocalStorage: string | null = localStorage.getItem('@SisPortaria:empresa');
    let localUserData: any = null;
    if (typeof dadosLocalStorage == 'string') {
        localUserData = JSON.parse(dadosLocalStorage);
    }

    if (localUserData) {
        localStorage.removeItem('@SisPortaria:empresa');
    }
}

/**
 * Pegar empresas do token
 */
export const getEmpresasToken = (): Array<IntefListEmpresasToken> | undefined => {
    let dadosLocalStorage: string | null = localStorage.getItem('@SisPortaria:token');
    let localUserData: any = null;
    if (typeof dadosLocalStorage == 'string') {
        localUserData = JSON.parse(dadosLocalStorage);

        if (localUserData.token) {
            let decodedToken: any = jwt_decode(localUserData.token);

            if (decodedToken && decodedToken != null && decodedToken?.empresas) {
                //retorna o id do usuario
                return decodedToken?.empresas
            }
        }
        return undefined
    } else {
        return undefined
    }
}
