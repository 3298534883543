import { useCallback, useState } from 'react';
import { EventoAddOrUpdate } from './AddOrUpdate';
import { ListagemEvento } from './Listagem';

interface IntefEmpresas {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "ativo": boolean,
    "nome": string,
    "cnpj": string,
    "telefone": string,
    "bairro": string | null,
    "cep": string | null,
    "cidade": string | null,
    "logradouro": string | null,
    "numero": string | null,
    "uf": string | null,
    "deletedAt": string | null
}
interface interPros {
    empresaSelecionado?: IntefEmpresas,
}
type interfOpcaoScreen = 'listar' | 'addUpdate';

export const ListEvento = ({ empresaSelecionado }: interPros) => {

    const [opcaoScreen, setOpcaoScreen] = useState<interfOpcaoScreen>('listar');
    const [idUsuarioSelecionado, setIdUsuarioSelecionado] = useState<string | null | undefined>(null);

    const alterOpcaoScreen = useCallback((opcao: interfOpcaoScreen, id?: string) => {
        setIdUsuarioSelecionado(id)
        setOpcaoScreen(opcao)
    }, []);

    return (
        <>
            {
                opcaoScreen === 'listar' ?
                    <ListagemEvento
                        alterOpcaoScreen={alterOpcaoScreen}
                        empresaSelecionado={empresaSelecionado}
                    />
                    :
                    <EventoAddOrUpdate
                        alterOpcaoScreen={alterOpcaoScreen}
                        id={idUsuarioSelecionado}
                        idEmpresa={empresaSelecionado?.id}
                    />
            }
        </>
    );
}
