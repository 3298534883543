import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
    Row, Col, Select, Typography, Button, notification, Divider,
    Form, Input, Spin
} from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import { colInputs, colFull } from './GridStyle';

// import { getToken } from './../../services/LStorage/token';
import { VerificaRota } from './../../../../services/VerificaSePodeAcessar';
import LayoutDashboard from './../../../../layout/LayoutAdmin';
import { urlEmpresas, urlEmpresasUsuario, urlUsuariosPapel, urlUsuariosUsuario, urlUsuariosUsuarioEmail, urlUsuariosUsuarioPerfil } from './../../../../services/urls';
import { api } from './../../../../services/apiAxios';
import { EmitirErro } from '../../../../services/EmitirErro';
import { SairDoSistema } from '../../../../services/LStorage/SairSistema';
import { getToken } from './../../../../services/LStorage/token';
import { mask, unMask } from './../../../../utils/MascaraDeCampos';
// import './style.css';

import {
    removeAcento
} from './../../../../utils/RemoveAcentos';
import { getIdUsuario } from '../../../../services/LStorage/UsuarioERefresh';
import { Loading } from '../../../../components/Loading';


const { Text, Title, Link } = Typography;

// const largura = window.innerWidth;
// const altura = window.innerHeight;
interface IntefListEmpresas {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "ativo": boolean,
    "nome": string,
    "cnpj": string,
    "telefone": string,
    "endereco": string | null,
    "colaboradores": Array<any>,
    "veiculos": Array<any>,
    "deletedAt": string | null
}

interface dadosRegraPerfilPermissoes {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "nome": string,
    "descricao": string
}
interface dadosRegraPerfil {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "nome": string,
    "descricao": string,
    "permissoes": Array<dadosRegraPerfilPermissoes>
}

const AddOrUpdateUsuarios = () => {

    const navigate = useNavigate();
    let { id } = useParams();

    const refForm = useRef<any>(null);
    const refDadosRegraPerfil = useRef<Array<dadosRegraPerfil>>([]);

    const [estaEditando, setEstaEditando] = useState(false);

    const [dadosEmpresas, setDadosEmpresas] = useState<Array<IntefListEmpresas>>([]);
    const [loadingDadosEmpresas, setLoadingDadosEmpresas] = useState(false);

    const [dadosRegraPerfil, setDadosRegraPerfil] = useState<Array<dadosRegraPerfil>>([]);
    const [loadingDadosRegraPerfil, setLoadingDadosRegraPerfil] = useState(false);
    const [valueUsuarioPerfilselect, setValueUsuarioPerfilselect] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => () => {

        let dadosLocalStorage: string | null = localStorage.getItem('@SisPortaria:userEdit');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData) {
            localStorage.removeItem('@SisPortaria:userEdit');
        }

    }, []);

    //setando dados das cidades no selected
    const buscaDadosUsuario = useCallback((id: string) => {

        const buscaCiaddes = async () => {
            try {
                //pega dados do localStorage se existir
                let dadosLocalStorage: string | null = localStorage.getItem('@SisPortaria:userEdit');
                let localUserData: any = null;
                if (typeof dadosLocalStorage == 'string') {
                    localUserData = JSON.parse(dadosLocalStorage);
                }


                let resultEmpresasUsuario = await api.get(urlEmpresasUsuario + '/' + id,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                let arr: Array<string> = []
                resultEmpresasUsuario.data.forEach((elem: any) => {
                    arr.push(elem.id)
                })
                refForm.current?.setFieldsValue(
                    {
                        empresasArr: arr
                    }
                );

                if (localUserData) {

                    refForm.current?.setFieldsValue(
                        {
                            email: localUserData.email ? localUserData.email : undefined,
                            nome: localUserData.nome ? localUserData.nome : undefined,
                            papeis: localUserData.papeis.length > 0 ? localUserData.papeis[0].id : undefined,
                        }
                    );

                    setValueUsuarioPerfilselect(localUserData.papeis.length > 0 ? localUserData.papeis[0].nome : null)

                    if (localUserData.telefone) {
                        const originalValue = unMask(localUserData.telefone);
                        const maskedValue = mask(originalValue, [
                            "(99) 9999-9999",
                            "(99) 9 9999-9999"
                        ]);
                        refForm.current?.setFieldsValue({
                            telefone: maskedValue
                        })
                    }

                    if (localUserData.cpf) {
                        const originalValue = unMask(localUserData.cpf);
                        const maskedValue = mask(originalValue, [
                            "999.999.999-9",
                            "999.999.999-99"
                        ]);
                        refForm.current?.setFieldsValue({
                            cpf: maskedValue
                        })
                    }
                }

            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);

    //setando dados das cidades no selected
    const buscaDadosRegraDeUsuariosPerfil = useCallback(() => {


        const buscaCiaddes = async () => {
            try {
                setLoadingDadosEmpresas(true);
                let resultEmpresas = await api.get(urlEmpresas,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDadosEmpresas(resultEmpresas.data);

                setLoadingDadosEmpresas(false);

                setLoadingDadosRegraPerfil(true);
                let result = await api.get(urlUsuariosPapel,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                refDadosRegraPerfil.current = result.data
                setDadosRegraPerfil(result.data);

                setLoadingDadosRegraPerfil(false);

            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);

    useEffect(() => {

        let arrayUrl = window.location.pathname.split("/");
        VerificaRota(arrayUrl, navigate);

        buscaDadosRegraDeUsuariosPerfil();
        if (typeof id === 'string') {
            if (id === 'criar') {
                setEstaEditando(false)
            } else {
                setEstaEditando(true)
                buscaDadosUsuario(id);
            }
        }


    }, []);

    const selectPerfilPorID = useCallback((id: any): string | null => {
        let idPerfil = null;
        refDadosRegraPerfil.current.forEach(val => {
            if (val.id == id) {
                idPerfil = val.nome
            }
        });
        return idPerfil;

    }, []);

    const onChangeSelectPerfil = useCallback((value: any): void => {

        setValueUsuarioPerfilselect(selectPerfilPorID(value))

    }, []);

    const validaEmail = useCallback((email: string): boolean => {
        let er = new RegExp(/^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/);

        if (email == '' || !er.test(email)) { return false; }
        return true;

    }, []);

    const buscarEmailSeJaExiste = useCallback((email: string): Promise<boolean> => {

        const buscaDadosTipoDeContrato = async (): Promise<boolean> => {
            try {
                let result = await axios.get(urlUsuariosUsuarioEmail + '/' + email,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                if (typeof result.data?.id === 'string') {
                    if (result.data.id === id) {
                        return false;
                    }
                    return true;
                } else {
                    return false;
                }

            } catch (error) {
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel consultar email!',
                });
                return false
            }
        }

        return buscaDadosTipoDeContrato();

    }, []);

    const onChangeInputCPF = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, [
            "999.999.999-9",
            "999.999.999-99"
        ]);

        refForm.current.setFieldsValue(
            { cpf: maskedValue }
        );
        if (maskedValue.length >= 10) {
            refForm.current.validateFields(["cpf"])
        }

    }, []);

    const onChangeInputTelefone = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, [
            "(99) 9999-9999",
            "(99) 9 9999-9999"
        ]);

        refForm.current.setFieldsValue(
            { telefone: maskedValue }
        );

        if (maskedValue.length >= 14) {
            refForm.current.validateFields(["telefone"])
        }

    }, []);

    const buscarCelularSeJaExiste = useCallback((celular: string): Promise<boolean> => {

        const buscaDadosTipoDeContrato = async (): Promise<boolean> => {
            try {
                let result = await axios.get('urlBuscaUserByCelular' + celular,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                if (typeof result.data == 'string') {
                    return false;
                } else {
                    return true;
                }
            } catch (error) {
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel consultar celular!',
                });
                return false
            }
        }

        return buscaDadosTipoDeContrato();

    }, []);

    const submitBotaoSalvar = useCallback(() => {


        const salvarDadosCriar = async (obj: any) => {
            try {
                setLoading(true);
                let resultUser = await api.post(urlUsuariosUsuario, obj,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram criados com sucesso!',
                });

                setLoading(false);

                navigate('/usuarios')

            } catch (error) {
                setLoading(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        refForm.current?.validateFields()
            .then((values: any) => {

                values.papeis = [values.papeis];
                values.telefone = unMask(values.telefone);
                values.cpf = unMask(values.cpf);

                if (values?.empresasArr) {
                    let empres: Array<any> = []
                    values.empresasArr.forEach((element: string) => {
                        empres.push({ "empresaId": element })
                    })

                    values.empresas = empres
                }
                salvarDadosCriar(values);

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, []);

    const submitBotaoEditar = useCallback((idUser: string) => {


        const salvarDadosEditar = async (obj: any) => {
            try {
                setLoading(true);

                let resultUser = await api.put(urlUsuariosUsuario + '/' + idUser, obj,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram editados com sucesso!',
                });

                // setDataListCidades(resultCidade.data);

                setLoading(false);
                navigate('/usuarios');

            } catch (error) {
                setLoading(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        refForm.current?.validateFields()
            .then((values: any) => {

                values.papeis = [values.papeis];
                values.telefone = unMask(values.telefone);
                values.cpf = unMask(values.cpf);

                if (values?.empresasArr) {
                    let empres: Array<any> = []
                    values.empresasArr.forEach((element: string) => {
                        empres.push({ "empresaId": element })
                    })

                    values.empresas = empres
                }
                salvarDadosEditar(values);

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, []);

    return (
        <LayoutDashboard>
            <Loading

                loading={loading}
            >
                <Row>
                    <Col {...colFull}>
                        <Title level={4}>
                            <UserAddOutlined style={{ fontSize: 25, marginRight: 10 }} />
                            {`${estaEditando ? 'Editar' : 'Adicionar'} Usuario`}
                        </Title>
                        <div
                            style={{
                                display: 'flex'
                            }}
                        >
                            <Link
                                // href="#"
                                onClick={() => [
                                    navigate('/usuarios')
                                ]}
                                style={{
                                    marginRight: 5
                                }}
                            // target="_blank"
                            >
                                <Text
                                    type='secondary' underline
                                >Usuários</Text>
                            </Link>

                            <Text
                                style={{
                                    marginRight: 5
                                }} type='secondary'
                            >/</Text>

                            <Text style={{
                                marginRight: 5
                            }}>{`${estaEditando ? 'Editar' : 'Adicionar'}`}</Text>
                        </div>
                        <Divider />
                    </Col>
                </Row>

                <Form
                    ref={refForm}
                    name="adicionarUsuario"
                    layout="vertical"
                    // onFieldsChange={onFieldsChange}
                    initialValues={{
                        remember: true,
                    }}
                    // onFinish={onFinish}
                    // onFinishFailed={() => { }}
                    autoComplete="off"
                >
                    <Row>
                        <Col {...colInputs}>
                            <Form.Item
                                style={{
                                    margin: 5
                                }}
                                name="nome"
                                label="Nome"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor digite seu nome!',
                                    },
                                ]}
                            >
                                <Input autoComplete="off" placeholder="Nome"
                                    onChange={() => {

                                        const text = refForm.current?.getFieldValue('nome')
                                        const first = text.charAt(0).toUpperCase()
                                        const rest = text.substring(1);

                                        refForm.current?.setFieldsValue(
                                            { 'nome': first + rest }
                                        )

                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                style={{
                                    margin: 5
                                }}
                                name="email"
                                label="Email (Login)"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor digite seu email!',
                                    },
                                    () => ({
                                        validator(rule, value) {
                                            return new Promise((resolve: (value?: any) => void, reject) => {
                                                //CUIDADO AO EDITAR PARA PODER ACEITAR MESMO EMAIL
                                                if (value != '' && value != null) {
                                                    if (validaEmail(value)) {
                                                        buscarEmailSeJaExiste(value).then(valorPromessa => {
                                                            if (valorPromessa) {
                                                                reject("Email já existe!");
                                                            } else {
                                                                resolve();
                                                            }
                                                        }).catch(error => {
                                                            resolve();
                                                        })
                                                    } else {
                                                        reject("Email inválido");
                                                    }
                                                } else {
                                                    reject("Email é obrigatório");
                                                    // if (propsState.nome == "painel" || propsState.nome == "todos") {
                                                    //     reject('');
                                                    // } else {
                                                    //     resolve();
                                                    // }

                                                }
                                            });
                                        },
                                    }),
                                ]}
                            >
                                <Input autoComplete="off" placeholder="Digite seu email!" />
                            </Form.Item>
                        </Col>
                        {/* {
                        estaEditando &&
                        <>
                            <Col {...colInputs}>
                                <Form.Item
                                    style={{
                                        margin: 5
                                    }}
                                    name="senha"
                                    label="Senha Atual"
                                    rules={[
                                        {
                                            required: estaEditando === true ? false : true,
                                            message: 'Por favor digite sua senha!',
                                        },
                                        () => ({
                                            validator(rule, value) {
                                                if (value?.length >= 1) {
                                                    setObrigatorioConfirm(true);
                                                }
                                                if (value?.length == 0) {
                                                    setObrigatorioConfirm(false);
                                                }

                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password autoComplete="off" placeholder="Senha" />
                                </Form.Item>
                            </Col>
                            <Col {...colInputs}>
                                <Form.Item
                                    style={{
                                        margin: 5
                                    }}
                                    name="nova_senha"
                                    label="Nova Senha"
                                    rules={[
                                        {
                                            required: obrigatorioConfirm,
                                            message: 'Por favor digite sua nova senha!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value || getFieldValue('senha') !== value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject('Senha está igual a anterior!');
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password autoComplete="off" placeholder="Por favor digite nova senha!" />
                                </Form.Item>
                            </Col>
                        </>
                    } */}
                        <Col {...colInputs}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: "Preencha o telefone!",
                                        max: 16
                                    },
                                    () => ({
                                        validator(rule, value) {
                                            return new Promise((resolve: (value?: any) => void, reject) => {
                                                //CUIDADO AO EDITAR PARA PODER ACEITAR MESMO EMAIL
                                                if (value != '' && value != null) {
                                                    value = unMask(value);
                                                    if (value.length >= 10) {
                                                        // buscarCelularSeJaExiste(value).then(valorPromessa => {
                                                        //     if (valorPromessa) {
                                                        //         reject("Celular já existe!");
                                                        //     } else {
                                                        //         resolve();
                                                        //     }
                                                        // }).catch(error => {
                                                        //     resolve();
                                                        // })
                                                        resolve();
                                                    } else {
                                                        reject("Celular inválido");
                                                    }
                                                } else {
                                                    reject('');
                                                }
                                            });
                                        },
                                    }),
                                ]}
                                name="telefone"
                                label="Telefone"
                                style={{ margin: 5 }}
                            >
                                <Input
                                    type="tel"
                                    autoComplete="off"
                                    onChange={(e) => onChangeInputTelefone(e)}
                                    placeholder="(44) 9 9999-9999"
                                />
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: "Preencha o cpf!",
                                        max: 14
                                    },
                                    () => ({
                                        validator(rule, value) {
                                            return new Promise((resolve: (value?: any) => void, reject) => {
                                                //CUIDADO AO EDITAR PARA PODER ACEITAR MESMO EMAIL
                                                if (value != '' && value != null) {
                                                    value = unMask(value);

                                                    if (value.length >= 10) {
                                                        // buscarCelularSeJaExiste(value).then(valorPromessa => {
                                                        //     if (valorPromessa) {
                                                        //         reject("Celular já existe!");
                                                        //     } else {
                                                        //         resolve();
                                                        //     }
                                                        // }).catch(error => {
                                                        //     resolve();
                                                        // })
                                                        resolve();
                                                    } else {
                                                        reject("CPF inválido");
                                                    }
                                                } else {
                                                    reject('');
                                                }
                                            });
                                        },
                                    }),
                                ]}
                                name="cpf"
                                label="CPF"
                                style={{ margin: 5 }}
                            >
                                <Input
                                    // type="tel"
                                    autoComplete="off"
                                    onChange={(e) => onChangeInputCPF(e)}
                                    placeholder="999.999.999-9"
                                />
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                name="papeis"
                                label="Perfil do usuário"
                                style={{ margin: 5 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor selecione o perfil!',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Selecione..."
                                    loading={loadingDadosRegraPerfil}
                                    notFoundContent={loadingDadosRegraPerfil ? <Spin size="small" /> : null}
                                    onChange={onChangeSelectPerfil}
                                    filterOption={(input: any, option: any) => {
                                        let textDigit = removeAcento(input)
                                        let listCidade = removeAcento(option?.children);
                                        return listCidade.indexOf(textDigit) >= 0
                                    }}
                                >
                                    {
                                        dadosRegraPerfil.map((item) => {
                                            return (
                                                <Select.Option
                                                    value={item.id}
                                                    key={item.id}
                                                >
                                                    {item.descricao}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        {
                            valueUsuarioPerfilselect === 'user_manager' || valueUsuarioPerfilselect === 'user_app' ?
                                <Col {...colInputs}>
                                    <Form.Item
                                        name="empresasArr"
                                        label="Selecionar empresas"
                                        style={{ margin: 5 }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor selecione uma empresa!',
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            placeholder="Selecione..."
                                            loading={loadingDadosEmpresas}
                                            mode='multiple'
                                            notFoundContent={loadingDadosEmpresas ? <Spin size="small" /> : null}
                                            filterOption={(input: any, option: any) => {
                                                let textDigit = removeAcento(input)
                                                let listCidade = removeAcento(option?.children);
                                                return listCidade.indexOf(textDigit) >= 0
                                            }}
                                        >
                                            {
                                                dadosEmpresas.map((item) => {
                                                    return (
                                                        <Select.Option
                                                            value={item.id}
                                                            key={item.id}
                                                        >
                                                            {item.nome}
                                                        </Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                :
                                <></>
                        }
                        <Col {...colFull}>
                            <div className="botaoSalvarStep" style={{
                                flexDirection: 'row',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                // background: 'red'
                            }}>
                                <Button
                                    // type="primary"
                                    style={{
                                        marginTop: 20,
                                        marginRight: 5
                                    }}
                                    onClick={() => {
                                        navigate('/usuarios')
                                    }}
                                >
                                    {"Cancelar"}
                                </Button>
                                <Button
                                    key="submit"
                                    type="primary"
                                    style={{
                                        marginTop: 20,
                                        marginRight: 5
                                    }}
                                    onClick={() => {
                                        estaEditando ?
                                            submitBotaoEditar(id ? id : 'criar')
                                            :
                                            submitBotaoSalvar()
                                    }}
                                >
                                    {"Salvar"}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Loading>

        </LayoutDashboard >
    );
}

export default AddOrUpdateUsuarios;
