import React, { useCallback, useEffect } from 'react';
import { Card, Row, Col, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import { cardLayout, cardImag } from './GridStyle';

// @ts-ignore
import ImagemLogo from './../../assets/imagens/logo.png';

// teste commit

// import { getToken } from './../../services/LStorage/token';
import { VerificaRota } from './../../services/VerificaSePodeAcessar';
import LayoutDashboard from './../../layout/LayoutAdmin';
import { urlUsuariosPapel } from './../../services/urls';
import { api } from './../../services/apiAxios';
import { EmitirErro } from '../../services/EmitirErro';
import { SairDoSistema } from '../../services/LStorage/SairSistema';
import { getToken } from './../../services/LStorage/token';


const largura = window.innerWidth;
// const altura = window.innerHeight;

const Dashboard: React.FC = () => {

    const navigate = useNavigate();

    //setando dados das cidades no selected
    const buscaCidades = useCallback(() => {

        // setLoadingCidadesSelected(true);

        const buscaCiaddes = async () => {
            try {
                let resultCidade = await api.get(urlUsuariosPapel,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                // setListCidades(resultCidade.data);

                // setLoadingCidadesSelected(false);

            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'dashboard_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);

    useEffect(() => {
        let arrayUrl = window.location.pathname.split("/");
        VerificaRota(arrayUrl, navigate);

        buscaCidades();
        // if (getToken()) {
        //     // navigate('/dashboard');
        // } else {
        //     navigate('/');

        //     // setRotasPermitidas(Array(AllRotas[0]))
        // }

        let larguraDashBoard = undefined;
        let autoFitDashBoard = true;
        switch (true) {
            case (largura < 768 && largura >= 700):
                larguraDashBoard = 300;
                autoFitDashBoard = false;
                break;
            case (largura < 700 && largura >= 576):
                larguraDashBoard = 250;
                autoFitDashBoard = false;
                break;
            case (largura < 576 && largura >= 435):
                larguraDashBoard = 350;
                autoFitDashBoard = false;
                break;
            case (largura < 435 && largura >= 345):
                larguraDashBoard = 270;
                autoFitDashBoard = false;
                break;
            case (largura < 345 && largura >= 0):
                larguraDashBoard = 230;
                autoFitDashBoard = false;
                break;
            default:
                larguraDashBoard = undefined;
                autoFitDashBoard = true;
        }

    }, []);

    return (
        <LayoutDashboard>
            <Row>
                <Col {...cardLayout}>
                    <Card title={`Bem Vindo!`} bordered={false}>

                    </Card>
                </Col>
            </Row>
            {/* <Row>
                <Col {...cardImag}>
                    <img className="imgdashboardGovfacil" style={{
                        width: '400px'
                    }} src={ImagemLogo}></img>
                </Col>
            </Row> */}

        </LayoutDashboard>
    );
}

export default Dashboard;
