import { useCallback, useState, useRef, useEffect } from 'react';
import {
    Button, Col, Row, Breadcrumb, Input, Table, Modal, Spin, Form,
    Typography, Divider, notification, Popconfirm
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import {
    cardLayout,
    modaisDeConfig,
    colLisTabelaConfigs
} from './GridStyle';

import { VerificaRota } from '../../../../../services/VerificaSePodeAcessar';
import { FaCogs, FaSearch } from 'react-icons/fa';
import { api } from '../../../../../services/apiAxios';
import { getToken } from '../../../../../services/LStorage/token';
import { useNavigate } from 'react-router-dom';
import { urlVeiculoProprioTipo } from '../../../../../services/urls';
import { useGetColumnSearchProps } from '../../../../../hooks/FilterTable/string';
import './style.css'

const IconCogs = (props: any) => <Icon {...props} component={FaCogs} ></Icon>

const { Title } = Typography;

export const CrudVeiculo = () => {

    const formRef = useRef<any>(null);

    const navigate = useNavigate();

    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<any>>([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [dadosTabelaState, setDadosTabelaState] = useState([]);
    const [hasSelectedAdd, setHasSelectedAdd] = useState(true);
    const [hasSelectedEdit, setHasSelectedEdit] = useState(false);
    const [hasSelectedDel, setHasSelectedDel] = useState(false);
    const [loadingFormAdd, setLoadingFormAdd] = useState(false);
    const [modalAddConfigVisible, setModalAddConfigVisible] = useState(false);

    //State de editar usuario
    const [initialValueEditId, setInitialValueEditId] = useState<undefined | number>(undefined);

    //executa apenas ao iniciar o component
    useEffect(() => {

        let arrayUrl = window.location.pathname.split("/");
        VerificaRota(arrayUrl, navigate);

        setLoadingTable(true);
        const buscaDeDados = async () => {
            try {
                let result = await api.get(urlVeiculoProprioTipo,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                setDadosTabelaState(result.data);
                setLoadingTable(false);

            } catch (error) {
                setLoadingTable(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }

        buscaDeDados();

    }, []);

    //executa apenas quando salvar
    const salvarModalRecarregaTable = useCallback(() => {
        setLoadingTable(true);
        const buscaDeDados = async () => {
            try {
                let result = await api.get(urlVeiculoProprioTipo,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                setDadosTabelaState(result.data);
                setLoadingTable(false);

            } catch (error) {
                setLoadingTable(false);
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel buscar os dados!',
                });
            }
        }

        buscaDeDados();

    }, []);

    // fim do search da tabela de listagem

    const clickBotaoDeletarDados = useCallback((): void => {
        const buscaDeDados = () => {
            // if (selectedRowKeys.length == 1 || selectedRowKeys.length > 1) {
            if (selectedRowKeys.length === 1) {
                let obj: Array<any> = [];
                dadosTabelaState.forEach(({ tipo, id }): void => {

                    selectedRowKeys.forEach((val, i) => {
                        if (id === val) {
                            obj.push({ id })
                        }
                    });

                })

                const excluirDadosModal = async () => {
                    try {
                        await api.delete(urlVeiculoProprioTipo + '/' + obj[0].id, {
                            //EXEMPLO APENAS PARA EXCLUIR UM [0] ATE CORRIGIR O BACK
                            // data: obj[0],
                            headers: { 'Authorization': 'Bearer ' + getToken() }
                        })

                        notification.success({
                            message: 'Sucesso',
                            description:
                                'Seus dados foram excluídos com sucesso!',
                        });
                        setLoadingFormAdd(false);
                        salvarModalRecarregaTable();
                        setInitialValueEditId(undefined);
                    } catch (error) {
                        setLoadingFormAdd(false);
                        notification.error({
                            message: 'Erro',
                            description:
                                'Não foi possivel salvar, entre em contato com suporte!',
                        });
                    }
                }
                excluirDadosModal();
                setHasSelectedAdd(true);
                setHasSelectedEdit(false);
                setHasSelectedDel(false);
                setSelectedRowKeys([]);

            }
        }

        buscaDeDados();

        // setModalAddConfigVisible(true);
    }, [selectedRowKeys, dadosTabelaState, salvarModalRecarregaTable]);

    const clickCancelModalAddConfig = useCallback(() => {
        setModalAddConfigVisible(false);
        formRef.current.resetFields();
    }, []);

    const clickSubmitModalAddConfig = useCallback((): void => {
        formRef.current?.validateFields()
            .then((values: any) => {
                setLoadingFormAdd(true);

                //verifica se ele esta editando ou é um novo
                if (initialValueEditId) {
                    values.id = initialValueEditId;

                    const editarDadosModal = async () => {
                        try {
                            await api.put(urlVeiculoProprioTipo + '/' + initialValueEditId, values,
                                { headers: { 'Authorization': 'Bearer ' + getToken() } });
                            notification.success({
                                message: 'Sucesso',
                                description:
                                    'Seus dados foram editados com sucesso!',
                            });
                            setLoadingFormAdd(false);
                            salvarModalRecarregaTable();
                            clickCancelModalAddConfig();
                            setInitialValueEditId(undefined);
                        } catch (error) {
                            setLoadingFormAdd(false);
                            notification.error({
                                message: 'Erro',
                                description:
                                    'Não foi possivel salvar, entre em contato com suporte!',
                            });
                        }
                    }
                    editarDadosModal();
                } else {
                    const salvarDadosModal = async () => {
                        try {
                            await api.post(urlVeiculoProprioTipo, values,
                                { headers: { 'Authorization': 'Bearer ' + getToken() } });
                            notification.success({
                                message: 'Sucesso',
                                description:
                                    'Seus dados foram criados com sucesso!',
                            });
                            setLoadingFormAdd(false);
                            salvarModalRecarregaTable();
                            clickCancelModalAddConfig();
                        } catch (error) {
                            setLoadingFormAdd(false);
                            notification.error({
                                message: 'Erro',
                                description:
                                    'Não foi possivel salvar, entre em contato com suporte!',
                            });
                        }
                    }
                    salvarDadosModal();
                }
                setHasSelectedAdd(true);
                setHasSelectedEdit(false);
                setHasSelectedDel(false);
                setSelectedRowKeys([])

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, [initialValueEditId, clickCancelModalAddConfig, salvarModalRecarregaTable]);

    //abrir modal
    const clickBotaoAddEsfera = useCallback((): void => {
        setInitialValueEditId(undefined);
        setModalAddConfigVisible(true);
    }, []);

    //Editar dados
    const clickBotaoEditar = useCallback((): void => {

        const buscaDeDados = () => {
            if (selectedRowKeys.length === 1) {
                let obj: Array<any> = [];
                dadosTabelaState.forEach(({ tipo, id }): void => {
                    if (id === selectedRowKeys[0]) {
                        obj.push({ id, tipo })
                    }
                })

                if (formRef.current) {
                    formRef.current.setFieldsValue(
                        { nome: obj[0].tipo }
                    );
                } else {
                    setTimeout(() => {
                        formRef.current?.setFieldsValue(
                            { nome: obj[0].tipo }
                        );
                    }, 100);
                }

                setInitialValueEditId(obj[0].id)
                setModalAddConfigVisible(true);

            }
        }

        buscaDeDados();

        // setModalAddConfigVisible(true);
    }, [selectedRowKeys, dadosTabelaState, formRef]);

    // click na linha da tabela de listagem de usuario
    const onclickTable = useCallback((record: any, row: any): void => {

        //verifica se nome esta no array
        if (selectedRowKeys.includes(record.id)) {
            let filter = selectedRowKeys.filter((val) => {
                return val !== record.id
            })
            if (filter.length === 1) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(false);
                setHasSelectedEdit(true);
                setHasSelectedDel(true);
            } else if (filter.length === 0) {
                setSelectedRowKeys(filter);
                setHasSelectedAdd(true);
                setHasSelectedEdit(false);
                setHasSelectedDel(false);
            } else {
                setSelectedRowKeys(filter);
            }

        } else {
            if (selectedRowKeys.length === 0) {
                setSelectedRowKeys([record.id]);
                setHasSelectedAdd(false);
                setHasSelectedEdit(true);
                setHasSelectedDel(true);
            } else {
                let teste = selectedRowKeys.concat(record.id);
                setSelectedRowKeys(teste);
                setHasSelectedAdd(false);
                setHasSelectedEdit(false);
                setHasSelectedDel(true);
            }
        }
    }, [selectedRowKeys]);

    //checkbox da tabela de listagem
    const onSelectChange = useCallback((rowKeys: Array<string | number>): void => {

        if (rowKeys.length > 1) {
            setSelectedRowKeys(rowKeys);
            setHasSelectedAdd(false);
            setHasSelectedEdit(false);
            setHasSelectedDel(false);
        } else if (rowKeys.length === 0) {
            setSelectedRowKeys(rowKeys);
            setHasSelectedAdd(true);
            setHasSelectedEdit(false);
            setHasSelectedDel(false);
        } else {
            setSelectedRowKeys(rowKeys);
            setHasSelectedAdd(false);
            setHasSelectedEdit(true);
            setHasSelectedDel(true);
        }
    }, [selectedRowKeys, setSelectedRowKeys]);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: '1%'
    };

    const columns = [
        {
            title: 'Veículo',
            dataIndex: 'tipo',
            ...useGetColumnSearchProps('tipo'),
            width: '15%',
        }
    ];

    const onClickVoltarConfigOfSystem = useCallback((): void => {
        navigate("/configuracoes");
    }, []);

    return (
        <>
            <Row>
                <Col {...cardLayout}>
                    <Title level={3}><IconCogs style={{ fontSize: 35 }} /> Configurações do Sistema</Title>
                    <Breadcrumb>
                        <Breadcrumb.Item><a onClick={onClickVoltarConfigOfSystem}>Configurações do Sistema</a></Breadcrumb.Item>
                        <Breadcrumb.Item>Veículo</Breadcrumb.Item>
                    </Breadcrumb>
                    <Divider />
                </Col>
            </Row>
            <Row style={{ marginBottom: 16 }}>
                <Col>
                    <Button
                        type="primary"
                        className="ConfigVeiculo_BotaoAdd_qergerwg"
                        style={{
                            marginRight: 5,
                            marginBottom: 5
                        }}
                        disabled={!hasSelectedAdd}
                        onClick={clickBotaoAddEsfera} >
                        Adicionar
                    </Button>
                </Col>
                <Col>
                    <Button
                        type="primary"
                        style={{
                            marginRight: 5,
                            marginBottom: 5
                        }}
                        className="ConfigVeiculo_BotaoEditar_ffervregbrtyt"
                        onClick={clickBotaoEditar} disabled={!hasSelectedEdit}
                    >
                        Editar
                    </Button>
                    <Popconfirm
                        title="Deseja realmente excluir?"
                        onConfirm={clickBotaoDeletarDados}
                        onCancel={() => { }}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <Button
                            style={{
                                marginRight: 5,
                                marginBottom: 5
                            }}
                            type="primary" danger
                            // disabled={!hasSelectedDel}
                            disabled={!hasSelectedEdit}
                        >
                            <DeleteOutlined />
                        </Button>
                    </Popconfirm>
                </Col>
            </Row>
            <Row>
                <Col {...colLisTabelaConfigs}>
                    <Table
                        loading={loadingTable}
                        rowKey={(record) => record.id}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => { onclickTable(record, rowIndex) }, // click row
                            };
                        }}
                        rowSelection={rowSelection}
                        columns={columns} dataSource={dadosTabelaState}
                        size="small"
                        pagination={{ locale: { items_per_page: '' } }}
                    />
                </Col>
            </Row>
            <Modal
                title="Adicionar Veículo"
                visible={modalAddConfigVisible}
                className="modalAddAssociacao"
                onOk={clickSubmitModalAddConfig}
                onCancel={clickCancelModalAddConfig}
                footer={[
                    <Button key="back" disabled={loadingFormAdd} onClick={clickCancelModalAddConfig}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" loading={loadingFormAdd} onClick={clickSubmitModalAddConfig}>
                        Salvar
                    </Button>,
                ]}
            >
                <Spin spinning={loadingFormAdd}>
                    <Form
                        ref={formRef}
                        name="formModalAddAssociacao"
                        layout="vertical"
                        initialValues={{
                            remember: true,
                        }}
                        onFinishFailed={() => { }}
                    >
                        <Row>
                            <Col {...modaisDeConfig}>
                                <Form.Item
                                    name="nome"
                                    label="Nome"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor digite o nome do veículo!',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Nome"
                                        onChange={() => {

                                            const text = formRef.current?.getFieldValue('nome')
                                            const first = text.charAt(0).toUpperCase()
                                            const rest = text.substring(1);

                                            formRef.current?.setFieldsValue(
                                                { 'nome': first + rest }
                                            )

                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
}
