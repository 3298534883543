import { useCallback, useEffect, useState, useRef } from 'react';
import {
    Row, Col, Typography, Button, notification, Divider,
    Form, Input
} from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import { colInputs, colFull } from './GridStyle';

// import { getToken } from './../../services/LStorage/token';
import { VerificaRota } from './../../services/VerificaSePodeAcessar';
import LayoutDashboard from './../../layout/LayoutAdmin';
import { urlUsuariosUsuarioEmail, urlUsuariosUsuarioUpdate } from './../../services/urls';
import { api } from './../../services/apiAxios';
import { EmitirErro } from '../../services/EmitirErro';
import { SairDoSistema } from '../../services/LStorage/SairSistema';
import { getToken } from './../../services/LStorage/token';
// import './style.css';

// import {
//     removeAcento
// } from './../../utils/RemoveAcentos';
import { getIdUsuario, getUsuario } from '../../services/LStorage/UsuarioERefresh';
import { Loading } from '../../components/Loading';
import { mask, unMask } from '../../utils/MascaraDeCampos';


const { Title } = Typography;

// const largura = window.innerWidth;
// const altura = window.innerHeight;

const PerfilUsuario = () => {

    const navigate = useNavigate();
    let { id } = useParams();

    const refForm = useRef<any>(null);

    const [obrigatorioConfirm, setObrigatorioConfirm] = useState(false);
    // const [estaEditando, setEstaEditando] = useState(false);

    const [loading, setLoading] = useState(false);


    //setando dados das cidades no selected
    const buscaDadosUsuario = useCallback((id: string) => {

        const buscaCiaddes = async () => {
            try {
                //pega dados do localStorage se existir
                let usuarioData = getUsuario();

                if (usuarioData) {

                    refForm.current?.setFieldsValue(
                        {
                            email: usuarioData.email,
                            nome: usuarioData.nome,
                        }
                    );

                    //setValue input telefone with mask
                    if (usuarioData.telefone) {
                        const originalValue = unMask(usuarioData.telefone);
                        const maskedValue = mask(originalValue, [
                            "(99) 9999-9999",
                            "(99) 9 9999-9999"
                        ]);
                        refForm.current?.setFieldsValue({
                            telefone: maskedValue
                        })
                    }
                }

            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'perfil_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);

    useEffect(() => {

        let arrayUrl = window.location.pathname.split("/");
        VerificaRota(arrayUrl, navigate);

        if (typeof id === 'string') {

            buscaDadosUsuario(id);

        }


    }, []);


    const validaEmail = useCallback((email: string): boolean => {
        let er = new RegExp(/^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/);

        if (email == '' || !er.test(email)) { return false; }
        return true;

    }, []);

    const buscarEmailSeJaExiste = useCallback((email: string): Promise<boolean> => {

        const buscaDadosTipoDeContrato = async (): Promise<boolean> => {
            try {
                let result = await axios.get(urlUsuariosUsuarioEmail + '/' + email,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });
                if (typeof result.data?.id === 'string') {
                    if (result.data.id === id) {
                        return false;
                    }
                    return true;
                } else {
                    return false;
                }

            } catch (error) {
                notification.error({
                    message: 'Erro',
                    description:
                        'Não foi possivel consultar email!',
                });
                return false
            }
        }

        return buscaDadosTipoDeContrato();

    }, []);

    const onChangeInputTelefone = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, [
            "(99) 9999-9999",
            "(99) 9 9999-9999"
        ]);

        refForm.current.setFieldsValue(
            { telefone: maskedValue }
        );

        if (maskedValue.length >= 14) {
            refForm.current.validateFields(["telefone"])
        }

    }, []);

    const submitBotaoEditar = useCallback(() => {


        const salvarDadosEditar = async (obj: any) => {
            try {
                setLoading(true);

                const result = await api.put(urlUsuariosUsuarioUpdate, obj,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram editados com sucesso!',
                });

                const objSalvar: any = {
                    telefone: result.data.telefone,
                    email: result.data.email,
                    nome: result.data.nome,
                    primeiro_acesso: result.data.primeiro_acesso,
                }

                localStorage.setItem('@SisPortaria:usuario', JSON.stringify(
                    { usuario: objSalvar }
                ));

                // setDataListCidades(resultCidade.data);

                setLoading(false);
                navigate('/dashboard');

            } catch (error) {
                setLoading(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'perfil_dkwdfaiweyi')
                }


            }
        }

        refForm.current?.validateFields()
            .then((values: any) => {

                values.telefone = unMask(values.telefone);

                salvarDadosEditar(values);

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, []);

    return (
        <LayoutDashboard>
            <Loading

                loading={loading}
            >
                <Row>
                    <Col {...colFull}>
                        <Title level={4}>
                            <UserAddOutlined style={{ fontSize: 25, marginRight: 10 }} />
                            {`Editar usuário`}
                        </Title>
                        <Divider />
                    </Col>
                </Row>

                <Form
                    ref={refForm}
                    name="adicionarUsuario"
                    layout="vertical"
                    // onFieldsChange={onFieldsChange}
                    initialValues={{
                        remember: true,
                    }}
                    // onFinish={onFinish}
                    // onFinishFailed={() => { }}
                    autoComplete="off"
                >
                    <Row>
                        <Col {...colInputs}>
                            <Form.Item
                                style={{
                                    margin: 5
                                }}
                                name="nome"
                                label="Nome"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor digite seu nome!',
                                    },
                                ]}
                            >
                                <Input autoComplete="off"
                                    placeholder="Nome"
                                    onChange={() => {

                                        const text = refForm.current?.getFieldValue('nome')
                                        const first = text.charAt(0).toUpperCase()
                                        const rest = text.substring(1);

                                        refForm.current?.setFieldsValue(
                                            { 'nome': first + rest }
                                        )

                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                style={{
                                    margin: 5
                                }}
                                name="email"
                                label="Email (Login)"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor digite seu email!',
                                    },
                                    () => ({
                                        validator(rule, value) {
                                            return new Promise((resolve: (value?: any) => void, reject) => {
                                                //CUIDADO AO EDITAR PARA PODER ACEITAR MESMO EMAIL
                                                if (value != '' && value != null) {
                                                    if (validaEmail(value)) {
                                                        buscarEmailSeJaExiste(value).then(valorPromessa => {
                                                            if (valorPromessa) {
                                                                reject("Email já existe!");
                                                            } else {
                                                                resolve();
                                                            }
                                                        }).catch(error => {
                                                            resolve();
                                                        })
                                                    } else {
                                                        reject("Email inválido");
                                                    }
                                                } else {
                                                    reject("Email é obrigatório");
                                                    // if (propsState.nome == "painel" || propsState.nome == "todos") {
                                                    //     reject('');
                                                    // } else {
                                                    //     resolve();
                                                    // }

                                                }
                                            });
                                        },
                                    }),
                                ]}
                            >
                                <Input autoComplete="off" placeholder="Digite seu email!" />
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: "Preencha o telefone!",
                                        max: 16
                                    },
                                    () => ({
                                        validator(rule, value) {
                                            return new Promise((resolve: (value?: any) => void, reject) => {
                                                //CUIDADO AO EDITAR PARA PODER ACEITAR MESMO EMAIL
                                                if (value != '' && value != null) {
                                                    value = unMask(value);
                                                    if (value.length >= 10) {
                                                        // buscarCelularSeJaExiste(value).then(valorPromessa => {
                                                        //     if (valorPromessa) {
                                                        //         reject("Celular já existe!");
                                                        //     } else {
                                                        //         resolve();
                                                        //     }
                                                        // }).catch(error => {
                                                        //     resolve();
                                                        // })
                                                        resolve();
                                                    } else {
                                                        reject("Celular inválido");
                                                    }
                                                } else {
                                                    reject('');
                                                }
                                            });
                                        },
                                    }),
                                ]}
                                name="telefone"
                                label="Telefone"
                                style={{ margin: 5 }}
                            >
                                <Input
                                    type="tel"
                                    autoComplete="off"
                                    onChange={(e) => onChangeInputTelefone(e)}
                                    placeholder="(44) 9 9999-9999"
                                />
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                style={{
                                    margin: 5
                                }}
                                name="senha"
                                label="Senha Atual"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Por favor digite sua senha!',
                                    },
                                    () => ({
                                        validator(rule, value) {
                                            if (value?.length >= 1) {
                                                setObrigatorioConfirm(true);
                                            }
                                            if (value?.length == 0) {
                                                setObrigatorioConfirm(false);
                                            }

                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password autoComplete="off" placeholder="Senha" />
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                style={{
                                    margin: 5
                                }}
                                name="nova_senha"
                                label="Nova Senha"
                                rules={[
                                    {
                                        required: obrigatorioConfirm,
                                        message: 'Por favor digite sua nova senha!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('senha') !== value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('Senha está igual a anterior!');
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password autoComplete="off" placeholder="Por favor digite nova senha!" />
                            </Form.Item>
                        </Col>


                        {/* <Col {...colInputs}>
                        <Form.Item
                            name="regras"
                            label="Perfil do usuário"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor selecione o perfil!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                placeholder="Selecione..."
                                loading={loadingDadosRegraPerfil}
                                notFoundContent={loadingDadosRegraPerfil ? <Spin size="small" /> : null}
                                filterOption={(input: any, option: any) => {
                                    let textDigit = removeAcento(input)
                                    let listCidade = removeAcento(option?.children);
                                    return listCidade.indexOf(textDigit) >= 0
                                }}
                            >
                                {
                                    dadosRegraPerfil.map((item) => {
                                        return (
                                            <Select.Option
                                                value={item.id}
                                                key={item.id}
                                            >
                                                {item.nome}
                                            </Select.Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col> */}
                        <Col {...colFull}>
                            <div className="botaoSalvarStep" style={{
                                flexDirection: 'row',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                // background: 'red'
                            }}>
                                <Button
                                    key="submit"
                                    type="primary"
                                    style={{
                                        marginTop: 20
                                    }}
                                    onClick={() => {
                                        submitBotaoEditar()
                                    }}
                                >
                                    {"Salvar"}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Loading>

        </LayoutDashboard >
    );
}

export default PerfilUsuario;
