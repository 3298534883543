import { useCallback, useState, useRef, useContext, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Spin, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { api } from '../../services/apiAxios';

// @ts-ignore
import ImagemLogo from './../../assets/imagens/logo_final.png';
// @ts-ignore
import ImagemBGLogo from './../../assets/imagens/loginBG.png';

import { urlUsuariosUsuarioPrimeiroAcesso } from './../../services/urls';
import { GlobalContext } from './../../contexts/globalContext';
import { EmitirErro } from './../../services/EmitirErro';
import { VerificaRota } from '../../services/VerificaSePodeAcessar';
import './style.css';
import { getToken } from '../../services/LStorage/token';
import { getUsuario } from '../../services/LStorage/UsuarioERefresh';
import { SairDoSistema } from '../../services/LStorage/SairSistema';
import { Loading } from '../../components/Loading';

const PrimeiroAcesso = () => {
    const navigate = useNavigate();
    const formRef = useRef<any>(null);

    const { theme } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);
    const [obrigatorioConfirm, setObrigatorioConfirm] = useState(false);

    useEffect(() => {

        let arrayUrl = window.location.pathname.split("/");
        if (VerificaRota(arrayUrl, navigate)) {

            if (!getUsuario()?.primeiro_acesso) {
                navigate('/dashboard');
            }
        }


    }, [])

    const onSubmit = useCallback((): void => {
        const buscaDeDados = async (dados: any) => {
            try {
                setLoading(true);
                let result = await api.patch(urlUsuariosUsuarioPrimeiroAcesso, dados,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    }
                );

                let obj = {
                    ...result.data,
                    senha: undefined,
                    regras: undefined
                }

                localStorage.setItem('@SisPortaria:usuario', JSON.stringify(
                    { usuario: obj }
                ));

                formRef.current?.resetFields();
                setLoading(false);

                let arrayUrl = result.data.nome.split(" ")
                notification.success({
                    message: `Bem vindo ${arrayUrl[0]}`,
                });
                navigate('/dashboard');

            } catch (error) {
                setLoading(false);

                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'primeiroacesso_dkwdfaiweyi')
                }
            }
        }

        formRef.current?.validateFields()
            .then((values: any) => {
                buscaDeDados(values);
            })
            .catch(() => {
                // .catch((errorInfo: any) => {

                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, []);

    return (
        <>
            <Loading
                loading={loading}
                paddingLayout={false}
            />
            <Row gutter={24} style={{ maxHeight: "100vh", margin: 0 }} align="middle">

                <Col
                    className='fotoFundoPrimeiroAcesso'
                    style={{
                        filter: theme === 'light' ? 'grayscale(0%)' : 'grayscale(100%)',
                        display: 'flex',
                        padding: 0,
                        flexDirection: 'column',
                        // alignItems: 'center',
                        justifyContent: 'flex-end',
                        backgroundColor: '#163561'

                    }} flex={3}
                >
                    <img style={{
                        width: '100%',
                    }} src={ImagemBGLogo}></img>
                </Col>
                <Col flex={1} style={{
                    height: '100vh',
                    // backgroundColor: '#243463',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Form
                        name="formPrimeiroAcesso"
                        ref={formRef}
                        initialValues={{
                            remember: true,
                        }}
                        style={{
                            width: '100%'
                        }}
                        layout="vertical"
                        onFinish={onSubmit}
                    // onFinishFailed={onFinishFailed}
                    >
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <img style={{
                                width: '200px',
                                // margin: '20px'
                            }} src={ImagemLogo}></img>
                            <h1
                                style={{
                                    fontFamily: 'Orbitron',
                                    fontWeight: '800'
                                }}
                            >iControl Portaria</h1>

                            <div
                                style={{
                                    marginTop: 10,
                                    marginBottom: -15
                                }}
                            >
                                <p>Por favor redefina sua senha de primeiro acesso!</p>
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            // alignItems: 'center',
                            // justifyContent: 'center'
                            margin: 30
                        }}>

                            {/* <Form.Item
                                style={{
                                    marginBottom: 15
                                }}
                                name="senha"
                                label="Senha Provisória"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor digite sua senha!',
                                    },
                                    () => ({
                                        validator(rule, value) {
                                            if (value?.length >= 1) {
                                                setObrigatorioConfirm(true);
                                            }
                                            if (value?.length == 0) {
                                                setObrigatorioConfirm(false);
                                            }

                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password autoComplete="off" placeholder="Senha" />
                            </Form.Item> */}

                            <Form.Item
                                style={{
                                    marginBottom: 15
                                }}
                                name="nova_senha"
                                label="Nova Senha"
                                rules={[
                                    {
                                        required: obrigatorioConfirm,
                                        message: 'Por favor digite sua nova senha!'
                                    },
                                    () => ({
                                        validator(rule, value) {
                                            if (value?.length >= 1) {
                                                setObrigatorioConfirm(true);
                                            }
                                            if (value?.length == 0) {
                                                setObrigatorioConfirm(false);
                                            }

                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password autoComplete="off" placeholder="Por favor digite nova senha!" />
                            </Form.Item>
                            <Form.Item
                                style={{
                                    marginBottom: 15
                                }}
                                name="confimarSenha"
                                label="Confirmação da nova senha"
                                rules={[
                                    {
                                        required: obrigatorioConfirm,
                                        message: 'Por favor confirme sua senha!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('nova_senha') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('Senha deve estar igual a anterior!');
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password autoComplete="off" placeholder="Por favor digite novamente sua senha!" />
                            </Form.Item>

                            <Button
                                type="primary"
                                style={{ width: "100%" }}
                                htmlType='submit'
                            // onKeyDown={onPreesTecla}
                            // onClick={onSubmitClick}
                            >
                                Continuar
                            </Button>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <Button
                                    type="link"
                                    style={{ marginTop: 10 }}
                                    htmlType='button'
                                    // onKeyDown={onPreesTecla}
                                    onClick={() => {
                                        SairDoSistema();
                                        navigate('/')
                                    }}
                                >
                                    {`Sair`}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </Col>
            </Row>
        </>
    );
};

export default PrimeiroAcesso;
