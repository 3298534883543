export const colSelect = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 12, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 12, offset: 0 }, // > 1200
    xxl: { span: 12, offset: 0 }, // > 1600
};

export const colButton = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 18, offset: 0 }, //>576
    md: { span: 13, offset: 0 }, // >768
    lg: { span: 12, offset: 0 }, // > 992
    xl: { span: 12, offset: 0 }, // > 1200
    xxl: { span: 14, offset: 0 }, // > 1600
};

export const colInputs = {
    xs: { span: 24, offset: 0 }, //<576
    sm: { span: 24, offset: 0 }, //>576
    md: { span: 12, offset: 0 }, // >768
    lg: { span: 6, offset: 0 }, // > 992
    xl: { span: 6, offset: 0 }, // > 1200
    xxl: { span: 6, offset: 0 }, // > 1600
};
