import { useParams } from 'react-router-dom';
import LayoutDashboard from '../../../layout/LayoutAdmin';
import { CrudCargo } from './Tipos/Cargo';
import { CrudVeiculo } from './Tipos/Veiculos';


export const ConfiguracoesPage = () => {

    let { page } = useParams();

    let Component = () => <></>
    if (page === 'cargo') Component = () => <CrudCargo />
    if (page === 'veiculo') Component = () => <CrudVeiculo />

    return (
        <>
            <LayoutDashboard>
                {Component()}
            </LayoutDashboard>
        </>
    );
}
