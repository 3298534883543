import { useCallback, useEffect, useRef, useState } from 'react';
import {
    Col, Row, Typography,
    Button, Form, notification,
    Input, Select, FormInstance, Divider, Modal
} from 'antd';
import { mask, unMask } from '../../../../../utils/MascaraDeCampos';
import { Loading } from '../../../../../components/Loading';
import { SairDoSistema } from '../../../../../services/LStorage/SairSistema';
import { EmitirErro } from '../../../../../services/EmitirErro';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../../../services/apiAxios';
import { getToken } from '../../../../../services/LStorage/token';
import { urlVeiculoProprioTipo, urlVeiculoProprio } from '../../../../../services/urls';
import { colFull, colInputs } from './GridStyle';
import { removeAcento } from '../../../../../utils/RemoveAcentos';
import {
    CarOutlined, ExclamationCircleOutlined
} from '@ant-design/icons';
import { validacaoPermissao } from '../../../../../services/VerificaSePodeAcessar';

const { Text, Title, Link } = Typography;
const { confirm } = Modal;

interface interfaceTipoVeiculo {
    tipo: string,
    id: number
}

interface IntefObjVeiculo {
    cor: string,
    "createdAt": string,
    descricao: string,
    empresaId: string,
    "id": string,
    noPatio: boolean
    placa: string,
    tipo: string,
    ultimo_km_chegada: number,
    updatedAt: string
}

type interfOpcaoScreen = 'listar' | 'addUpdate';

interface PropsModal {
    id: string | null | undefined;
    alterOpcaoScreen: (opcao: interfOpcaoScreen, id?: string) => void;
    // empresaSelecionado?: IntefEmpresas,
    idEmpresa: string | null | undefined;
}

export const VeiculosAddOrUpdate = ({ id, alterOpcaoScreen, idEmpresa }: PropsModal) => {

    const navigate = useNavigate();
    const refValueUltimo_km_chegada = useRef<any>();

    const refFormModalAddTipoVeiculo = useRef<FormInstance>(null);
    const [modalAddTipoVeiculoVisible, setModalAddTipoVeiculoVisible] = useState(false);
    const [loadingFormModalTipoVeiculo, setLoadingFormModalTipoVeiculo] = useState(false);

    const refForm = useRef<any>(null);
    const refDadosTipoVeiculoState = useRef<Array<interfaceTipoVeiculo>>([]);

    const [estaEditando, setEstaEditando] = useState(false);
    const [dadosDoTipoVeiculoList, setDadosDoTipoVeiculoList] = useState<Array<interfaceTipoVeiculo>>([]);
    const [loading, setLoading] = useState(false);

    //desmontar ou destruir component
    useEffect(() => () => {

        let dadosLocalStorage: string | null = localStorage.getItem('@SisPortaria:veiculoEditForEmpresa');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData) {
            localStorage.removeItem('@SisPortaria:veiculoEditForEmpresa');
        }

    }, []);

    const buscarDadosTipoVeiculoList = useCallback((setCampo?: any, nome?: any, selectIdAndSet?: any, id?: any) => {

        if (setCampo) {
            refForm.current?.setFieldsValue({ tipoId: nome })
        }

        const buscaDadosCargo = async () => {
            try {
                let result = await api.get(urlVeiculoProprioTipo,
                    { headers: { 'Authorization': 'Bearer ' + getToken() } });

                refDadosTipoVeiculoState.current = result.data;
                setDadosDoTipoVeiculoList(result.data)

                if (selectIdAndSet) {
                    let nome = null;
                    await result.data.forEach((val: interfaceTipoVeiculo) => {
                        if (val.id == id) {
                            nome = val.tipo
                        }
                    });
                    refForm.current?.setFieldsValue(
                        { tipoId: nome }
                    )
                }

            } catch (error) {
                console.error(error);
            }
        }

        buscaDadosCargo();

    }, []);

    //setando dados das cidades no selected
    const buscaDadosUsuario = useCallback(() => {

        const buscaCiaddes = async () => {
            try {
                //pega dados do localStorage se existir
                let dadosLocalStorage: string | null = localStorage.getItem('@SisPortaria:veiculoEditForEmpresa');
                let localUserData: any = null;
                if (typeof dadosLocalStorage == 'string') {
                    localUserData = JSON.parse(dadosLocalStorage);
                }

                if (localUserData) {
                    setLoading(true)

                    setTimeout(() => {
                        refForm.current?.setFieldsValue(
                            {
                                cor: localUserData.cor ? localUserData.cor : undefined,
                                descricao: localUserData.descricao ? localUserData.descricao : undefined,
                                empresaId: localUserData.empresaId ? localUserData.empresaId : undefined,
                                // tipo: localUserData.tipo ? localUserData.tipo : undefined,
                                placa: localUserData.placa ? localUserData.placa : undefined,
                                ultimo_km_chegada: typeof localUserData?.ultimo_km_chegada === 'number' ?
                                    localUserData.ultimo_km_chegada
                                    : undefined,
                            }
                        );

                        refValueUltimo_km_chegada.current = typeof localUserData?.ultimo_km_chegada === 'number' ?
                            localUserData.ultimo_km_chegada
                            : undefined

                        setLoading(false)
                    }, 500);

                    if (localUserData.tipoId) {
                        buscarDadosTipoVeiculoList(true, localUserData.tipoId);
                    } else {
                        buscarDadosTipoVeiculoList();
                    }
                }

            } catch (error) {
                let msgErro: any = (error as Error);
                setLoading(false)

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);

    const configuracoesIniciaisDeCriar = useCallback((idEmpresa: any) => {
        setTimeout(() => {
            refForm.current?.setFieldsValue(
                {
                    empresaId: idEmpresa,
                }
            );
        }, 500);

        setEstaEditando(false)

        buscarDadosTipoVeiculoList();

    }, []);


    useEffect(() => {

        if (typeof id === 'string') {
            if (id === 'criar') {

                //adicionar
                configuracoesIniciaisDeCriar(idEmpresa)
            } else {
                setEstaEditando(true)
                buscaDadosUsuario();

            }
        } else {

            //adicionar
            configuracoesIniciaisDeCriar(idEmpresa)
        }

    }, [id, idEmpresa]);


    const onChangeInputPlaca = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, [
            "AAA-9S99"
        ]);

        refForm.current.setFieldsValue(
            { placa: maskedValue.toUpperCase() }
        );
        if (maskedValue.length >= 14) {
            refForm.current.validateFields(["placa"])
        }

    }, []);

    const onChangeInputQuilometragem = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, [
            "999999"
        ]);

        refForm.current.setFieldsValue(
            { ultimo_km_chegada: maskedValue.toUpperCase() }
        );
        if (maskedValue.length <= 6) {
            refForm.current.validateFields(["ultimo_km_chegada"])
        }

    }, []);

    const submitBotaoSalvar = useCallback(() => {


        const salvarDadosCriar = async (obj: any) => {
            try {
                setLoading(true);
                await api.post(urlVeiculoProprio, obj,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Dados criados com sucesso!',
                });

                setLoading(false);

                alterOpcaoScreen('listar')

            } catch (error) {
                setLoading(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        refForm.current?.validateFields()
            .then((values: any) => {

                salvarDadosCriar(values);

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, []);

    const submitBotaoEditar = useCallback((id: any) => {


        const salvarDadosEditar = async (obj: any) => {
            try {
                setLoading(true);

                await api.put(urlVeiculoProprio + '/' + id, obj,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Dados editados com sucesso!',
                });

                setLoading(false);
                alterOpcaoScreen('listar')

            } catch (error) {
                setLoading(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        refForm.current?.validateFields()
            .then((values: any) => {


                if (refValueUltimo_km_chegada.current == values.ultimo_km_chegada) {
                    //pode editar
                    salvarDadosEditar(values);
                } else {

                    confirm({
                        title: 'Atenção: Quilometragem atual alterada.',
                        icon: <ExclamationCircleOutlined />,
                        cancelText: 'Cancelar',
                        content: 'Se o veículo possui alguma movimentação os dados poderão ficar divergentes, essa opção só é recomendada para veículos recém cadastrados e sem nenhuma movimentação de entrada e saída.',
                        onOk() {
                            salvarDadosEditar(values);
                        },
                        onCancel() {
                            // console.log('Cancel');
                        },
                    });

                }


            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, []);

    const clickSubmitModalAddTipoVeiculo = useCallback((): void => {
        refFormModalAddTipoVeiculo.current?.validateFields()
            .then((values: any) => {
                setLoadingFormModalTipoVeiculo(true);

                const salvarDadosModal = async () => {
                    try {
                        let resultReq = await api.post(urlVeiculoProprioTipo, values,
                            {
                                headers: { 'Authorization': 'Bearer ' + getToken() }
                            });
                        notification.success({
                            message: 'Sucesso',
                            description:
                                'Seus dados foram criados com sucesso!',
                        });

                        setLoadingFormModalTipoVeiculo(false);
                        buscarDadosTipoVeiculoList(true, resultReq.data.nome);
                        clickCancelModalAddTipoVeiculo();
                    } catch (error) {
                        setLoadingFormModalTipoVeiculo(false);
                        notification.error({
                            message: 'Erro',
                            description:
                                'Não foi possivel salvar, entre em contato com suporte!',
                        });
                    }
                }
                salvarDadosModal();

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });
    }, []);

    const clickCancelModalAddTipoVeiculo = useCallback((): void => {
        setModalAddTipoVeiculoVisible(false);
        refFormModalAddTipoVeiculo.current?.resetFields();
    }, []);
    const onClickAddTipoVeiculo = useCallback((): void => {
        setModalAddTipoVeiculoVisible(true);
    }, []);

    return (
        <>
            <Loading
                loading={loading}
            >
                <Row>
                    <Col {...colFull}>
                        <Title level={4}>
                            <CarOutlined style={{ fontSize: 25, marginRight: 10 }} />
                            {`${estaEditando ? 'Editar' : 'Adicionar'} Veículo`}
                        </Title>
                        <div
                            style={{
                                display: 'flex'
                            }}
                        >
                            <Link
                                onClick={() => [
                                    alterOpcaoScreen('listar')
                                ]}
                                style={{
                                    marginRight: 5
                                }}
                            >
                                <Text
                                    type='secondary' underline
                                >Listagem</Text>
                            </Link>

                            <Text
                                style={{
                                    marginRight: 5
                                }} type='secondary'
                            >/</Text>

                            <Text style={{
                                marginRight: 5
                            }}>
                                {
                                    `${estaEditando ? 'Editar' : 'Adicionar'}`
                                }
                            </Text>
                        </div>
                        <Divider />
                    </Col>
                </Row>
                <Form
                    ref={refForm}
                    name="adicionarUsuario"
                    layout="vertical"
                    // onFieldsChange={onFieldsChange}
                    initialValues={{
                        remember: true,
                    }}
                    // onFinish={onFinish}
                    // onFinishFailed={() => { }}
                    autoComplete="off"
                >
                    <Row>
                        <Col {...colInputs}>
                            <Form.Item
                                style={{
                                    margin: 5
                                }}
                                name="descricao"
                                label="Descrição"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor digite a descrição!',
                                    },
                                ]}
                            >
                                <Input autoComplete="off"
                                    placeholder="Descrição"
                                    onChange={() => {

                                        const text = refForm.current?.getFieldValue('descricao')
                                        const first = text.charAt(0).toUpperCase()
                                        const rest = text.substring(1);

                                        refForm.current?.setFieldsValue(
                                            { 'descricao': first + rest }
                                        )

                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                style={{
                                    margin: 5
                                }}
                                name="placa"
                                label="Placa"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor digite a placa!',
                                        max: 8
                                    },
                                    () => ({
                                        validator(rule, value) {
                                            return new Promise((resolve: (value?: any) => void, reject) => {
                                                //CUIDADO AO EDITAR PARA PODER ACEITAR MESMO EMAIL
                                                if (value != '' && value != null) {
                                                    value = unMask(value);

                                                    if (value.length >= 7) {
                                                        // buscarCelularSeJaExiste(value).then(valorPromessa => {
                                                        //     if (valorPromessa) {
                                                        //         reject("Celular já existe!");
                                                        //     } else {
                                                        //         resolve();
                                                        //     }
                                                        // }).catch(error => {
                                                        //     resolve();
                                                        // })
                                                        resolve();
                                                    } else {
                                                        reject("Placa inválida");
                                                    }
                                                } else {
                                                    reject('');
                                                }
                                            });
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    autoComplete="off"
                                    placeholder="Placa"
                                    onChange={(e) => onChangeInputPlaca(e)}
                                />
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                style={{
                                    margin: 5
                                }}
                                name="cor"
                                label="Cor"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor digite a cor!',
                                    },
                                ]}
                            >
                                <Input autoComplete="off" placeholder="Cor"
                                    onChange={() => {

                                        const text = refForm.current?.getFieldValue('cor')
                                        const first = text.charAt(0).toUpperCase()
                                        const rest = text.substring(1);

                                        refForm.current?.setFieldsValue(
                                            { 'cor': first + rest }
                                        )

                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                style={{
                                    margin: 5
                                }}
                                name="tipoId"
                                label="Tipo"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor selecione o tipo!',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Selecione..."
                                    filterOption={(input: any, option: any) => {
                                        let textDigit = removeAcento(input)
                                        let listCidade = removeAcento(option?.children);
                                        return listCidade.indexOf(textDigit) >= 0
                                    }}
                                    dropdownRender={menu => (
                                        <div>
                                            {
                                                validacaoPermissao(['admin']) &&
                                                <>
                                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                        <Button block onClick={onClickAddTipoVeiculo} type="dashed">Adicionar Tipo</Button>
                                                    </div>
                                                    <Divider style={{ margin: '4px 0' }} />
                                                </>
                                            }
                                            {menu}
                                        </div>
                                    )}
                                >
                                    {
                                        dadosDoTipoVeiculoList.map((tipo) => {
                                            return (
                                                <Select.Option
                                                    value={tipo.id}
                                                    key={tipo.id}
                                                >
                                                    {tipo.tipo}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                style={{
                                    margin: 5
                                }}
                                name="ultimo_km_chegada"
                                label="Quilometragem atual"
                                rules={[
                                    {
                                        required: true,
                                        // max: 6,
                                        message: 'Por favor digite a quilometragem atual!',
                                    },
                                    () => ({
                                        validator(rule, value) {
                                            return new Promise((resolve: (value?: any) => void, reject) => {
                                                //CUIDADO AO EDITAR PARA PODER ACEITAR MESMO EMAIL

                                                if ((typeof value === 'number' ||
                                                typeof value === 'string'
                                                ) && value != null) {
                                                    if (typeof value !== 'number') {

                                                        value = unMask(value);
                                                    } else {
                                                        // é numero
                                                        resolve();
                                                    }

                                                    if (value.length <= 6) {
                                                        // buscarCelularSeJaExiste(value).then(valorPromessa => {
                                                        //     if (valorPromessa) {
                                                        //         reject("Celular já existe!");
                                                        //     } else {
                                                        //         resolve();
                                                        //     }
                                                        // }).catch(error => {
                                                        //     resolve();
                                                        // })
                                                        resolve();
                                                    } else {
                                                        reject("Quilometragem inválida");
                                                    }
                                                } else {
                                                    reject('');
                                                }
                                            });
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    autoComplete="off"
                                    maxLength={6}
                                    placeholder="Quilometragem atual"
                                    onChange={(e) => onChangeInputQuilometragem(e)}
                                />
                            </Form.Item>
                        </Col>
                        <Col hidden {...colInputs}>
                            <Form.Item
                                name="empresaId"
                                label="Empresa"
                                style={{ margin: 5 }}
                                hidden
                            >
                                <Input disabled hidden />
                            </Form.Item>
                        </Col>
                        <Col {...colFull}>
                            <div className="botaoSalvarStep" style={{
                                flexDirection: 'row',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                // background: 'red'
                            }}>
                                <Button
                                    // type="primary"
                                    style={{
                                        marginTop: 20,
                                        marginRight: 5
                                    }}
                                    onClick={() => {
                                        alterOpcaoScreen('listar')
                                    }}
                                >
                                    {"Cancelar"}
                                </Button>

                                <Button
                                    key="submit"
                                    type="primary"
                                    style={{
                                        marginTop: 20,
                                        marginRight: 5
                                    }}
                                    onClick={() => {
                                        estaEditando ?
                                            submitBotaoEditar(id ? id : 'criar')
                                            :
                                            submitBotaoSalvar()
                                    }}
                                >
                                    {"Salvar"}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Loading >
            <Modal
                title="Adicionar tipo veículo"
                className="modalAddCargo"
                visible={modalAddTipoVeiculoVisible}
                onOk={clickSubmitModalAddTipoVeiculo}
                onCancel={clickCancelModalAddTipoVeiculo}
                footer={[
                    <Button key="back" onClick={clickCancelModalAddTipoVeiculo}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" onClick={clickSubmitModalAddTipoVeiculo}>
                        Salvar
                    </Button>,
                ]}
            >
                <Loading loading={loadingFormModalTipoVeiculo}>
                    <Form
                        ref={refFormModalAddTipoVeiculo}
                        name="formModalAddTipoVeiculo"
                        layout="vertical"
                        initialValues={{
                            remember: true,
                        }}
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Por favor digite o nome do Tipo de Veículo!',
                                        }
                                    ]}
                                    name="nome"
                                    label="Nome"
                                >
                                    <Input placeholder="Tipo de Veículo"
                                        onChange={() => {

                                            const text = refFormModalAddTipoVeiculo.current?.getFieldValue('nome')
                                            const first = text.charAt(0).toUpperCase()
                                            const rest = text.substring(1);

                                            refFormModalAddTipoVeiculo.current?.setFieldsValue(
                                                { 'nome': first + rest }
                                            )

                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Loading>
            </Modal>
        </>
    );
}
