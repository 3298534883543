import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Table, Typography, notification, Button } from 'antd';
import moment from 'moment';

import { convertDataUSParaBrasil, convertDataUSParaBrasilEHora } from '../../../../../services/Conversores';
import { useNavigate } from 'react-router-dom';
import locale from 'antd/es/locale/pt_BR';
import { urlEventoEmpresa } from '../../../../../services/urls';
import { EmitirErro } from '../../../../../services/EmitirErro';
import { SairDoSistema } from '../../../../../services/LStorage/SairSistema';
import { api } from '../../../../../services/apiAxios';
import { getToken } from '../../../../../services/LStorage/token';
import { useGetColumnSearchProps } from '../../../../../hooks/FilterTable/string';
import { useGetColumnSearchPropsData } from '../../../../../hooks/FilterTable/date';
import { GlobalContext } from '../../../../../contexts/globalContext';
import './style.css';
import { mask, unMask } from '../../../../../utils/MascaraDeCampos';


const { Text } = Typography;

// const largura = window.innerWidth;
// const altura = window.innerHeight;
interface IntefEmpresas {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "ativo": boolean,
    "nome": string,
    "cnpj": string,
    "telefone": string,
    "bairro": string | null,
    "cep": string | null,
    "cidade": string | null,
    "logradouro": string | null,
    "numero": string | null,
    "uf": string | null,
    "deletedAt": string | null
}
interface IntefDadosTable {
    "createdAt": string,
    "updatedAt": string,
    "data": string,
    "id": string,
    "responsavel": string,
    "telefone_responsavel": string,
    "observacoes": string,
    "empresaId": string,
    "usuarioId": string
}

type interfOpcaoScreen = 'listar' | 'addUpdate';

interface interPros {
    empresaSelecionado?: IntefEmpresas,
    alterOpcaoScreen: (opcao: interfOpcaoScreen, id?: string) => void;
}

export const ListagemEvento = ({ empresaSelecionado, alterOpcaoScreen }: interPros) => {

    const navigate = useNavigate();

    const { theme } = useContext(GlobalContext);

    const refContagemDoFiltro = React.useRef<Array<any>>([]);
    const refEmpresaSelecionada = React.useRef<IntefEmpresas | null | undefined>(null);

    const [dadosTabelaState, setDadosTabelaState] = useState<Array<IntefDadosTable>>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<string | number>>([]);
    const [hasSelectedEdit, setHasSelectedEdit] = useState(false);


    const [loadingScreen, setLoadingScreen] = useState(false);

    //setando dados das cidades no selected
    const buscaDadosList = useCallback((id?: string) => {


        const funcBusca = async () => {
            try {
                setLoadingScreen(true);
                let result = await api.get(urlEventoEmpresa + '/' + id,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDadosTabelaState(result.data);

                setLoadingScreen(false);

            } catch (error) {
                setLoadingScreen(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'usuarios_dkwdfaiweyi')
                }


            }
        }

        funcBusca();

    }, []);

    useEffect(() => {

        refEmpresaSelecionada.current = empresaSelecionado
        if (empresaSelecionado) buscaDadosList(empresaSelecionado.id);

    }, [empresaSelecionado]);


    // click na linha da tabela de listagem de usuario
    const onclickTable = useCallback((record: any, row: any): void => {

        if (selectedRowKeys.includes(record.id)) {
            let filter = selectedRowKeys.filter((val) => {
                return val != record.id
            })
            if (filter.length === 1) {
                setSelectedRowKeys(filter);
                setHasSelectedEdit(true);
            } else if (filter.length === 0) {
                setSelectedRowKeys(filter);
                setHasSelectedEdit(false);
            } else {
                setSelectedRowKeys(filter);
            }

        } else {
            if (selectedRowKeys.length == 0) {
                setSelectedRowKeys([record.id]);
                setHasSelectedEdit(true);
            } else {
                let teste = selectedRowKeys.concat(record.id);
                setSelectedRowKeys(teste);
                setHasSelectedEdit(false);
            }
        }
    }, [selectedRowKeys]);

    //checkbox da tabela de listagem
    // const onSelectChange = useCallback((selectedRowKeys: any): void => {
    const onSelectChange = useCallback((selectedRowKeys: Array<string | number>, selectedRows: Array<any>): void => {
        if (selectedRowKeys.length > 1) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedEdit(false);
        } else if (selectedRowKeys.length === 0) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedEdit(false);
        } else {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedEdit(true);
        }
    }, [selectedRowKeys]);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: '1%'
    };

    const columns = [
        {
            title: 'Responsável',
            dataIndex: 'responsavel',
            ...useGetColumnSearchProps('responsavel'),
            width: '10%',
            sorter: (a: any, b: any) => a.responsavel.localeCompare(b.responsavel),
            showSorterTooltip: false,
        },
        {
            title: 'Telefone',
            dataIndex: 'telefone_responsavel',
            ...useGetColumnSearchProps('telefone_responsavel'),
            sorter: (a: any, b: any) => {
                return a?.telefone_responsavel?.localeCompare(b?.telefone_responsavel)
            },
            showSorterTooltip: false,
            width: '8%',
            render: (telefone: any) => {
                if (telefone) {
                    const originalValue = unMask(telefone);
                    const maskedValue = mask(originalValue, [
                        "(99) 9999-9999",
                        "(99) 9 9999-9999"
                    ]);

                    return maskedValue;
                }
                return telefone
            }
        },
        {
            title: 'Data',
            dataIndex: 'data',
            ...useGetColumnSearchPropsData('data'),
            width: '4%',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.data) {
                    aMonMen = moment(a.data).unix()
                } else {
                    aMonMen = 0
                }
                if (b.data) {
                    bMonMen = moment(b.data).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),
            render: (data: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Text>
                        {
                            data ? convertDataUSParaBrasilEHora(data) : '-'
                        }
                    </Text>
                </div>
            ),
        },
        {
            title: 'Observações',
            dataIndex: 'observacoes',
            ...useGetColumnSearchProps('observacoes'),
            width: '10%',
            sorter: (a: any, b: any) => {
                return a?.observacoes?.localeCompare(b?.observacoes)
            },
            showSorterTooltip: false,
        },
        {
            title: 'Criado em',
            dataIndex: 'createdAt',
            ...useGetColumnSearchPropsData('createdAt'),
            width: '2%',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.createdAt) {
                    aMonMen = moment(a.createdAt).unix()
                } else {
                    aMonMen = 0
                }
                if (b.createdAt) {
                    bMonMen = moment(b.createdAt).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),
            render: (criado: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Text>
                        {
                            criado ? convertDataUSParaBrasil(criado) : '-'
                        }
                    </Text>
                </div>
            ),
        },
    ];

    const handleClickEditarVeiculo = useCallback(() => {

        if (selectedRowKeys.length == 1) {

            let objSelecionado: any = {}
            dadosTabelaState.forEach((dados): void => {
                if (dados.id === selectedRowKeys[0]) {
                    objSelecionado = dados
                }
            })

            localStorage.setItem('@SisPortaria:eventoEditForEmpresa', JSON.stringify(objSelecionado));

            alterOpcaoScreen('addUpdate', selectedRowKeys[0].toString())


        }
    }, [selectedRowKeys, dadosTabelaState]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    // justifyContent: 'flex-end',
                    // justifyContent: 'flex-end'
                    marginBottom: 15
                }}
            >
                <Button
                    type="primary"
                    className="ListEvento_BotaoAdd_efwAIUYGprdfgjbwp"
                    style={{ margin: 2 }}
                    // disabled={!hasSelectedAdd}
                    onClick={() => {
                        let dadosLocalStorage: string | null = localStorage.getItem('@SisPortaria:eventoEditForEmpresa');
                        let localUserData: any = null;
                        if (typeof dadosLocalStorage == 'string') {
                            localUserData = JSON.parse(dadosLocalStorage);
                        }

                        if (localUserData) {
                            localStorage.removeItem('@SisPortaria:eventoEditForEmpresa');
                        }

                        alterOpcaoScreen('addUpdate')
                    }}
                >
                    Adicionar
                </Button>
                <Button
                    type="default"
                    style={{ margin: 2 }}
                    className="ListEvento_BotaoEditar_regqpjkbo"
                    onClick={handleClickEditarVeiculo}
                    disabled={!hasSelectedEdit}
                >
                    Editar
                </Button>
            </div>
            <Table
                style={{
                    // marginTop: 50
                }}
                className='marginTopTables'
                rowKey={(record) => record.id}
                loading={loadingScreen}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => { onclickTable(record, rowIndex) }
                    };
                }}
                rowSelection={rowSelection}
                columns={columns} dataSource={dadosTabelaState}
                scroll={{ x: 650 }}
                onChange={
                    (pagination, filters, sorter, extra) => {
                        refContagemDoFiltro.current = extra.currentDataSource
                    }
                }
                title={(registros) => {

                    return (
                        <div
                            style={{
                                backgroundColor: theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                                padding: 10,
                                margin: -8,
                                marginBottom: 0.5
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                    dadosTabelaState.length :
                                    refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                            </Text>
                        </div>
                    )
                }}
                footer={(registros) => {
                    return (
                        <div
                            style={{
                                textAlign: 'end'
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                    dadosTabelaState.length :
                                    refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                            </Text>
                        </div>
                    )
                }}
                size="small"
                pagination={{
                    locale: { items_per_page: '' },
                    showSizeChanger: true,
                    defaultPageSize: 50,
                    position: ['topRight', 'bottomRight'],
                    pageSizeOptions: ['10', '20', '50', '100']
                }}
                locale={locale.Table}
            />

        </>
    );
}
