import jwt_decode from 'jwt-decode';
import { addMinutes } from 'date-fns';

export const getToken = (): string | undefined => {
    let dadosLocalStorage: string | null = localStorage.getItem('@SisPortaria:token');
    let localUserData: any = null;
    if (typeof dadosLocalStorage == 'string') {
        localUserData = JSON.parse(dadosLocalStorage);
        return localUserData.token
    } else {
        return undefined
    }
}

/**
 * Verifica se o token não esta expirado
 */
export const checkExpiredToken = (token: string | undefined): boolean => {
    if (token) {
        let decodedToken: any = jwt_decode(token);

        if (decodedToken != null) {
            // addMinutes(new Date(), 5).getTime() / 1000
            // if (decodedToken.exp < new Date().getTime() / 1000) {
            if (decodedToken.exp < addMinutes(new Date(), 3).getTime() / 1000) {
                return true;
            }
        }
    } else if (token === undefined) {
        return true
    }
    return false;
};

export const removeToken = (): void => {
    let dadosLocalStorage: string | null = localStorage.getItem('@SisPortaria:token');
    let localUserData: any = null;
    if (typeof dadosLocalStorage == 'string') {
        localUserData = JSON.parse(dadosLocalStorage);
    }

    if (localUserData) {
        localStorage.removeItem('@SisPortaria:token');
    }
}
