import { useCallback, useRef, useState } from 'react';
import { Typography, Button, Form, Row, Col, Divider, notification } from 'antd';
import Icon from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { Loading } from '../../../components/Loading';
import { unMask } from '../../../utils/MascaraDeCampos';
import { SairDoSistema } from '../../../services/LStorage/SairSistema';
import { EmitirErro } from '../../../services/EmitirErro';
import { api } from '../../../services/apiAxios';
import { urlEmpresas } from '../../../services/urls';
import { getToken } from '../../../services/LStorage/token';
import { FaBuilding } from 'react-icons/fa';
import { FormsInputEmpresa } from '../../../components/FormsInputsEmpresa';

const IconBuilding = (props: any) => (<Icon {...props} component={FaBuilding} ></Icon>);

const { Text, Title, Link } = Typography;

interface IntefReqModulos {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "nome": string,
    "descricao": string
}

type interfOpcaoScreen = 'tabs' | 'addEmpresa';

interface interPros {
    alterOpcaoScreen: (opcao: interfOpcaoScreen) => void;
}

export const AdicionarDadosEmpresa = ({ alterOpcaoScreen }: interPros) => {

    const navigate = useNavigate();
    const refForm = useRef<any>(null);
    const [loading, setLoading] = useState(false);

    const submitBotaoSalvar = useCallback(() => {


        const salvarDadosCriar = async (obj: any) => {
            try {
                setLoading(true);
                let resultUser = await api.post(urlEmpresas, obj,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram criados com sucesso!',
                });

                setLoading(false);

                alterOpcaoScreen('tabs')

            } catch (error) {
                setLoading(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        let todosModulos: Array<IntefReqModulos> = refForm.current.getFieldValue('todosModulos')
        let modulosSelecionados = refForm.current.getFieldValue('modulos')

        let objSalvarModulos: Array<{
            id: string,
            ativo: boolean
        }> = []

        todosModulos.forEach(element => {

            if (modulosSelecionados.includes(element.id)) {
                objSalvarModulos.push({
                    id: element.id,
                    ativo: true,
                })
            } else {
                objSalvarModulos.push({
                    id: element.id,
                    ativo: false,
                })
            }
        });

        refForm.current?.validateFields()
            .then((values: any) => {

                values.telefone = unMask(values.telefone);
                values.cnpj = unMask(values.cnpj);
                values.modulos = objSalvarModulos;

                salvarDadosCriar(values);

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, []);

    return (
        <>
            <Loading
                loading={loading}
                paddingLayout={false}
            >
                <Row>
                    <Col span={24}>
                        <Title level={4}>
                            <IconBuilding style={{ fontSize: 25, marginRight: 10 }} />
                            {`${'Adicionar'} Empresa`}
                        </Title>
                        <div
                            style={{
                                display: 'flex'
                            }}
                        >
                            <Link
                                onClick={() => [
                                    alterOpcaoScreen('tabs')
                                ]}
                                style={{
                                    marginRight: 5
                                }}
                            >
                                <Text
                                    type='secondary' underline
                                >Selecionar empresa</Text>
                            </Link>

                            <Text
                                style={{
                                    marginRight: 5
                                }} type='secondary'
                            >/</Text>

                            <Text style={{
                                marginRight: 5
                            }}>
                                {
                                    `${'Adicionar'}`
                                }
                            </Text>
                        </div>
                        <Divider />
                    </Col>
                </Row>

                <Form
                    ref={refForm}
                    name="adicionarUsuario"
                    layout="vertical"
                    // onFieldsChange={onFieldsChange}
                    initialValues={{
                        remember: true,
                    }}
                    // onFinish={onFinish}
                    // onFinishFailed={() => { }}
                    autoComplete="off"
                >
                    <FormsInputEmpresa
                        refForm={refForm}
                        readonly={false}
                    />
                    <Col span={24}>
                        <div className="botaoSalvarStep" style={{
                            flexDirection: 'row',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            // background: 'red'
                        }}>
                            <Button
                                // type="primary"
                                style={{
                                    marginTop: 20,
                                    marginRight: 5
                                }}
                                onClick={() => {
                                    alterOpcaoScreen('tabs')
                                }}
                            >
                                {"Cancelar"}
                            </Button>

                            <Button
                                key="submit"
                                type="primary"
                                style={{
                                    marginTop: 20,
                                    marginRight: 5
                                }}
                                onClick={() => {
                                    submitBotaoSalvar()
                                }}
                            >
                                {"Salvar"}
                            </Button>
                        </div>
                    </Col>
                </Form>
            </Loading>
        </>
    );
}
