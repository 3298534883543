import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Table, Typography, notification, Button } from 'antd';
import moment from 'moment';

import { convertDataUSParaBrasil } from '../../../../services/Conversores';
import { useNavigate } from 'react-router-dom';
import locale from 'antd/es/locale/pt_BR';
import { EmitirErro } from '../../../../services/EmitirErro';
import { SairDoSistema } from '../../../../services/LStorage/SairSistema';
import { api } from '../../../../services/apiAxios';
import { getToken } from '../../../../services/LStorage/token';
import { useGetColumnSearchProps } from '../../../../hooks/FilterTable/string';
import { useGetColumnSearchPropsData } from '../../../../hooks/FilterTable/date';
import { GlobalContext } from '../../../../contexts/globalContext';

import { UsuariosListagem } from './components/Listagem';
import AddOrUpdateUsuariosEmpresa from './components/AddOrUpdate';


const { Text } = Typography;

// const largura = window.innerWidth;
// const altura = window.innerHeight;

type interfOpcaoScreen = 'listar' | 'addUpdate';
interface IntefEmpresas {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "ativo": boolean,
    "nome": string,
    "cnpj": string,
    "telefone": string,
    "bairro": string | null,
    "cep": string | null,
    "cidade": string | null,
    "logradouro": string | null,
    "numero": string | null,
    "uf": string | null,
    "deletedAt": string | null
}
interface IntefDadosTable {
    cor: string,
    "createdAt": string,
    descricao: string,
    empresaId: string,
    "id": string,
    noPatio: boolean
    placa: string,
    tipo: string,
    updatedAt: string
}

interface interPros {
    empresaSelecionado?: IntefEmpresas,
}

export const ListUsuarios = ({ empresaSelecionado }: interPros) => {

    // const refIdUsuarioSelecionado = React.useRef<string | null | undefined>(null);

    const [opcaoScreen, setOpcaoScreen] = useState<interfOpcaoScreen>('listar');
    const [idUsuarioSelecionado, setIdUsuarioSelecionado] = useState<string | null | undefined>(null);

    const alterOpcaoScreen = useCallback((opcao: interfOpcaoScreen, id?: string) => {
        setIdUsuarioSelecionado(id)
        setOpcaoScreen(opcao)
    }, []);

    return (
        <>
            {
                opcaoScreen === 'listar' ?
                    <UsuariosListagem
                        alterOpcaoScreen={alterOpcaoScreen}
                        empresaSelecionado={empresaSelecionado}
                    />
                    :
                    <AddOrUpdateUsuariosEmpresa
                        alterOpcaoScreen={alterOpcaoScreen}
                        empresaSelecionado={empresaSelecionado}
                        id={idUsuarioSelecionado}
                    />
            }

        </>
    )
}
