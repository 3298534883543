export const urlHttpGov = process.env.REACT_APP_API_URL || 'http://localhost:3333'; //local


export const urlFiles = urlHttpGov + '/files'

//SESSAO
export const urlUsuarioSession = urlHttpGov + '/usuarios/session/painel'
export const urlUsuariosSessionRefreshToken = urlHttpGov + '/usuarios/session/refresh-token'

//usuario regra
export const urlUsuariosPapel = urlHttpGov + '/usuarios/papel'
export const urlUsuariosPermissao = urlHttpGov + '/usuarios/permissao'//nao usa
export const urlUsuariosUsuarioPerfil = urlHttpGov + '/usuarios/usuario/perfil'

export const urlUsuariosUsuario = urlHttpGov + '/usuarios/usuario'
// /email para consultar
export const urlUsuariosUsuarioEmail = urlHttpGov + '/usuarios/usuario/email'
export const urlUsuariosUsuarioAtivos = urlHttpGov + '/usuarios/usuario/ativos'
export const urlUsuariosUsuarioEmpresas = urlHttpGov + '/usuarios/usuario/empresas'

export const urlUsuariosUsuarioPrimeiroAcesso = urlHttpGov + '/usuarios/usuario/primeiro_acesso'
export const urlUsuariosUsuarioRecuperacao = urlHttpGov + '/usuarios/usuario/recuperacao'
export const urlUsuariosUsuarioRecuperacaoPainel = urlHttpGov + '/usuarios/usuario/recuperacao/painel'
export const urlUsuariosUsuarioUpdate = urlHttpGov + '/usuarios/usuario/update'
export const urlUsuariosUsuarioGestor = urlHttpGov + '/usuarios/usuario/gestor'
export const urlEmpresasEmpresaUsuarioDesvincular = urlHttpGov + '/empresas/empresa/usuario/desvincular'

export const urlVeiculoProprio = urlHttpGov + '/veiculo-proprio'
export const urlVeiculoProprioEmpresa = urlHttpGov + '/veiculo-proprio/empresa'
export const urlVeiculoProprioTipo = urlHttpGov + '/veiculo-proprio/tipo'
export const urlVeiculosPropriosRelatorio = urlHttpGov + '/veiculo-proprio/relatorio'
export const urlVeiculoProprioRelatorioPlanilha = urlHttpGov + '/veiculo-proprio/relatorio/planilha'
export const urlVeiculoProprioRelatorioPDF = urlHttpGov + '/veiculo-proprio/relatorio/pdf'

export const urlVeiculoProprioMovimentoEdit = urlHttpGov + '/veiculo-proprio/movimento/edit'

export const urlVisitanteRelatorio = urlHttpGov + '/visitante/relatorio'
export const urlVeiculoTerceiroRelatorio = urlHttpGov + '/veiculo-terceiro/relatorio'
export const urlCarroForteRelatorio = urlHttpGov + '/carro-forte/relatorio'

export const urlEmpresas = urlHttpGov + '/empresas'

export const urlEmpresasModulo = urlHttpGov + '/empresas/modulo'
export const urlEmpresasModuloModulos = urlHttpGov + '/empresas/modulo/modulos'
export const urlEmpresasModulos = urlHttpGov + '/empresas/modulos'

export const urlEmpresasUsuario = urlHttpGov + '/empresas/usuario'
export const urlEmpresasUsuarioPainel = urlHttpGov + '/empresas/usuario/painel'
export const urlEmpresasUsuarioGestor = urlHttpGov + '/empresas/usuario/gestor'
export const urlEmpresasColaborador = urlHttpGov + '/empresas/colaborador'
export const urlEmpresasColaboradorEmpresaColaboradores = urlHttpGov + '/empresas/colaborador/empresa/colaboradores'
export const urlEmpresasRelatorioProprioVeiculo = urlHttpGov + '/empresas/relatorio/proprios/veiculo'//nao usa
export const urlCarroForteRelatorioPdf = urlHttpGov + '/carro-forte/relatorio/pdf'
export const urlCarroForteRelatorioXlsx = urlHttpGov + '/carro-forte/relatorio/xlsx'
export const urlVeiculoTerceiroReltorioPdf = urlHttpGov + '/veiculo-terceiro/relatorio/pdf'
export const urlVeiculoTerceiroReltorioXlsx = urlHttpGov + '/veiculo-terceiro/relatorio/xlsx'
export const urlVisitanteRelatorioPdf = urlHttpGov + '/visitante/relatorio/pdf'
export const urlVisitanteRelatorioXlsx = urlHttpGov + '/visitante/relatorio/xlsx'
export const urlEventoRelatorioPdf = urlHttpGov + '/evento/relatorio/pdf'
export const urlEventoRelatorioXlsx = urlHttpGov + '/evento/relatorio/xlsx'
export const urlAtaPDF = urlHttpGov + '/ata/pdf'

export const urlEvento = urlHttpGov + '/evento'
export const urlEventoEmpresa = urlHttpGov + '/evento/empresa'
export const urlEventoConvidado = urlHttpGov + '/evento/convidado'
export const urlEventoConvidadoEvento = urlHttpGov + '/evento/convidado/evento'
export const urlEventoRelaotrio = urlHttpGov + '/evento/relatorio'

export const urlEmpresasCargo = urlHttpGov + '/empresas/cargo'

export const urlAtaEmpresa = urlHttpGov + '/ata/empresa'
export const urlAta = urlHttpGov + '/ata'
export const urlFotos = urlHttpGov + '/fotos'
