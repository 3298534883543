import axios
    // , { AxiosError }
    from 'axios';
import { SairDoSistema } from '../LStorage/SairSistema';
import { getRefreshToken } from '../LStorage/UsuarioERefresh';
import { checkExpiredToken, getToken } from './../../services/LStorage/token';
import { urlUsuarioSession, urlUsuariosSessionRefreshToken } from './../../services/urls';

// let isRefreshing = false;
// let failedRequestsQueue = [];

export function setupAPIClient() {

    const api = axios.create({
        // baseURL: urlHttpGov,
        // headers: {
        //     Authorization: 'Bearer chineschineschines'
        // }
    });

    api.interceptors.request.use(async (config) => {

        if (
            config.url != urlUsuariosSessionRefreshToken &&
            config.url != urlUsuarioSession
        ) {
            if (getToken() != undefined && checkExpiredToken(getToken())) {

                try {

                    let objRenovarToken = {
                        refresh_token: getRefreshToken(),
                    }

                    let result = await axios.post(urlUsuariosSessionRefreshToken, objRenovarToken);

                    localStorage.setItem('@SisPortaria:token', JSON.stringify(
                        { token: result.data.token }
                    ));

                    if (result.data?.refresh_token &&
                        typeof result.data?.refresh_token !== 'undefined') {

                        localStorage.setItem('@SisPortaria:rftoken', JSON.stringify(
                            result.data
                        ));
                    }


                    if (config?.headers?.Authorization) {
                        config.headers = {
                            ...config.headers,
                            Authorization: 'Bearer ' + result.data.token,
                        }

                    }

                    return config;

                } catch (error) {
                    SairDoSistema();
                    throw new Error('USUáRIO NãO AUTORIZADO.');
                    // navigate("/");
                }
            } else {

                // Do something before request is sent
                return config;
            }
        }
        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });


    return api;
}
// api.interceptors.response.use(response => {
//     return response;
// }, (error: AxiosError) => {
//     if (error.response.status === 401) {
//         if (error.response.data?.code === 'token.expired') {
//             cookies = parseCookies(ctx);

//             const { 'nextauth.refreshToken': refreshToken } = cookies;
//             const originalConfig = error.config;

//             if (!isRefreshing) {
//                 isRefreshing = true;

//                 api.post('/refresh', {
//                     refreshToken,
//                 }).then(response => {
//                     const { token } = response.data;

//                     setCookie(ctx, 'nextauth.token', token, {
//                         maxAge: 60 * 60 * 24 * 30, // 30 days
//                         path: '/',
//                     });
//                     setCookie(ctx, 'nextauth.refreshToken', response.data.refreshToken, {
//                         maxAge: 60 * 60 * 24 * 30, // 30 days
//                         path: '/',
//                     });

//                     api.defaults.headers['Authorization'] = `Bearer ${token}`;

//                     failedRequestsQueue.forEach(request => request.onSuccess(token))
//                     failedRequestsQueue = []
//                 }).catch(err => {
//                     failedRequestsQueue.forEach(request => request.onFailure(err))
//                     failedRequestsQueue = []

//                     if (process.browser) {
//                         signOut();
//                     }
//                 }).finally(() => {
//                     isRefreshing = false
//                 });
//             }

//             return new Promise((resolve, reject) => {
//                 failedRequestsQueue.push({
//                     onSuccess: (token: string) => {
//                         originalConfig.headers['Authorization'] = `Bearer ${token}`

//                         resolve(api(originalConfig))
//                     },
//                     onFailure: (err: AxiosError) => {
//                         reject(err);
//                     },
//                 })
//             })
//         } else {
//             if (process.browser) {
//                 signOut();
//             } else {
//                 return Promise.reject(new AuthTokenError());
//             }
//         }
//     }

//     return Promise.reject(error);
// });
