import { ReactNode } from "react";
import { Spin } from "antd"

interface IntefProps {
    loading?: boolean;
    paddingLayout?: boolean;
    children?: any;
}
export const Loading = ({ loading = false, children, paddingLayout = true }: IntefProps) => {

    return (
        <>
            {children ?
                <Spin
                    style={{
                        margin: paddingLayout ? -24 : 0,
                        //     height: '100%',
                        //     width: '100%',
                        //     display: 'flex',
                        //     alignItems: 'center',
                        //     justifyContent: 'center',
                        //     position: 'fixed',
                        //     zIndex: 99,
                        //     background: 'rgba(0,0,0,0.15)'
                    }}
                    size="large"
                    spinning={loading}
                >
                    {children}
                </Spin>
                :
                loading &&
                <Spin
                    style={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'fixed',
                        zIndex: 99,
                        background: 'rgba(0,0,0,0.15)',
                        margin: paddingLayout ? -24 : 0,
                    }}
                    size="large"
                    spinning={true}
                />
            }
        </>
    )
}
