import React, { useCallback, useState } from 'react';

import { ColaboradoresAddOrUpdate } from './AddOrUpdate';
import { ListagemColaboradores } from './Listagem';



// const largura = window.innerWidth;
// const altura = window.innerHeight;
interface IntefEmpresas {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "ativo": boolean,
    "nome": string,
    "cnpj": string,
    "telefone": string,
    "bairro": string | null,
    "cep": string | null,
    "cidade": string | null,
    "logradouro": string | null,
    "numero": string | null,
    "uf": string | null,
    "deletedAt": string | null
}

interface interPros {
    empresaSelecionado?: IntefEmpresas,
}
type interfOpcaoScreen = 'listar' | 'addUpdate';

export const ListColaboradores = ({ empresaSelecionado }: interPros) => {


    const [opcaoScreen, setOpcaoScreen] = useState<interfOpcaoScreen>('listar');
    const [idUsuarioSelecionado, setIdUsuarioSelecionado] = useState<string | null | undefined>(null);

    const alterOpcaoScreen = useCallback((opcao: interfOpcaoScreen, id?: string) => {
        setIdUsuarioSelecionado(id)
        setOpcaoScreen(opcao)
    }, []);

    return (
        <>
            {
                opcaoScreen === 'listar' ?
                    <ListagemColaboradores
                        alterOpcaoScreen={alterOpcaoScreen}
                        empresaSelecionado={empresaSelecionado}
                    />
                    :
                    <ColaboradoresAddOrUpdate
                        alterOpcaoScreen={alterOpcaoScreen}
                        id={idUsuarioSelecionado}
                        idEmpresa={empresaSelecionado?.id}
                    />
            }

        </>
    )
}
