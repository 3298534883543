import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Row, Col, Typography, notification, Image, Divider, Tooltip, Button } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { EyeOutlined, FileTextOutlined, FilePdfOutlined } from '@ant-design/icons';
import { colSelect } from './GridStyle';

import { VerificaRota } from './../../../../services/VerificaSePodeAcessar';
import LayoutDashboard from './../../../../layout/LayoutAdmin';
import { urlAta, urlAtaPDF, urlFiles, urlFotos } from './../../../../services/urls';
import { api } from './../../../../services/apiAxios';
import { EmitirErro } from '../../../../services/EmitirErro';
import { SairDoSistema } from '../../../../services/LStorage/SairSistema';
import { getToken } from './../../../../services/LStorage/token';

// import './style.css';

import {
    convertDataUSParaBrasil, convertDataUSParaBrasilEHora
} from '../../../../services/Conversores';
import { GlobalContext } from '../../../../contexts/globalContext';
import { Loading } from '../../../../components/Loading';

const { Text, Title, Link } = Typography;


// const largura = window.innerWidth;
// const altura = window.innerHeight;

interface InterfDadosAta {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "texto": string,
    "status": boolean,
    "fotos": Array<
        {
            "id": string,
            "createdAt": string,
            "updatedAt": string,
            "caminho": string,
            "ataId": string
        }
    >,
    "empresaId": string,
    "usuarioId": string,
    "usuario": {
        "id": string,
        "createdAt": string,
        "updatedAt": string,
        "ativo": boolean,
        "primeiro_acesso": boolean,
        "nome": string,
        "email": string,
        "cpf": string,
        "telefone": string,
        "deletedAt": string | null
    }
}

const RelatorioAtaView: React.FC = () => {

    const navigate = useNavigate();
    let { id } = useParams();
    const { theme } = useContext(GlobalContext);

    const [loadingScreen, setLoadingScreen] = useState(false);
    const [dadosAta, setDadosAta] = useState<InterfDadosAta>();

    const buscarDadosDaAtaPorEmpresa = useCallback((id: string) => {

        const buscaCiaddes = async () => {
            try {
                setLoadingScreen(true);

                let result = await api.get(urlAta + '/' + id,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDadosAta(result.data)

                setLoadingScreen(false);

            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingScreen(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);

    useEffect(() => {

        let arrayUrl = window.location.pathname.split("/");
        VerificaRota(arrayUrl, navigate);

        if (id) {
            buscarDadosDaAtaPorEmpresa(id);
        }

    }, [id]);

    const handleCLickGeneratePDF = useCallback((id: any): void => {

        const gerarPDF = async () => {

            try {
                setLoadingScreen(true)

                let stringFil = `/${id}`

                const result = await api.get(urlAtaPDF + stringFil,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                // const url = window.URL.createObjectURL(new Blob([urlFiles + '/' + result.data.filename]));
                const url = urlFiles + '/' + result.data.filename
                const link = document.createElement('a');
                link.href = url;

                link.setAttribute('download', result.data.filename);
                link.setAttribute('target', "_blank");
                document.body.appendChild(link);
                link.click();

                setLoadingScreen(false)
            } catch (error) {
                setLoadingScreen(false)
            }


        }

        gerarPDF();

    }, []);


    return (
        <LayoutDashboard>
            <Loading loading={loadingScreen} />
            <Row>
                <Col span={24}>
                    <Title level={4}>
                        <FileTextOutlined style={{ fontSize: 25, marginRight: 10 }} />
                        {`Visualizar Ata`}
                    </Title>
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <Link
                            onClick={() => {
                                navigate('/relatorios/ata?empresaId=' + dadosAta?.empresaId)
                            }}
                            style={{
                                marginRight: 5
                            }}
                        >
                            <Text
                                type='secondary' underline
                            >Listagem</Text>
                        </Link>

                        <Text
                            style={{
                                marginRight: 5
                            }} type='secondary'
                        >/</Text>

                        <Text style={{
                            marginRight: 5
                        }}>
                            {
                                `Visualizar Ata`
                            }
                        </Text>
                    </div>
                    <Divider />
                </Col>
            </Row>
            {
                dadosAta &&

                <Row>
                    <Col {...colSelect} style={{ padding: 5 }}>
                        <div
                        // style={{ marginBottom: 5 }}
                        >
                            <Text style={{ padding: 1 }} >
                                {`Usuário: ${dadosAta.usuario.nome ? dadosAta.usuario.nome : ''}`}
                            </Text>
                        </div>

                    </Col>
                    <Col {...colSelect} style={{ padding: 5 }}>
                        <div
                        // style={{ marginBottom: 5 }}
                        >
                            <Text style={{ padding: 1 }} >
                                {`Status: ${dadosAta.status ? 'Ata aberta' : 'Ata fechada'}`}
                            </Text>
                        </div>

                    </Col>
                    <Col {...colSelect} style={{ padding: 5 }}>
                        <div
                        // style={{ marginBottom: 5 }}
                        >
                            <Text style={{ padding: 1 }} >
                                {`Criado em: ${convertDataUSParaBrasilEHora(dadosAta.createdAt)}`}
                            </Text>
                        </div>

                    </Col>
                    <Col {...colSelect} style={{ padding: 5 }}>
                        <div
                        // style={{ marginBottom: 5 }}
                        >
                            <Text style={{ padding: 1 }} >
                                {`Finalizado em: ${!dadosAta.status ?
                                    convertDataUSParaBrasilEHora(dadosAta.updatedAt) : ''}`}
                            </Text>
                        </div>

                    </Col>
                    <Col span={24} style={{ padding: 5 }}>
                        <div
                        // style={{ marginBottom: 5 }}
                        >
                            <Text style={{ padding: 1 }} >
                                {`Observação: ${dadosAta.texto}`}
                            </Text>
                        </div>

                    </Col>
                    <Col span={24} style={{
                        paddingLeft: 5,
                        paddingRight: 5,
                        paddingBottom: 5,
                        marginBottom: -12,
                        marginTop: 7
                     }}>
                        <Tooltip placement="top" color='red' title={'PDF'}>
                            <Button
                                key="submit"
                                type="primary"
                                danger

                                onClick={() => {
                                    handleCLickGeneratePDF(dadosAta.id)
                                }}
                            >
                                <FilePdfOutlined style={{ fontSize: 22 }} />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            }
            {
                dadosAta &&
                <>
                    <Divider
                        style={{
                            // marginTop: 30,
                            // marginBottom: 30,
                            backgroundColor: '#2D9CDB'
                        }}
                    />
                    <Row>
                        <Image.PreviewGroup>
                            {
                                dadosAta.fotos.map((foto) => {
                                    return (
                                        <Image
                                            key={foto.id}
                                            width={200}
                                            preview={{
                                                mask: (
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                        <EyeOutlined style={{
                                                            marginRight: 5
                                                        }} />
                                                        {`Visualizar`}
                                                    </div>
                                                )
                                            }}
                                            style={{
                                                padding: 10
                                            }}
                                            src={urlFotos + '/' + foto.caminho}
                                        />
                                    )
                                })
                            }
                        </Image.PreviewGroup>
                    </Row>
                </>
            }

        </LayoutDashboard>
    );
}

export default RelatorioAtaView;
