import { useEffect } from 'react';
import {
    Col, Collapse, List, Row, Typography, Divider
} from 'antd';
import Icon from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import {
    cardLayout,
    colCollapseContrato,
} from './GridStyle';

import { FaCogs } from 'react-icons/fa';
import LayoutDashboard from '../../layout/LayoutAdmin';
import { validacaoPermissao, VerificaRota } from '../../services/VerificaSePodeAcessar';

const IconCogs = (props: any) => <Icon {...props} component={FaCogs} ></Icon>

const { Panel } = Collapse;
const { Title } = Typography;

export const Configuracoes = () => {

    const navigate = useNavigate();

    useEffect(() => {
        let arrayUrl = window.location.pathname.split("/");
        VerificaRota(
            arrayUrl,
            navigate,
            true,
            validacaoPermissao(['admin'])
        );
    }, [])

    return (
        <>
            <LayoutDashboard>
                <Row>
                    <Col {...cardLayout}>
                        <Title level={3}><IconCogs style={{ fontSize: 35 }} /> Configurações do Sistema</Title>
                        <Divider />
                    </Col>
                </Row>
                <Row>
                    <Col {...colCollapseContrato}>
                        <Collapse defaultActiveKey={['1tipos']} className="divCollapses" accordion>
                            <Panel header="Tipos" key="1tipos" showArrow>
                                <List>
                                    <List.Item
                                        key={'1ccargos'}
                                        actions={[<a key="list-loadmore-edit"
                                            onClick={
                                                () => { navigate("/configuracoes/cargo") }
                                            }>Listar</a>,
                                            // <a onClick={onClickCadastrarTipoDeAditivo} key="list-loadmore-more">Cadastrar</a>
                                        ]}
                                    >
                                        <div className="divNameCongSistemaList">Cargos</div>
                                    </List.Item>
                                    <List.Item
                                        key={'1veiculo'}
                                        actions={[<a key="list-loadmore-edit"
                                            onClick={
                                                () => { navigate("/configuracoes/veiculo") }
                                            }>Listar</a>,
                                            // <a onClick={onClickCadastrarTipoDeAditivo} key="list-loadmore-more">Cadastrar</a>
                                        ]}
                                    >
                                        <div className="divNameCongSistemaList">Veículos</div>
                                    </List.Item>
                                </List>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
            </LayoutDashboard>
        </>
    );
}
