import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { VerificaRota } from './../../services/VerificaSePodeAcessar';

import './style.css';
import LayoutHeader from '../../layout/LayoutHeader';
import { AdicionarDadosEmpresa } from './AdicionarEmpresa';
import { SelecionarEmpresaSelect } from './SelecionarEmpresa';

// const largura = window.innerWidth;
// const altura = window.innerHeight;

type interfOpcaoScreen = 'tabs' | 'addEmpresa';

const SelecionarEmpresa: React.FC = () => {

    const navigate = useNavigate();

    const [opcaoScreen, setOpcaoScreen] = useState<interfOpcaoScreen>('tabs');

    useEffect(() => {

        let arrayUrl = window.location.pathname.split("/");
        VerificaRota(arrayUrl, navigate);

    }, []);


    const alterOpcaoScreen = useCallback((opcao: interfOpcaoScreen) => {
        setOpcaoScreen(opcao)
    }, []);

    return (
        <LayoutHeader>
            {
                opcaoScreen === 'tabs' ?
                    <SelecionarEmpresaSelect
                        alterOpcaoScreen={alterOpcaoScreen}
                    />
                    :
                    <AdicionarDadosEmpresa
                        alterOpcaoScreen={alterOpcaoScreen}
                    />
            }
        </LayoutHeader>
    );
}

export default SelecionarEmpresa;
