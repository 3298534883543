import React, { useCallback, useEffect, useState, useRef, useContext } from 'react';
import { Row, Col, Select, Tabs, Typography, notification, Spin, Button, Form, Table, Divider, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import locale from 'antd/es/locale/pt_BR';
import { DateTimePicker, MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import ptLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns';
import { colSelect, colInputs } from './GridStyle';

import { VerificaRota } from './../../../services/VerificaSePodeAcessar';
import LayoutDashboard from './../../../layout/LayoutAdmin';
import { urlEmpresasColaboradorEmpresaColaboradores, urlVeiculosPropriosRelatorio, urlVeiculoProprioTipo, urlEmpresasUsuario, urlEmpresasUsuarioGestor, urlEmpresasUsuarioPainel, urlVeiculoProprioEmpresa, urlUsuariosUsuarioEmpresas, urlVeiculoProprioRelatorioPlanilha, urlFiles, urlVeiculoProprioRelatorioPDF } from './../../../services/urls';
import { api } from './../../../services/apiAxios';
import { EmitirErro } from '../../../services/EmitirErro';
import { SairDoSistema } from '../../../services/LStorage/SairSistema';
import { getToken } from './../../../services/LStorage/token';
import Icon, { FilePdfOutlined, FileTextOutlined } from '@ant-design/icons';
import {
    FaTruck
} from 'react-icons/fa';

import './style.css';

import {
    removeAcento
} from './../../../utils/RemoveAcentos';
import { getIdUsuario } from '../../../services/LStorage/UsuarioERefresh';
import {
    convertDataBrHoraParaUSHora,
    convertDataBrParaUS,
    convertDataUSParaBrasilEHora,
    poeValorComPontosEmMil
} from '../../../services/Conversores';
import { GlobalContext } from '../../../contexts/globalContext';
import { getEmpresaSelecionada } from '../../../services/LStorage/EmpresaSelecionada';
import { Loading } from '../../../components/Loading';

const { TabPane } = Tabs;
const { Text, Title } = Typography;


// const largura = window.innerWidth;
// const altura = window.innerHeight;

const IconFaTruck = (props: any) => (<Icon {...props} component={FaTruck} ></Icon>);

interface InterfRelatorioVeiculosProprios {
    registros: Array<{
        "id": string,
        "createdAt": string,
        "updatedAt": string,
        "km_saida": number,
        "km_chegada": number,
        "data_hora_saida": string,
        "data_hora_chegada": string,
        "destino"?: string,
        "veiculo": {
            "id": string,
            "descricao": string,
            "placa": string,
            "tipo": string,
            "cor": string
        },
        "usuario": {
            "id": string,
            "nome": string
        },
        "veiculoId": string,
        "usuarioId": string
    }>,
    total: number
}

interface IntefListEmpresas {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "ativo": boolean,
    "nome": string,
    "cnpj": string,
    "telefone": string,
    "bairro": string | null,
    "cep": string | null,
    "cidade": string | null,
    "logradouro": string | null,
    "numero": string | null,
    "uf": string | null,
    "deletedAt": string | null
}

interface IntefListVeiculo {
    cor: string,
    "createdAt": string,
    descricao: string,
    empresaId: string,
    "id": string,
    noPatio: boolean
    placa: string,
    tipo: string,
    updatedAt: string
}

interface IntefListColaborador {
    cargo: {
        cargo: string | null
        createdAt: string | null
        id: string | null
        updatedAt: string | null
    } | null,
    cargoId: string | null
    cpf: string | null
    createdAt: string | null
    deletedAt: string | null
    empresaId: string | null
    id: string | null
    nome: string | null
    telefone: string | null
    updatedAt: string | null
}

interface IntefListUsuario {
    ativo: boolean
    cpf: string | null
    email: string | null
    id: string
    nome: string | null
    telefone: string | null
    updatedAt: string | null
}

interface IntefListTipoVeiculo {
    id: string
    tipo: string | null
}

const VeiculosProprios: React.FC = () => {

    const navigate = useNavigate();
    const { theme } = useContext(GlobalContext);

    const refNumeroPaginate = useRef<any>(`1`);
    const refForm = useRef<any>(null);
    const refContagemDoFiltro = React.useRef<Array<any>>([]);
    const refDataListEmpresas = useRef<Array<IntefListEmpresas>>([]);
    const refEmpresaSelecionada = useRef<IntefListEmpresas | null>(null);

    const [dataListEmpresas, setDataListEmpresas] = useState<Array<IntefListEmpresas>>([]);
    const [loadingListEmpresas, setLoadingListEmpresas] = useState(false);
    const [loadingScreen, setLoadingScreen] = useState(false);
    const [empresaSelecionado, setEmpresaSelecionado] = useState<IntefListEmpresas>();

    const refDadosTabelaSelect = React.useRef<InterfRelatorioVeiculosProprios>();
    const [dadosTabelaState, setDadosTabelaState] = useState<InterfRelatorioVeiculosProprios>();

    const [abrirModalEmpresas, setAbrirModalEmpresas] = useState(false);
    const [idModalEmpresa, setIdModalEmpresa] = useState<string>('criar');

    const [dateHourSaidaInicio, setDateHourSaidaInicio] = useState<any>(null);
    const [dateHourChegadaInicio, setDateHourChegadaInicio] = useState<any>(null);
    // const [dateHourSaidaFim, setDateHourSaidaFim] = useState<any>(null);
    // const [dateHourChegadaFim, setDateHourChegadaFim] = useState<any>(null);

    const [dataListVeiculo, setDataListVeiculo] = useState<Array<IntefListVeiculo>>([]);
    const [loadingListVeiculo, setLoadingListVeiculo] = useState(false);

    const [dataListColaborador, setDataListColaborador] = useState<Array<IntefListColaborador>>([]);
    const [loadingListColaborador, setLoadingListColaborador] = useState(false);

    const [dataListUsuario, setDataListUsuario] = useState<Array<IntefListUsuario>>([]);
    const [loadingListUsuario, setLoadingListUsuario] = useState(false);

    const [dataListTipoVeiculo, setDataListTipoVeiculo] = useState<Array<IntefListTipoVeiculo>>([]);
    const [loadingListTipoVeiculo, setLoadingListTipoVeiculo] = useState(false);

    const selecEmpresaPorId = useCallback((id: string): IntefListEmpresas | null => {
        let obj = null;
        refDataListEmpresas.current.forEach(val => {
            if (val.id === id) {
                obj = val
            }
        });
        return obj;

    }, []);

    //setando dados das cidades no selected
    const buscaCidades = useCallback(() => {


        const buscaCiaddes = async () => {
            try {
                setLoadingListEmpresas(true);
                let idUsuario = getIdUsuario();
                let resultCidade = await api.get(urlEmpresasUsuarioGestor + '/' + idUsuario,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                refDataListEmpresas.current = resultCidade.data
                setDataListEmpresas(resultCidade.data);

                if (refEmpresaSelecionada.current) {
                    const objSelecionado = selecEmpresaPorId(refEmpresaSelecionada.current.id)

                    if (objSelecionado) setEmpresaSelecionado(objSelecionado);

                }

                setLoadingListEmpresas(false);

            } catch (error) {
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);

    const buscaVeiculosPorIdEmpresa = useCallback((id: any) => {

        const funcBusca = async () => {
            try {
                setLoadingListVeiculo(true);
                let result = await api.get(urlVeiculoProprioEmpresa + '/' + id,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDataListVeiculo(result.data);

                setLoadingListVeiculo(false);

            } catch (error) {
                setLoadingListVeiculo(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'usuarios_dkwdfaiweyi')
                }


            }
        }

        funcBusca();
    }, []);

    const buscaColaboradorPorIdEmpresa = useCallback((id: any) => {

        const funcBusca = async () => {
            try {
                setLoadingListColaborador(true);
                let result = await api.get(urlEmpresasColaboradorEmpresaColaboradores + '/' + id,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDataListColaborador(result.data);

                setLoadingListColaborador(false);

            } catch (error) {
                setLoadingListColaborador(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'usuarios_dkwdfaiweyi')
                }


            }
        }

        funcBusca();
    }, []);

    const buscaUsuarioPorIdEmpresa = useCallback((id: any) => {

        const funcBusca = async () => {
            try {
                setLoadingListUsuario(true);
                let result = await api.get(urlUsuariosUsuarioEmpresas + '/' + id,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDataListUsuario(result.data);

                setLoadingListUsuario(false);

            } catch (error) {
                setLoadingListUsuario(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'usuarios_dkwdfaiweyi')
                }


            }
        }

        funcBusca();
    }, []);

    const buscaTipoVeiculo = useCallback(() => {

        const funcBusca = async () => {
            try {
                setLoadingListTipoVeiculo(true);
                let result = await api.get(urlVeiculoProprioTipo,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDataListTipoVeiculo(result.data);

                setLoadingListTipoVeiculo(false);

            } catch (error) {
                setLoadingListTipoVeiculo(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'usuarios_dkwdfaiweyi')
                }


            }
        }

        funcBusca();
    }, []);

    useEffect(() => {

        let arrayUrl = window.location.pathname.split("/");
        VerificaRota(arrayUrl, navigate);

        // buscaCidades();
        let lsEmpresaSelecionada = getEmpresaSelecionada();

        if (lsEmpresaSelecionada) {
            setEmpresaSelecionado(lsEmpresaSelecionada);
            refEmpresaSelecionada.current = lsEmpresaSelecionada

            buscaVeiculosPorIdEmpresa(lsEmpresaSelecionada.id)
            buscaColaboradorPorIdEmpresa(lsEmpresaSelecionada.id)
            buscaUsuarioPorIdEmpresa(lsEmpresaSelecionada.id)
            buscaTipoVeiculo()
        }


    }, []);

    // const onChangeSelectEmpresas = useCallback((value: any, obj: any): void => {

    //     const objSelecionado = selecEmpresaPorId(value)

    //     if (objSelecionado) setEmpresaSelecionado(objSelecionado);
    //     refEmpresaSelecionada.current = objSelecionado

    //     //busca de filtros pela empresa selecionada
    //     buscaVeiculosPorIdEmpresa(value)
    //     buscaColaboradorPorIdEmpresa(value)
    //     buscaUsuarioPorIdEmpresa(value)
    //     buscaTipoVeiculo()

    // }, []);

    const submitBuscarDadosFiltrados = useCallback(() => {

        const buscarDados = async (obj: any) => {
            try {
                // setLoading(true);
                let hrsaidaIni: any = ''
                // let hrsaidaFim: any = ''
                // let hrchegadaFim: any = ''
                let hrchegadaIni: any = ''
                let primeiro: boolean = false

                let stringFil = `/${empresaSelecionado?.id}`

                if (dateHourSaidaInicio) {
                    primeiro = true
                    hrsaidaIni = `?data_hora_saida=${convertDataBrParaUS(dateHourSaidaInicio)}`
                    stringFil = stringFil + hrsaidaIni
                }
                if (dateHourChegadaInicio) {
                    if (!primeiro) {
                        primeiro = true
                        hrchegadaIni = `?data_hora_chegada=${convertDataBrParaUS(dateHourChegadaInicio)}`
                        stringFil = stringFil + hrchegadaIni
                    } else {
                        hrchegadaIni = `&data_hora_chegada=${convertDataBrParaUS(dateHourChegadaInicio)}`
                        stringFil = stringFil + hrchegadaIni
                    }
                }
                // if (dateHourSaidaFim) {
                //     if (!primeiro) {
                //         primeiro = true
                //         hrchegadaFim = `?data_hora_saida_fim=${convertDataBrHoraParaUSHora(dateHourSaidaFim)}`
                //         stringFil = stringFil + hrchegadaFim
                //     } else {
                //         hrchegadaFim = `&data_hora_saida_fim=${convertDataBrHoraParaUSHora(dateHourSaidaFim)}`
                //         stringFil = stringFil + hrchegadaFim
                //     }
                // }
                // if (dateHourChegadaFim) {
                //     if (!primeiro) {
                //         primeiro = true
                //         hrsaidaFim = `?data_hora_chegada_fim=${convertDataBrHoraParaUSHora(dateHourChegadaFim)}`
                //         stringFil = stringFil + hrsaidaFim
                //     } else {
                //         hrsaidaFim = `&data_hora_chegada_fim=${convertDataBrHoraParaUSHora(dateHourChegadaFim)}`
                //         stringFil = stringFil + hrsaidaFim
                //     }
                // }

                let veiculo = refForm.current.getFieldValue('veiculoId');
                let colaborador = refForm.current.getFieldValue('colaborador_id');
                let tipoVeiculo = refForm.current.getFieldValue('tipo_id');
                let usuario = refForm.current.getFieldValue('usuario_id');

                if (veiculo) {
                    if (!primeiro) {
                        primeiro = true
                        stringFil = stringFil + `?veiculoId=${veiculo}`
                    } else {
                        stringFil = stringFil + `&veiculoId=${veiculo}`
                    }
                }
                if (colaborador) {
                    if (!primeiro) {
                        primeiro = true
                        stringFil = stringFil + `?colaborador_id=${colaborador}`
                    } else {
                        stringFil = stringFil + `&colaborador_id=${colaborador}`
                    }
                }
                if (tipoVeiculo) {
                    if (!primeiro) {
                        primeiro = true
                        stringFil = stringFil + `?tipo_id=${tipoVeiculo}`
                    } else {
                        stringFil = stringFil + `&tipo_id=${tipoVeiculo}`
                    }
                }
                if (usuario) {
                    if (!primeiro) {
                        primeiro = true
                        stringFil = stringFil + `?usuario_id=${usuario}`
                    } else {
                        stringFil = stringFil + `&usuario_id=${usuario}`
                    }
                }

                // paginate
                // if (refNumeroPaginate.current) {
                //     if (!primeiro) {
                //         primeiro = true
                //         stringFil = stringFil + `?pagina=${refNumeroPaginate.current}`
                //     } else {
                //         stringFil = stringFil + `&pagina=${refNumeroPaginate.current}`
                //     }
                // } else {
                //     if (!primeiro) {
                //         primeiro = true
                //         stringFil = stringFil + `?pagina=1`
                //     } else {
                //         stringFil = stringFil + `&pagina=1`
                //     }
                // }

                // console.log(stringFil)
                // let stringFil = `/${empresaSelecionado?.id}?veiculoId=`


                const result = await api.get(urlVeiculosPropriosRelatorio + stringFil,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDadosTabelaState(result.data)

                if (result.data.length === 0) {
                    notification.error({
                        message: 'Atenção!',
                        description:
                            'Nenhum registro foi encontrado',
                    });
                }

                // notification.success({
                //     message: 'Sucesso',
                //     description:
                //         'Seus dados foram editados com sucesso!',
                // });

                // const objSalvar: any = {
                //     email: result.data.email,
                //     nome: result.data.nome,
                //     primeiro_acesso: result.data.primeiro_acesso,
                // }

                // localStorage.setItem('@SisPortaria:usuario', JSON.stringify(
                //     { usuario: objSalvar }
                // ));

                // setDataListCidades(resultCidade.data);

                // setLoading(false);

            } catch (error) {
                // setLoading(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'perfil_dkwdfaiweyi')
                }


            }
        }

        refForm.current?.validateFields()
            .then((values: any) => {

                buscarDados(values);

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, [empresaSelecionado, dateHourSaidaInicio, dateHourChegadaInicio]);

    const handleCLickGenerateXLSX = useCallback((): void => {

        const gerarXLSX = async () => {

            try {
                setLoadingScreen(true)

                let hrsaidaIni: any = ''
                let hrchegadaIni: any = ''
                let primeiro: boolean = false

                let stringFil = `/${empresaSelecionado?.id}`

                if (dateHourSaidaInicio) {
                    primeiro = true
                    hrsaidaIni = `?data_hora_saida=${convertDataBrParaUS(dateHourSaidaInicio)}`
                    stringFil = stringFil + hrsaidaIni
                }
                if (dateHourChegadaInicio) {
                    if (!primeiro) {
                        primeiro = true
                        hrchegadaIni = `?data_hora_chegada=${convertDataBrParaUS(dateHourChegadaInicio)}`
                        stringFil = stringFil + hrchegadaIni
                    } else {
                        hrchegadaIni = `&data_hora_chegada=${convertDataBrParaUS(dateHourChegadaInicio)}`
                        stringFil = stringFil + hrchegadaIni
                    }
                }

                let veiculo = refForm.current.getFieldValue('veiculoId');
                let colaborador = refForm.current.getFieldValue('colaborador_id');
                let tipoVeiculo = refForm.current.getFieldValue('tipo_id');
                let usuario = refForm.current.getFieldValue('usuario_id');

                if (veiculo) {
                    if (!primeiro) {
                        primeiro = true
                        stringFil = stringFil + `?veiculoId=${veiculo}`
                    } else {
                        stringFil = stringFil + `&veiculoId=${veiculo}`
                    }
                }
                if (colaborador) {
                    if (!primeiro) {
                        primeiro = true
                        stringFil = stringFil + `?colaborador_id=${colaborador}`
                    } else {
                        stringFil = stringFil + `&colaborador_id=${colaborador}`
                    }
                }
                if (tipoVeiculo) {
                    if (!primeiro) {
                        primeiro = true
                        stringFil = stringFil + `?tipo_id=${tipoVeiculo}`
                    } else {
                        stringFil = stringFil + `&tipo_id=${tipoVeiculo}`
                    }
                }
                if (usuario) {
                    if (!primeiro) {
                        primeiro = true
                        stringFil = stringFil + `?usuario_id=${usuario}`
                    } else {
                        stringFil = stringFil + `&usuario_id=${usuario}`
                    }
                }

                const result = await api.get(urlVeiculoProprioRelatorioPlanilha + stringFil,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                // const url = window.URL.createObjectURL(new Blob([urlFiles + '/' + result.data.filename]));
                const url = urlFiles + '/' + result.data.filename
                const link = document.createElement('a');
                link.href = url;

                link.setAttribute('download', result.data.filename);
                link.setAttribute('target', "_blank");
                document.body.appendChild(link);
                link.click();

                setLoadingScreen(false)
            } catch (error) {
                setLoadingScreen(false)
            }


        }

        gerarXLSX();

    }, [empresaSelecionado, dateHourSaidaInicio, dateHourChegadaInicio]);

    const handleCLickGeneratePDF = useCallback((): void => {

        const gerarXLSX = async () => {

            try {
                setLoadingScreen(true)

                let hrsaidaIni: any = ''
                let hrchegadaIni: any = ''
                let primeiro: boolean = false

                let stringFil = `/${empresaSelecionado?.id}`

                if (dateHourSaidaInicio) {
                    primeiro = true
                    hrsaidaIni = `?data_hora_saida=${convertDataBrParaUS(dateHourSaidaInicio)}`
                    stringFil = stringFil + hrsaidaIni
                }
                if (dateHourChegadaInicio) {
                    if (!primeiro) {
                        primeiro = true
                        hrchegadaIni = `?data_hora_chegada=${convertDataBrParaUS(dateHourChegadaInicio)}`
                        stringFil = stringFil + hrchegadaIni
                    } else {
                        hrchegadaIni = `&data_hora_chegada=${convertDataBrParaUS(dateHourChegadaInicio)}`
                        stringFil = stringFil + hrchegadaIni
                    }
                }

                let veiculo = refForm.current.getFieldValue('veiculoId');
                let colaborador = refForm.current.getFieldValue('colaborador_id');
                let tipoVeiculo = refForm.current.getFieldValue('tipo_id');
                let usuario = refForm.current.getFieldValue('usuario_id');

                if (veiculo) {
                    if (!primeiro) {
                        primeiro = true
                        stringFil = stringFil + `?veiculoId=${veiculo}`
                    } else {
                        stringFil = stringFil + `&veiculoId=${veiculo}`
                    }
                }
                if (colaborador) {
                    if (!primeiro) {
                        primeiro = true
                        stringFil = stringFil + `?colaborador_id=${colaborador}`
                    } else {
                        stringFil = stringFil + `&colaborador_id=${colaborador}`
                    }
                }
                if (tipoVeiculo) {
                    if (!primeiro) {
                        primeiro = true
                        stringFil = stringFil + `?tipo_id=${tipoVeiculo}`
                    } else {
                        stringFil = stringFil + `&tipo_id=${tipoVeiculo}`
                    }
                }
                if (usuario) {
                    if (!primeiro) {
                        primeiro = true
                        stringFil = stringFil + `?usuario_id=${usuario}`
                    } else {
                        stringFil = stringFil + `&usuario_id=${usuario}`
                    }
                }

                // paginate
                // if (refNumeroPaginate.current) {
                //     if (!primeiro) {
                //         primeiro = true
                //         stringFil = stringFil + `?pagina=${refNumeroPaginate.current}`
                //     } else {
                //         stringFil = stringFil + `&pagina=${refNumeroPaginate.current}`
                //     }
                // } else {
                //     if (!primeiro) {
                //         primeiro = true
                //         stringFil = stringFil + `?pagina=1`
                //     } else {
                //         stringFil = stringFil + `&pagina=1`
                //     }
                // }

                const result = await api.get(urlVeiculoProprioRelatorioPDF + stringFil,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                // const url = window.URL.createObjectURL(new Blob([urlFiles + '/' + result.data.filename]));
                const url = urlFiles + '/' + result.data.filename
                const link = document.createElement('a');
                link.href = url;

                link.setAttribute('download', result.data.filename);
                link.setAttribute('target', "_blank");
                document.body.appendChild(link);
                link.click();

                setLoadingScreen(false)
            } catch (error) {
                setLoadingScreen(false)
            }


        }

        gerarXLSX();

    }, [empresaSelecionado, dateHourSaidaInicio, dateHourChegadaInicio]);

    //abrir navegar em add contrato
    const handleDoubleClick = useCallback((record: any, rowIndex: number | undefined): void => {

        const buscaDeDados = async () => {


            // setLoadingTableModal(true)
            // let objSelecionado: any = {}
            // dadosTabelaState.forEach((dados): void => {
            //     if (dados.uuid == selectedRowKeys[0]) {
            //         objSelecionado = dados
            //     }
            // })

            try {

                localStorage.setItem('@SisPortaria:vpEdit', JSON.stringify(record));

                navigate('/relatorios/veiculos-proprios/editar');
            } catch (error) {

                let msgErro: any = (error as Error);
                notification.error({
                    message: 'Erro',
                    description:
                        msgErro?.response?.data?.message ? msgErro?.response?.data?.message : 'Entre em contato com o suporte!',
                });
            }


        }

        buscaDeDados();

    }, [dadosTabelaState]);

    const columns = [
        {
            title: 'Usuário chegada',
            dataIndex: 'usuarioChegada',
            // ...useGetColumnSearchProps('nome'),
            width: '10%',
            // sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            showSorterTooltip: false,
            render: (value: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: 8
                    }}
                >
                    <Text>
                        {
                            value?.nome ? value?.nome : ''
                        }
                    </Text>
                </div>
            ),
        },
        {
            title: 'Usuário saída',
            dataIndex: 'usuarioSaida',
            // ...useGetColumnSearchProps('nome'),
            width: '10%',
            // sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            showSorterTooltip: false,
            render: (value: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: 8
                    }}
                >
                    <Text>
                        {
                            value?.nome ? value?.nome : ''
                        }
                    </Text>
                </div>
            ),
        },
        {
            title: 'Destino',
            dataIndex: 'destino',
            // ...useGetColumnSearchProps('nome'),
            width: '10%',
            // sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            showSorterTooltip: false,
        },
        {
            title: 'Veículo',
            dataIndex: 'veiculo',
            // ...useGetColumnSearchProps('nome'),
            width: '10%',
            // sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            showSorterTooltip: false,
            render: (value: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: 8
                    }}
                >
                    <Text>
                        {
                            value?.descricao ? value?.descricao : ''
                        }
                    </Text>
                </div>
            ),
        },
        {
            title: 'Placa',
            dataIndex: 'veiculo',
            // ...useGetColumnSearchProps('nome'),
            width: '4%',
            // sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            showSorterTooltip: false,
            render: (value: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: 8
                    }}
                >
                    <Text>
                        {
                            value?.placa ? value?.placa : ''
                        }
                    </Text>
                </div>
            ),
        },
        {
            title: 'Km Saída',
            dataIndex: 'km_saida',
            // ...useGetColumnSearchProps('nome'),
            width: '4%',
            // sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            showSorterTooltip: false,
            render: (value: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: 8
                    }}
                >
                    <Text>
                        {
                            value ? poeValorComPontosEmMil(value) : '-'
                        }
                    </Text>
                </div>
            ),
        },
        {
            title: 'Km Chegada',
            dataIndex: 'km_chegada',
            // ...useGetColumnSearchProps('nome'),
            width: '4%',
            // sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            showSorterTooltip: false,
            render: (value: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: 8
                    }}
                >
                    <Text>
                        {
                            value ? poeValorComPontosEmMil(value) : '-'
                        }
                    </Text>
                </div>
            ),
        },
        {
            title: 'Km percorrido',
            dataIndex: 'km_chegada',
            // ...useGetColumnSearchPropsData('lastAccess'),
            width: '2%',
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),
            render: (data: any, record: any) => {


                let total: number = record.km_chegada ? record.km_chegada - record.km_saida : 0

                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: 8
                        }}
                    >
                        <Text>
                            {
                                !isNaN(total) ? poeValorComPontosEmMil(total) : '-'
                            }
                        </Text>
                    </div>
                )
            },
        },
        {
            title: 'Hora saída',
            dataIndex: 'data_hora_saida',
            // ...useGetColumnSearchPropsData('lastAccess'),
            width: '2%',
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),
            render: (data: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: 8
                    }}
                >
                    <Text>
                        {
                            data ? convertDataUSParaBrasilEHora(data) : '-'
                        }
                    </Text>
                </div>
            ),
        },
        {
            title: 'Hora chegada',
            dataIndex: 'data_hora_chegada',
            // ...useGetColumnSearchPropsData('lastAccess'),
            width: '2%',
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),
            render: (data: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: 8
                    }}
                >
                    <Text>
                        {
                            data ? convertDataUSParaBrasilEHora(data) : '-'
                        }
                    </Text>
                </div>
            ),
        },
        // {
        //     title: 'Criado em',
        //     dataIndex: 'createdAt',
        //     // ...useGetColumnSearchPropsData('createdAt'),
        //     width: '2%',
        //     sorter: (a: any, b: any) => {

        //         let aMonMen: any = null
        //         let bMonMen: any = null
        //         if (a.createdAt) {
        //             aMonMen = moment(a.createdAt).unix()
        //         } else {
        //             aMonMen = 0
        //         }
        //         if (b.createdAt) {
        //             bMonMen = moment(b.createdAt).unix()
        //         } else {
        //             bMonMen = 0
        //         }

        //         return aMonMen - bMonMen
        //     },
        //     showSorterTooltip: false,
        //     // sorter: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),
        //     render: (criado: any) => (
        //         <div
        //             style={{
        //                 display: 'flex',
        //                 alignItems: 'center',
        //                 padding: 8
        //             }}
        //         >
        //             <Text>
        //                 {
        //                     criado ? convertDataUSParaBrasil(criado) : '-'
        //                 }
        //             </Text>
        //         </div>
        //     ),
        // },
    ];

    return (
        <LayoutDashboard>
            <Loading
                loading={loadingScreen}
            />
            {/* <Row style={{
                display: 'flex',
                alignItems: 'flex-end'
            }}>
                <Col {...colSelect} style={{ marginBottom: 5, marginRight: 5 }}>
                    <div
                        style={{ marginBottom: 5 }}
                    >
                        <Text style={{ margin: 1 }} >{'Selecione uma empresa:'}</Text>
                    </div>
                    <Select
                        showSearch
                        placeholder="Digite o nome de uma empresa"
                        optionFilterProp="children"
                        // className="botaoListVendedorUser"
                        onChange={onChangeSelectEmpresas}
                        loading={loadingListEmpresas}
                        notFoundContent={loadingListEmpresas ? <Spin size="small" /> : null}
                        // disabled={!hasSelectedAdd}
                        // defaultValue='Usuários'
                        style={{
                            width: '100%',
                        }}
                        filterOption={(input: any, option: any) => {
                            let textDigit = removeAcento(input)
                            let listCidade = removeAcento(option?.children);
                            return listCidade.indexOf(textDigit) >= 0
                        }}
                    >
                        {
                            dataListEmpresas.map((item) => {
                                return (
                                    <Select.Option
                                        value={item.id}
                                        key={item.id}
                                    >
                                        {item.nome}
                                    </Select.Option>
                                )
                            })
                        }
                    </Select>
                </Col>
                <Col style={{ marginBottom: 5 }}>
                </Col>
            </Row> */}
            <Row>
                <Col span={24}>
                    <Title level={4}>
                        <IconFaTruck style={{ fontSize: 25, marginRight: 10 }} />
                        {`Veículos Próprios`}
                    </Title>
                    <Divider />
                </Col>
            </Row>
            {empresaSelecionado &&
                <Form
                    ref={refForm}
                    name="formRelatorioVeiculosProprios"
                    layout="vertical"
                    initialValues={{
                        remember: true,
                    }}
                    onFinishFailed={() => { }}
                >
                    <Row>
                        <Col {...colInputs}>
                            <Form.Item
                                name="data_hora_saida"
                                label="Data de saída"
                                style={{ margin: 5 }}
                            >
                                <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                    {/* <DateTimePicker
                                        value={dateHourSaidaInicio}
                                        onChange={setDateHourSaidaInicio}
                                        required={false}
                                        style={{
                                            width: '100%'
                                        }}
                                        okLabel="Ok"
                                        clearLabel="Limpar"
                                        cancelLabel="Cancelar"
                                        clearable
                                        format="dd/MM/yyyy HH:mm"
                                    /> */}
                                    <DatePicker
                                        value={dateHourSaidaInicio}
                                        onChange={setDateHourSaidaInicio}
                                        required={false}
                                        style={{
                                            width: '100%'
                                        }}
                                        okLabel="Ok"
                                        clearLabel="Limpar"
                                        cancelLabel="Cancelar"
                                        clearable
                                        format="dd/MM/yyyy"
                                    />
                                </MuiPickersUtilsProvider>
                            </Form.Item>
                        </Col>
                        {/* <Col {...colInputs}>
                            <Form.Item
                                name="data_hora_saida_fim"
                                label="Até hora saída"
                                style={{ margin: 5 }}
                            >
                                <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                    <DateTimePicker
                                        value={dateHourSaidaFim}
                                        onChange={setDateHourSaidaFim}
                                        required={false}
                                        style={{
                                            width: '100%'
                                        }}
                                        okLabel="Ok"
                                        clearLabel="Limpar"
                                        cancelLabel="Cancelar"
                                        clearable
                                        format="dd/MM/yyyy HH:mm"
                                    />
                                </MuiPickersUtilsProvider>
                            </Form.Item>
                        </Col> */}
                        <Col {...colInputs}>
                            <Form.Item
                                name="data_hora_chegada"
                                label="Data de chegada"
                                style={{ margin: 5 }}
                            >
                                <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                    {/* <DateTimePicker
                                        value={dateHourChegadaInicio}
                                        onChange={setDateHourChegadaInicio}
                                        required={false}
                                        style={{
                                            width: '100%'
                                        }}
                                        okLabel="Ok"
                                        clearLabel="Limpar"
                                        cancelLabel="Cancelar"
                                        clearable
                                        format="dd/MM/yyyy HH:mm"
                                    /> */}
                                    <DatePicker
                                        value={dateHourChegadaInicio}
                                        onChange={setDateHourChegadaInicio}
                                        required={false}
                                        style={{
                                            width: '100%'
                                        }}
                                        okLabel="Ok"
                                        clearLabel="Limpar"
                                        cancelLabel="Cancelar"
                                        clearable
                                        format="dd/MM/yyyy"
                                    />
                                </MuiPickersUtilsProvider>
                            </Form.Item>
                        </Col>
                        {/* <Col {...colInputs}>
                            <Form.Item
                                name="data_hora_chegada_fim"
                                label="Até hora chegada"
                                style={{ margin: 5 }}
                            >
                                <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                    <DateTimePicker
                                        value={dateHourChegadaFim}
                                        onChange={setDateHourChegadaFim}
                                        required={false}
                                        style={{
                                            width: '100%'
                                        }}
                                        okLabel="Ok"
                                        clearLabel="Limpar"
                                        cancelLabel="Cancelar"
                                        clearable
                                        format="dd/MM/yyyy HH:mm"
                                    />
                                </MuiPickersUtilsProvider>
                            </Form.Item>
                        </Col> */}
                        <Col {...colInputs}>
                            <Form.Item
                                name="veiculoId"
                                label="Veículos"
                                style={{ margin: 5 }}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder="Digite o nome do veiculo"
                                    optionFilterProp="children"
                                    // className="botaoListVendedorUser"
                                    // onChange={onChangeSelectVeiculo}
                                    // value={veiculoSecionado}
                                    loading={loadingListVeiculo}
                                    notFoundContent={loadingListVeiculo ? <Spin size="small" /> : null}
                                    // disabled={!hasSelectedAdd}
                                    // defaultValue='Usuários'
                                    style={{
                                        width: '100%',
                                    }}
                                    filterOption={(input: any, option: any) => {
                                        let textDigit = removeAcento(input)
                                        let listCidade = removeAcento(option?.children);
                                        return listCidade.indexOf(textDigit) >= 0
                                    }}
                                >
                                    {
                                        dataListVeiculo.map((item) => {
                                            return (
                                                <Select.Option
                                                    value={item.id}
                                                    key={item.id}
                                                >
                                                    {item.descricao + ' - ' + item.placa}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                name="colaborador_id"
                                label="Colaborador"
                                style={{ margin: 5 }}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder="Digite o nome do colaborador"
                                    optionFilterProp="children"
                                    // className="botaoListVendedorUser"
                                    // onChange={onChangeSelectVeiculo}
                                    // value={veiculoSecionado}
                                    loading={loadingListColaborador}
                                    notFoundContent={loadingListColaborador ? <Spin size="small" /> : null}
                                    // disabled={!hasSelectedAdd}
                                    // defaultValue='Usuários'
                                    style={{
                                        width: '100%',
                                    }}
                                    filterOption={(input: any, option: any) => {
                                        let textDigit = removeAcento(input)
                                        let listCidade = removeAcento(option?.children);
                                        return listCidade.indexOf(textDigit) >= 0
                                    }}
                                >
                                    {
                                        dataListColaborador.map((item) => {
                                            return (
                                                <Select.Option
                                                    value={item.id}
                                                    key={item.id}
                                                >
                                                    {item.nome + ' - ' + item.cargo?.cargo}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                name="tipo_id"
                                label="Tipo veículo"
                                style={{ margin: 5 }}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder="Digite o tipo do veículo"
                                    optionFilterProp="children"
                                    // className="botaoListVendedorUser"
                                    // onChange={onChangeSelectVeiculo}
                                    // value={veiculoSecionado}
                                    loading={loadingListTipoVeiculo}
                                    notFoundContent={loadingListTipoVeiculo ? <Spin size="small" /> : null}
                                    // disabled={!hasSelectedAdd}
                                    // defaultValue='Usuários'
                                    style={{
                                        width: '100%',
                                    }}
                                    filterOption={(input: any, option: any) => {
                                        let textDigit = removeAcento(input)
                                        let listCidade = removeAcento(option?.children);
                                        return listCidade.indexOf(textDigit) >= 0
                                    }}
                                >
                                    {
                                        dataListTipoVeiculo.map((item) => {
                                            return (
                                                <Select.Option
                                                    value={item.id}
                                                    key={item.id}
                                                >
                                                    {item.tipo}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                name="usuario_id"
                                label="Usuário"
                                style={{ margin: 5 }}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder="Digite o nome do usuario"
                                    optionFilterProp="children"
                                    // className="botaoListVendedorUser"
                                    // onChange={onChangeSelectVeiculo}
                                    // value={veiculoSecionado}
                                    loading={loadingListUsuario}
                                    notFoundContent={loadingListUsuario ? <Spin size="small" /> : null}
                                    // disabled={!hasSelectedAdd}
                                    // defaultValue='Usuários'
                                    style={{
                                        width: '100%',
                                    }}
                                    filterOption={(input: any, option: any) => {
                                        let textDigit = removeAcento(input)
                                        let listCidade = removeAcento(option?.children);
                                        return listCidade.indexOf(textDigit) >= 0
                                    }}
                                >
                                    {
                                        dataListUsuario.map((item) => {
                                            return (
                                                <Select.Option
                                                    value={item.id}
                                                    key={item.id}
                                                >
                                                    {item.nome}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <div className="botaoSalvarStep" style={{
                                flexDirection: 'row',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                // background: 'red'
                            }}>
                                <Button
                                    key="submit"
                                    type="primary"
                                    style={{
                                        marginTop: 20
                                    }}
                                    onClick={() => {
                                        submitBuscarDadosFiltrados()
                                    }}
                                >
                                    {"Buscar"}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            }

            {
                dadosTabelaState?.registros ?
                    <Row>
                        <Col span={24}>
                            <div
                                // className="botaoSalvarStep"
                                style={{
                                    flexDirection: 'row',
                                    display: 'flex',
                                    // justifyContent: 'flex-end',
                                    // background: 'red'
                                }}
                            >
                                <Tooltip placement="top" color='green' title={'XLSX'}>
                                    <Button
                                        key="submit"
                                        type="primary"
                                        className='RelatorioVeiculosPriprios_BotaoXLSX_dkhjfvbwefewlfv'
                                        style={{
                                            marginTop: 30,
                                            marginRight: 5
                                        }}
                                        onClick={() => {
                                            handleCLickGenerateXLSX()
                                        }}
                                    >
                                        <FileTextOutlined style={{ fontSize: 22 }} />
                                    </Button>
                                </Tooltip>
                                <Tooltip placement="top" color='red' title={'PDF'}>
                                    <Button
                                        key="submit"
                                        type="primary"
                                        danger
                                        style={{
                                            marginTop: 30
                                        }}
                                        onClick={() => {
                                            handleCLickGeneratePDF()
                                        }}
                                    >
                                        <FilePdfOutlined style={{ fontSize: 22 }} />
                                    </Button>
                                </Tooltip>
                            </div>
                        </Col>
                    </Row>
                    :
                    <></>
            }

            <Table
                style={{
                    // marginTop: 30
                }}
                className='marginTopTablesListUsuarios'
                rowKey={(record) => record.id}
                loading={loadingScreen}
                onRow={(record, rowIndex) => {
                    return {
                        // onClick: event => { onclickTable(record, rowIndex) },
                        onDoubleClick: event => { handleDoubleClick(record, rowIndex) }
                    };
                }}
                // rowClassName={(record, index) => record?.empresaUsuarios[0]?.gestor ? 'table-row-gestor' : 'table-row-normal'}
                // rowSelection={rowSelection}
                columns={columns} dataSource={dadosTabelaState?.registros ? dadosTabelaState.registros : []}
                scroll={{ x: 650 }}
                // onChange={
                //     (pagination, filters, sorter, extra) => {
                //         refContagemDoFiltro.current = extra.currentDataSource
                //     }
                // }
                title={(registros) => {

                    return (
                        <>
                            {
                                dadosTabelaState?.registros ?
                                    <div
                                        style={{
                                            backgroundColor: theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                                            padding: 10,
                                            margin: -8,
                                            marginBottom: 0.5
                                        }}
                                    >
                                        {
                                            dadosTabelaState?.registros &&
                                            <Text>
                                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                                {`Mostrando: ${registros.length} de ${dadosTabelaState.registros.length} num total de ${dadosTabelaState?.total} `}
                                            </Text>
                                        }
                                    </div>
                                    : <></>
                            }
                        </>
                    )
                }}
                footer={(registros) => {
                    return (
                        <div
                            style={{
                                textAlign: 'end'
                            }}
                        >
                            {
                                dadosTabelaState?.registros &&
                                <Text>
                                    {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                    {`Mostrando: ${registros.length} de ${dadosTabelaState.registros.length} num total de ${dadosTabelaState?.total} `}
                                </Text>
                            }
                        </div>
                    )
                }}
                size="small"
                pagination={{
                    locale: { items_per_page: '' },
                    // showSizeChanger: true,//isso faz que o usuario possa escolher quantos ele quer por pg
                    defaultPageSize: 50,
                    total: dadosTabelaState?.total,//mandar o total de registros
                    pageSize: 50,//quantos por pagina
                    onChange(page, pageSize) {
                        // console.log(page)
                        refNumeroPaginate.current = page
                        submitBuscarDadosFiltrados()
                        // if (page == 1) {
                        //     setDadosTabelaState(constData1)
                        // } else {
                        //     setDadosTabelaState(constData2)

                        // }
                    },
                    position: ['topRight', 'bottomRight'],
                    // pageSizeOptions: ['5', '10', '20', '50', '100']
                    pageSizeOptions: ['50']
                }}
                locale={locale.Table}
            />


        </LayoutDashboard>
    );
}

export default VeiculosProprios;
