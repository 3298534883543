import { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row, Modal, Button, Form, notification, Input, Select } from 'antd';
import { mask, unMask } from '../../../../../../utils/MascaraDeCampos';
import { Loading } from '../../../../../../components/Loading';
import { SairDoSistema } from '../../../../../../services/LStorage/SairSistema';
import { EmitirErro } from '../../../../../../services/EmitirErro';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../../../../services/apiAxios';
import { getToken } from '../../../../../../services/LStorage/token';
import { urlEventoConvidado } from '../../../../../../services/urls';
import { colInputs } from './GridStyle';
import { removeAcento } from '../../../../../../utils/RemoveAcentos';

interface IntefObjConvidados {
    "createdAt": string,
    "updatedAt": string,
    "id": string,
    "nome": string,
    "placa"?: string,
    "eventoId": string,
}
interface PropsModal {
    visible: boolean;
    setFecharModal: () => void;
    objConvidados: IntefObjConvidados | null | undefined;
    eventoId: string | null | undefined;
}

export const ModalConvidadosAddOrUpdate = ({ visible, setFecharModal, objConvidados, eventoId }: PropsModal) => {

    const navigate = useNavigate();

    const refForm = useRef<any>(null);

    const [estaEditando, setEstaEditando] = useState(false);
    const [visibleModalEmpresa, setVisibleModalEmpresa] = useState(false);
    const [loading, setLoading] = useState(false);

    //setando dados das cidades no selected
    const buscaDadosUsuario = useCallback((objfunc: IntefObjConvidados) => {

        const buscaCiaddes = async () => {
            try {
                setLoading(true)

                setTimeout(() => {
                    refForm.current?.setFieldsValue(
                        {
                            nome: objfunc.nome ? objfunc.nome : undefined,
                            placa: objfunc?.placa ? objfunc.placa : undefined,
                            eventoId: objfunc.eventoId ? objfunc.eventoId : undefined,
                        }
                    );
                    setLoading(false)
                }, 500);


            } catch (error) {
                let msgErro: any = (error as Error);
                setLoading(false)

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);


    useEffect(() => {

        if (visible) {
            setVisibleModalEmpresa(true);

            if (objConvidados) {
                setEstaEditando(true)
                if (objConvidados) buscaDadosUsuario(objConvidados);
            } else {
                setTimeout(() => {
                    refForm.current?.setFieldsValue(
                        {
                            eventoId: eventoId,
                        }
                    );
                }, 500);

                setEstaEditando(false)
            }

        }


    }, [visible, objConvidados, eventoId]);

    const onChangeInputPlaca = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, [
            "AAA-9S99"
        ]);

        refForm.current.setFieldsValue(
            { placa: maskedValue.toUpperCase() }
        );
        if (maskedValue.length >= 14) {
            refForm.current.validateFields(["placa"])
        }

    }, []);


    const submitBotaoSalvarEAdicionar = useCallback(() => {


        const salvarDadosCriar = async (obj: any) => {
            try {
                setLoading(true);
                await api.post(urlEventoConvidado, obj,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Dados criados com sucesso!',
                });
                refForm.current?.resetFields(['nome', 'placa'])
                setLoading(false);

                // clickCancelModal();

            } catch (error) {
                setLoading(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        refForm.current?.validateFields()
            .then((values: any) => {

                salvarDadosCriar(values);

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, []);

    const submitBotaoSalvar = useCallback(() => {


        const salvarDadosCriar = async (obj: any) => {
            try {
                setLoading(true);
                await api.post(urlEventoConvidado, obj,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Dados criados com sucesso!',
                });

                setLoading(false);

                clickCancelModal();

            } catch (error) {
                setLoading(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        refForm.current?.validateFields()
            .then((values: any) => {

                salvarDadosCriar(values);

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, []);

    const submitBotaoEditar = useCallback((objConvidado: IntefObjConvidados) => {

        const salvarDadosEditar = async (obj: any) => {
            try {
                setLoading(true);

                await api.put(urlEventoConvidado + '/' + objConvidado.id, obj,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Dados editados com sucesso!',
                });

                // setDataListCidades(resultCidade.data);

                setLoading(false);
                clickCancelModal();

            } catch (error) {
                setLoading(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        refForm.current?.validateFields()
            .then((values: any) => {

                salvarDadosEditar(values);

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, []);

    const clickCancelModal = useCallback((): void => {
        refForm.current.resetFields();
        setVisibleModalEmpresa(false);
        setFecharModal();
    }, []);

    return (
        <Modal
            title={objConvidados ? "Editar Convidado" : "Adicionar Convidado"}
            visible={visibleModalEmpresa}
            key='modaEventoDireto'
            className="modalAddRotas"
            onOk={() => {
                estaEditando ?
                    objConvidados && submitBotaoEditar(objConvidados)
                    :
                    submitBotaoSalvar()
            }}
            onCancel={clickCancelModal}
            footer={[
                <Button key="backCancelar" disabled={loading} onClick={clickCancelModal}>
                    Cancelar
                </Button>,
                <Button key="submitSalvar" type="primary" loading={loading} onClick={() => {
                    estaEditando ?
                        objConvidados && submitBotaoEditar(objConvidados)
                        :
                        submitBotaoSalvar()
                }}>
                    Salvar
                </Button>,
                <>
                    {!estaEditando &&
                        <Button key="saveAndsubmit" type="primary" loading={loading} onClick={() => {
                            submitBotaoSalvarEAdicionar()
                        }}>
                            Salvar e Adicionar
                        </Button>}</>,
            ]}
        >
            <Loading
                loading={loading}
            >
                <Form
                    ref={refForm}
                    name="adicionarUsuario"
                    layout="vertical"
                    // onFieldsChange={onFieldsChange}
                    initialValues={{
                        remember: true,
                    }}
                    // onFinish={onFinish}
                    // onFinishFailed={() => { }}
                    autoComplete="off"
                >
                    <Row>
                        <Col {...colInputs}>
                            <Form.Item
                                style={{
                                    margin: 5
                                }}
                                name="nome"
                                label="Nome"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor digite o nome!',
                                    },
                                ]}
                            >
                                <Input autoComplete="off" placeholder="Nome"
                                    onChange={() => {

                                        const text = refForm.current?.getFieldValue('nome')
                                        const first = text.charAt(0).toUpperCase()
                                        const rest = text.substring(1);

                                        refForm.current?.setFieldsValue(
                                            { 'nome': first + rest }
                                        )

                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                style={{
                                    margin: 5
                                }}
                                name="placa"
                                label="Placa"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Por favor digite a placa!',
                                        max: 8
                                    },
                                    () => ({
                                        validator(rule, value) {
                                            return new Promise((resolve: (value?: any) => void, reject) => {
                                                //CUIDADO AO EDITAR PARA PODER ACEITAR MESMO EMAIL
                                                if (value != '' && value != null) {
                                                    value = unMask(value);

                                                    if (value.length >= 7) {
                                                        // buscarCelularSeJaExiste(value).then(valorPromessa => {
                                                        //     if (valorPromessa) {
                                                        //         reject("Celular já existe!");
                                                        //     } else {
                                                        //         resolve();
                                                        //     }
                                                        // }).catch(error => {
                                                        //     resolve();
                                                        // })
                                                        resolve();
                                                    } else {
                                                        reject("Placa inválida");
                                                    }
                                                } else {
                                                    // reject('');
                                                    resolve();
                                                }
                                            });
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    autoComplete="off"
                                    placeholder="Placa"
                                    onChange={(e) => onChangeInputPlaca(e)}
                                />
                            </Form.Item>

                        </Col>
                        <Col hidden {...colInputs}>
                            <Form.Item
                                name="eventoId"
                                label="ID do envento"
                                style={{ margin: 5 }}
                                hidden
                            >
                                <Input disabled hidden />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </Loading>
        </Modal>
    );
}
