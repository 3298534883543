import { useCallback, useRef, useState } from 'react';
import { Typography, Button, Form, Row, Col, Divider, notification } from 'antd';
import Icon from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { FormsInputEmpresa } from './../../../../../components/FormsInputsEmpresa';
import { Loading } from '../../../../../components/Loading';
import { unMask } from '../../../../../utils/MascaraDeCampos';
import { SairDoSistema } from '../../../../../services/LStorage/SairSistema';
import { EmitirErro } from '../../../../../services/EmitirErro';
import { api } from '../../../../../services/apiAxios';
import { urlEmpresas, urlEmpresasModulos } from '../../../../../services/urls';
import { getToken } from '../../../../../services/LStorage/token';
import { FaBuilding } from 'react-icons/fa';
import './style.css';

const IconBuilding = (props: any) => (<Icon {...props} component={FaBuilding} ></Icon>);

const { Text, Title, Link } = Typography;

interface IntefReqModulosEditar {
    "id": string,
    "empresaId"?: string | null,
    "ativo": boolean,
    "modulo": {
        "id": string,
        "nome": string,
        "descricao": string
    }
}

interface IntefEmpresas {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "ativo": boolean,
    "nome": string,
    "cnpj": string,
    "telefone": string,
    "bairro": string | null,
    "cep": string | null,
    "cidade": string | null,
    "logradouro": string | null,
    "numero": string | null,
    "limite_diferenca_validacao_km": string | number | null,
    "uf": string | null,
    "deletedAt": string | null
}

type interfOpcaoScreen = 'visualizar' | 'editar';

interface interPros {
    empresaSelecionado?: IntefEmpresas,
    alterOpcaoScreen: (opcao: interfOpcaoScreen) => void;
    atualizarEmpresas: () => void;
}

export const EditarDadosEmpresa = ({ empresaSelecionado, alterOpcaoScreen, atualizarEmpresas }: interPros) => {

    const navigate = useNavigate();
    const refForm = useRef<any>(null);
    const [loading, setLoading] = useState(false);

    const submitBotaoEditar = useCallback((idUser: string) => {

        const salvarDadosEditar = async (obj: any) => {
            try {
                setLoading(true);

                let resultUser = await api.put(urlEmpresas + '/' + idUser, obj,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                let todosModulosEditar: Array<IntefReqModulosEditar> = refForm.current.getFieldValue('todosModulosEditar')
                let modulosSelecionados = refForm.current.getFieldValue('modulos')

                let objSalvarModulos: Array<IntefReqModulosEditar> = []

                todosModulosEditar.forEach(element => {

                    if (modulosSelecionados.includes(element.modulo.id)) {
                        objSalvarModulos.push({
                            ...element,
                            ativo: true,
                            empresaId: empresaSelecionado?.id
                        })
                    } else {
                        objSalvarModulos.push({
                            ...element,
                            ativo: false,
                            empresaId: empresaSelecionado?.id
                        })
                    }
                });

                let resultEMpresasModulos = await api.put(urlEmpresasModulos, { modulos: objSalvarModulos },
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Seus dados foram editados com sucesso!',
                });

                // setDataListCidades(resultCidade.data);

                setLoading(false);
                alterOpcaoScreen('visualizar')
                atualizarEmpresas();

            } catch (error) {
                setLoading(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        refForm.current?.validateFields()
            .then((values: any) => {

                values.telefone = unMask(values.telefone);
                values.cnpj = unMask(values.cnpj);

                salvarDadosEditar(values);

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, [empresaSelecionado]);

    return (
        <>
            <Loading
                loading={loading}
            >
                <Row>
                    <Col span={24}>
                        <Title level={4}>
                            <IconBuilding style={{ fontSize: 25, marginRight: 10 }} />
                            {`${'Editar'} Empresa`}
                        </Title>
                        <div
                            style={{
                                display: 'flex'
                            }}
                        >
                            <Link
                                onClick={() => [
                                    alterOpcaoScreen('visualizar')
                                ]}
                                style={{
                                    marginRight: 5
                                }}
                            >
                                <Text
                                    type='secondary' underline
                                >Visualizar</Text>
                            </Link>

                            <Text
                                style={{
                                    marginRight: 5
                                }} type='secondary'
                            >/</Text>

                            <Text style={{
                                marginRight: 5
                            }}>
                                {
                                    `${'Editar'}`
                                }
                            </Text>
                        </div>
                        <Divider />
                    </Col>
                </Row>

                <Form
                    ref={refForm}
                    name="adicionarUsuario"
                    layout="vertical"
                    // onFieldsChange={onFieldsChange}
                    initialValues={{
                        remember: true,
                    }}
                    // onFinish={onFinish}
                    // onFinishFailed={() => { }}
                    autoComplete="off"
                >
                    <FormsInputEmpresa
                        empresaSelecionado={empresaSelecionado}
                        refForm={refForm}
                        readonly={false}
                        editarModulos
                    />
                    <Col span={24}>
                        <div className="botaoSalvarStep" style={{
                            flexDirection: 'row',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            // background: 'red'
                        }}>
                            <Button
                                // type="primary"
                                style={{
                                    marginTop: 20,
                                    marginRight: 5
                                }}
                                onClick={() => {
                                    alterOpcaoScreen('visualizar')
                                }}
                            >
                                {"Cancelar"}
                            </Button>

                            <Button
                                key="submit"
                                type="primary"
                                style={{
                                    marginTop: 20,
                                    marginRight: 5
                                }}
                                onClick={() => {
                                    if (empresaSelecionado?.id) {
                                        submitBotaoEditar(empresaSelecionado?.id)
                                    }
                                }}
                            >
                                {"Salvar"}
                            </Button>
                        </div>
                    </Col>
                </Form>
            </Loading>
        </>
    );
}
