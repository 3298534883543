import { useCallback, useEffect, useState, useRef } from 'react';
import { Row, Col, Select, Tabs, Typography, notification, Spin, Button, Divider } from 'antd';
import Icon from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { colSelect } from './GridStyle';

import { validacaoPermissao, VerificaRota } from './../../../services/VerificaSePodeAcessar';
import { urlEmpresas, urlEmpresasUsuarioPainel } from './../../../services/urls';
import { api } from './../../../services/apiAxios';
import { EmitirErro } from '../../../services/EmitirErro';
import { SairDoSistema } from '../../../services/LStorage/SairSistema';
import { getToken } from './../../../services/LStorage/token';
import { FaBuilding } from 'react-icons/fa';


import './style.css';

import {
    removeAcento
} from './../../../utils/RemoveAcentos';
import { getIdUsuario } from '../../../services/LStorage/UsuarioERefresh';

const { Text, Title } = Typography;
const IconBuilding = (props: any) => (<Icon {...props} component={FaBuilding} ></Icon>);

interface IntefListEmpresas {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "ativo": boolean,
    "nome": string,
    "cnpj": string,
    "telefone": string,
    "bairro": string | null,
    "cep": string | null,
    "cidade": string | null,
    "logradouro": string | null,
    "numero": string | null,
    "uf": string | null,
    "deletedAt": string | null
}

type interfOpcaoScreen = 'tabs' | 'addEmpresa';

interface interPros {
    alterOpcaoScreen: (opcao: interfOpcaoScreen) => void;
}
export const SelecionarEmpresaSelect = ({ alterOpcaoScreen }: interPros) => {

    const navigate = useNavigate();

    const refDataListEmpresas = useRef<Array<IntefListEmpresas>>([]);
    const refEmpresaSelecionada = useRef<IntefListEmpresas | null>(null);

    const [dataListEmpresas, setDataListEmpresas] = useState<Array<IntefListEmpresas>>([]);
    const [loadingListEmpresas, setLoadingListEmpresas] = useState(false);
    const [empresaSelecionada, setEmpresaSelecionada] = useState<IntefListEmpresas | null>(null);

    const selectEmpresaPorId = useCallback((id: string): IntefListEmpresas | null => {
        let objCidade = null;
        refDataListEmpresas.current.forEach(val => {
            if (val.id === id) {
                objCidade = val
            }
        });
        return objCidade;

    }, []);

    //setando dados das cidades no selected
    const buscaEmpresas = useCallback(() => {

        const requisicao = async () => {
            try {
                setLoadingListEmpresas(true);
                let idUsuario = getIdUsuario();
                // let resultCidade = await api.get(urlEmpresasUsuarioGestor + '/' + idUsuario,
                let result = await api.get(urlEmpresasUsuarioPainel + '/' + idUsuario,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                refDataListEmpresas.current = result.data
                setDataListEmpresas(result.data);

                if (result.data.length == 1) {

                    refEmpresaSelecionada.current = result.data[0]
                    setEmpresaSelecionada(result.data[0])

                }


                setLoadingListEmpresas(false);

            } catch (error) {
                setLoadingListEmpresas(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        requisicao();

    }, []);

    useEffect(() => {

        let arrayUrl = window.location.pathname.split("/");
        VerificaRota(arrayUrl, navigate);

        buscaEmpresas();


    }, []);

    const onChangeSelectEmpresas = useCallback((value: any, obj: any): void => {

        const objSelecionado = selectEmpresaPorId(value)

        refEmpresaSelecionada.current = objSelecionado

        setEmpresaSelecionada(objSelecionado)

    }, []);

    const handleClickSalvar = useCallback((): void => {
        const requisicao = async () => {
            try {
                if (refEmpresaSelecionada.current) {

                    let resultEmpresa = await api.get(urlEmpresas + '/' + refEmpresaSelecionada.current.id,
                        {
                            headers: {
                                'Authorization': 'Bearer ' + getToken()
                            }
                        });

                    localStorage.setItem('@SisPortaria:empresa', JSON.stringify(
                        { empresaSelecionada: resultEmpresa.data }
                    ));

                    navigate('/dashboard');
                } else {
                    notification.error({
                        message: 'Erro',
                        description:
                            'Selecione uma empresa!',
                    });
                }
            } catch (error) {
                setLoadingListEmpresas(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        requisicao();

    }, []);

    return (
        <>
            <Row>
                <Col span={24}>
                    <Title level={4}>
                        <IconBuilding style={{ fontSize: 25, marginRight: 10 }} />
                        {`Selecionar Empresa`}
                    </Title>
                    <Divider />
                </Col>
            </Row>
            {
                validacaoPermissao(['admin']) &&
                <Row>
                    <Col style={{ marginBottom: 15 }}>
                        <Button
                            type="primary"
                            className="Empresas_BotaoAdd_agfjlkabofd"

                            // disabled={!hasSelectedAdd}
                            onClick={() => {
                                alterOpcaoScreen('addEmpresa')
                            }}
                        >
                            Adicionar empresa
                        </Button>
                    </Col>
                </Row>
            }
            <Row style={{
                display: 'flex',
                alignItems: 'flex-end'
            }}>
                <Col {...colSelect} style={{ marginBottom: 5, marginRight: 5 }}>
                    <div
                        style={{ marginBottom: 5 }}
                    >
                        <Text style={{ margin: 1 }} >{'Selecione uma empresa:'}</Text>
                    </div>
                    <Select
                        showSearch
                        placeholder="Digite o nome de uma empresa"
                        optionFilterProp="children"
                        // className="botaoListVendedorUser"
                        onChange={onChangeSelectEmpresas}
                        loading={loadingListEmpresas}
                        notFoundContent={loadingListEmpresas ? <Spin size="small" /> : null}
                        // disabled={!hasSelectedAdd}
                        value={empresaSelecionada?.id}
                        style={{
                            width: '100%',
                        }}
                        filterOption={(input: any, option: any) => {
                            let textDigit = removeAcento(input)
                            let listCidade = removeAcento(option?.children);
                            return listCidade.indexOf(textDigit) >= 0
                        }}
                    >
                        {
                            dataListEmpresas.map((item) => {
                                return (
                                    <Select.Option
                                        value={item.id}
                                        key={item.id}
                                    >
                                        {item.nome}
                                    </Select.Option>
                                )
                            })
                        }
                    </Select>
                </Col>
                <Col style={{ marginBottom: 5 }}>
                    <Button
                        type="primary"
                        // className="Empresas_BotaoAdd_agfjlkabofd"

                        disabled={!empresaSelecionada?.id}
                        onClick={() => {
                            handleClickSalvar()
                        }}
                    >
                        Entrar
                    </Button>
                </Col>
            </Row>

        </>
    );
}

