import React, { useEffect, useCallback, useState, useContext } from 'react';
import { Layout, Menu, Button, Dropdown, Drawer, Radio, Typography, Avatar } from 'antd';
import type { MenuProps } from 'antd';
import {
    UserOutlined,
    LogoutOutlined} from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { FaCog, FaTv, FaBuilding } from 'react-icons/fa';

import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../contexts/globalContext';
// import { GlobalPrimaryContext } from '../../context/GlobalPrimaryContext';

// @ts-ignore
import ImagemLogo from './../../assets/imagens/logo_final.png';

import { SairDoSistema } from './../../services/LStorage/SairSistema';
import { getIdUsuario, getUsuario } from '../../services/LStorage/UsuarioERefresh';


const { Header, Content, Footer } = Layout;
const { Text } = Typography;

// const IconHand = () => <Icon style={{ fontSize: 17 }} component={FaSitemap} ></Icon>
const IconBuilding = (props: any) => (<Icon {...props} component={FaBuilding} ></Icon>);
const IconCogs = (props: any) => (<Icon {...props} component={FaCog} ></Icon>);
const IconTv = (props: any) => (<Icon {...props} component={FaTv} ></Icon>);
// const UnlokProcessoJuridico = (props: any) => <Icon {...props} component={FaUnlockAlt} ></Icon>

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    permissao: boolean,
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
        permissao: permissao.toString()
    } as MenuItem;
}

export default function LayoutHeader({ children }: any) {

    const navigate = useNavigate();
    const { theme, updateTheme } = useContext(GlobalContext);

    const [visibleDrawerColor, setVisibleDrawerColor] = useState(false);

    const [nomeUsuarioLogado, setNomeUsuarioLogado] = useState('');
    const [itemsMenuConfiguracaoState, setItemsMenuConfiguracaoState] = useState<MenuItem[]>([]);


    const onCloseDrawerColor = useCallback((): void => {
        setVisibleDrawerColor(false);
    }, []);

    const onAbrirDrawerColor = useCallback((): void => {
        setVisibleDrawerColor(true);
    }, []);

    const onchangeRadioTheme = useCallback((value: any): void => {

        updateTheme(value.target.value)

        window.location.reload();
    }, []);

    // useEffect(() => {
    //     // let arrayUrl = window.location.pathname.split("/");


    // }, []);

    useEffect(() => {

        let dataUsuario = getUsuario();
        if (dataUsuario) {
            let arrayUrl = dataUsuario.nome.split(" ")
            setNomeUsuarioLogado(arrayUrl[0])
        }

        const itemsMenuConfiguracao: MenuItem[] = [
            // getItem(true, 'Perfil', 'perfil1', <UserOutlined />),
            // getItem(true, 'Usuarios', 'usuarios1', <UserOutlined />),
            getItem(true, 'Tema', 'tema1', <IconTv />),
            // getItem(true, 'Configurações', 'configuracoes1', <IconCogs />),
            getItem(true, 'Sair', 'sair1', <LogoutOutlined />),

        ];

        const menuConfig = itemsMenuConfiguracao.filter((element: any) => element?.permissao === 'true')
        setItemsMenuConfiguracaoState(menuConfig)

    }, []);


    const clickMenuDireitoUsuarios = useCallback((): void => {
        navigate("/usuarios");
    }, []);

    const clickMenuPerfilUsuario = useCallback((): void => {
        let id = getIdUsuario();
        navigate("/perfil/" + id);
    }, []);

    const clickMenuDireitoSair = useCallback((): void => {
        SairDoSistema();
        navigate("/");
        // localStorage.removeItem('@GovFacil:token');
        // dispatch(LoginActions.loginAlterSingOut());
    }, []);

    const clickMenuConfiguracoes = useCallback((): void => {
        navigate("/configuracoes");
    }, []);

    const onClickMenuConfiguracoes = useCallback((e: any): void => {
        switch (e.key) {
            case 'perfil1':
                clickMenuPerfilUsuario();
                break;
            case 'usuarios1':
                clickMenuDireitoUsuarios();
                break;
            case 'tema1':
                onAbrirDrawerColor();
                break;
            case 'configuracoes1':
                clickMenuConfiguracoes();
                break;
            case 'sair1':
                clickMenuDireitoSair();
                break;

            default:
                break;
        }

    }, []);

    const menuPerfil = useCallback(() => (
        <Menu items={itemsMenuConfiguracaoState} onClick={onClickMenuConfiguracoes} />
    ), [itemsMenuConfiguracaoState]);


    return (
        <>
            <Layout style={{ minHeight: '100vh' }}>
                <Layout>
                    <Header
                        style={{
                            position: 'fixed',
                            zIndex: 99,
                            padding: 0,
                            width: '100%',
                            // backgroundColor: theme === 'light' ? '#fff' : '#141414'
                            backgroundColor: theme === 'light' ? '#fff' : '#141414',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div>

                            {
                                theme === 'light' ?
                                    // <img style={{
                                    //     // margin: 15,
                                    //     width: 165,
                                    //     height: 42
                                    // }} src={ImagemLogoHorizontalCorSemIcone} />
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            marginLeft: 15,
                                        }}
                                    >
                                        <img
                                            style={{
                                                width: 40,
                                                // marginTop: -20,
                                                // marginLeft: -20,
                                                // marginBottom: 5
                                            }}
                                            src={ImagemLogo}
                                        />
                                        <h1
                                            style={{
                                                fontFamily: 'Orbitron',
                                                fontWeight: '800',
                                                fontSize: 16
                                            }}>iControl Portaria</h1>
                                    </div>
                                    :
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <img
                                            style={{
                                                width: 40,
                                                // marginTop: -20,
                                                // marginLeft: -20,
                                                // marginBottom: 5
                                            }}
                                            src={ImagemLogo}
                                        />
                                        <h1
                                            style={{
                                                color: '#fff',
                                                fontFamily: 'Orbitron',
                                                fontWeight: '800',
                                                fontSize: 16
                                            }}>iControl Portaria</h1>
                                    </div>
                            }
                        </div>
                        <Dropdown.Button
                            // onClick={handleButtonClick}
                            trigger={['click']}
                            className="dropDownMenu"
                            overlay={menuPerfil}
                            buttonsRender={([leftButton, rightButton]) => ([
                                <></>,
                                <Button
                                    style={{
                                        // marginRight: 10,
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                    type="text">
                                    {
                                        <Text
                                            style={{
                                                marginRight: '6px'
                                            }}
                                        >{nomeUsuarioLogado}</Text>
                                    }
                                    <Avatar size={28} icon={<UserOutlined />} />
                                </Button>

                            ])}

                        />
                    </Header>
                    <Content
                        className={'siteLayoutBackground'}
                        style={{
                            backgroundColor: theme === 'light' ? '#fff' : '#141414'
                        }}
                    >
                        {children}
                    </Content>
                    <Footer
                        style={{
                            paddingLeft: 0, paddingRight: 0, marginTop: -10,
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection:  'row',
                        }}
                    >
                        <div
                            style={{
                                width: '50%',
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            Desenvolvido por GM Sistemas
                            {/* <img style={{
                                width: 90,
                                marginLeft: 5,
                                height: 21
                            }} src={ImagemGovfacil} /> */}
                        </div>
                        {/* <div
                            style={{
                                width: '50%',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginRight: 10
                            }}
                        >
                            <Text
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: 18,
                                    marginLeft: 10,
                                    color: 'red'
                                }}
                            >Sistema em desenvolvimento!</Text>
                        </div> */}
                    </Footer>

                </Layout>
            </Layout>
            {/* Drawer para alterar cor do sistema */}
            <Drawer
                title="Tema do sistema"
                placement="right"
                closable={false}
                onClose={onCloseDrawerColor}
                visible={visibleDrawerColor}
            >
                <Radio.Group
                    onChange={onchangeRadioTheme}
                    defaultValue={theme}
                    size="large">
                    <Radio.Button value="light">Light</Radio.Button>
                    <Radio.Button value="dark">Dark</Radio.Button>
                    {/* <Radio.Button value="c">Beijing</Radio.Button> */}
                </Radio.Group>
            </Drawer>
        </>
    );
};
