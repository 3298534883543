import { useCallback, useState } from 'react';
import { VisualizarDadosEmpresa } from './VisualizarDados';
import { EditarDadosEmpresa } from './EditarDados';
import './style.css';

interface IntefEmpresas {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "ativo": boolean,
    "nome": string,
    "cnpj": string,
    "telefone": string,
    "bairro": string | null,
    "cep": string | null,
    "cidade": string | null,
    "logradouro": string | null,
    "numero": string | null,
    "limite_diferenca_validacao_km": string | number | null,
    "uf": string | null,
    "deletedAt": string | null
}
interface interPros {
    empresaSelecionado?: IntefEmpresas,
    atualizarEmpresas: () => void;
}
type interfOpcaoScreen = 'visualizar' | 'editar';

export const DadosEmpresa = ({ empresaSelecionado, atualizarEmpresas }: interPros) => {

    const [opcaoScreen, setOpcaoScreen] = useState<interfOpcaoScreen>('visualizar');

    const alterOpcaoScreen = useCallback((opcao: interfOpcaoScreen) => {
        setOpcaoScreen(opcao)
    }, []);

    return (
        <>
            {
                opcaoScreen === 'visualizar' ?
                    <VisualizarDadosEmpresa
                        alterOpcaoScreen={alterOpcaoScreen}
                        empresaSelecionado={empresaSelecionado}
                    />
                    :
                    <EditarDadosEmpresa
                        alterOpcaoScreen={alterOpcaoScreen}
                        empresaSelecionado={empresaSelecionado}
                        atualizarEmpresas={atualizarEmpresas}
                    />
            }
        </>
    );
}
