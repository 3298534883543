import { api } from "./../../apiAxios";
import { removeToken } from "./../token";
import { removeRefreshToken, removeUsuario } from "./../UsuarioERefresh";
import { removeEmpresa } from "./../EmpresaSelecionada";

export const SairDoSistema = (): void => {
    removeToken();
    removeRefreshToken();
    removeUsuario();
    removeEmpresa();
    api.defaults.headers.common['Authorization'] = '';
}
