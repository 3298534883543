import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Col, Row, Typography, Button, Form, notification, Input, Divider, Table, Popconfirm } from 'antd';
import locale from 'antd/es/locale/pt_BR';
import moment from 'moment';
import { mask, unMask } from '../../../../../utils/MascaraDeCampos';
import { Loading } from '../../../../../components/Loading';
import { SairDoSistema } from '../../../../../services/LStorage/SairSistema';
import { EmitirErro } from '../../../../../services/EmitirErro';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../../../services/apiAxios';
import { getToken } from '../../../../../services/LStorage/token';
import { urlEventoConvidado, urlEventoConvidadoEvento, urlEvento } from '../../../../../services/urls';
import ptLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { colFull, colInputs } from './GridStyle';
import { CarOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { convertDataBrHoraParaUSHora, convertDataUSParaBrasil } from '../../../../../services/Conversores';
import { useGetColumnSearchPropsData } from '../../../../../hooks/FilterTable/date';
import { useGetColumnSearchProps } from '../../../../../hooks/FilterTable/string';
import { GlobalContext } from '../../../../../contexts/globalContext';
import { ModalConvidadosAddOrUpdate } from './ModalConvidadosAddOrUpdate';

import './style.css';

const { Text, Title, Link } = Typography;

type interfOpcaoScreen = 'listar' | 'addUpdate';

interface PropsModal {
    id: string | null | undefined;
    alterOpcaoScreen: (opcao: interfOpcaoScreen, id?: string) => void;
    // empresaSelecionado?: IntefEmpresas,
    idEmpresa: string | null | undefined;
}

interface IntefDadosTable {
    "createdAt": string,
    "updatedAt": string,
    "id": string,
    "nome": string,
    "placa": string,
    "eventoId": string,
}

export const EventoAddOrUpdate = ({ id, alterOpcaoScreen, idEmpresa }: PropsModal) => {

    const navigate = useNavigate();

    const refForm = useRef<any>(null);

    const { theme } = useContext(GlobalContext);

    const refIdEvento = useRef<string>();
    const refContagemDoFiltro = useRef<Array<any>>([]);
    const [dadosTabelaState, setDadosTabelaState] = useState<Array<IntefDadosTable>>([]);
    const [loadingConvidado, setLoadingConvidado] = useState(false);
    const [abrirModalConvidado, setAbrirModalConvidado] = useState(false);
    const [objModalConvidado, setObjModalConvidado] = useState<null | IntefDadosTable>(null);


    const [estaEditando, setEstaEditando] = useState(false);
    const [loading, setLoading] = useState(false);

    const [dateHourDoEvento, setDateHourDoEvento] = useState<any>(null);

    //desmontar ou destruir component
    useEffect(() => () => {

        let dadosLocalStorage: string | null = localStorage.getItem('@SisPortaria:eventoEditForEmpresa');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData) {
            localStorage.removeItem('@SisPortaria:eventoEditForEmpresa');
        }

    }, []);

    //setando dados das cidades no selected
    const buscaDadosUsuario = useCallback(() => {

        const buscaCiaddes = async () => {
            try {
                //pega dados do localStorage se existir
                let dadosLocalStorage: string | null = localStorage.getItem('@SisPortaria:eventoEditForEmpresa');
                let localUserData: any = null;
                if (typeof dadosLocalStorage == 'string') {
                    localUserData = JSON.parse(dadosLocalStorage);
                }

                if (localUserData) {
                    setLoading(true)

                    setTimeout(() => {
                        refForm.current?.setFieldsValue(
                            {
                                responsavel: localUserData.responsavel ? localUserData.responsavel : undefined,
                                observacoes: localUserData.observacoes ? localUserData.observacoes : undefined,
                                empresaId: localUserData.empresaId ? localUserData.empresaId : undefined,
                            }
                        );

                        if (localUserData.telefone_responsavel) {
                            const originalValue = unMask(localUserData.telefone_responsavel);
                            const maskedValue = mask(originalValue, [
                                "(99) 9999-9999",
                                "(99) 9 9999-9999"
                            ]);
                            refForm.current?.setFieldsValue({
                                telefone_responsavel: maskedValue
                            })
                        }

                        if (localUserData.data) {
                            setDateHourDoEvento(new Date(localUserData.data))
                        }

                        setLoading(false)
                    }, 500);

                }

            } catch (error) {
                let msgErro: any = (error as Error);
                setLoading(false)

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);

    const configuracoesIniciaisDeCriar = useCallback((idEmpresa: any) => {
        setTimeout(() => {
            refForm.current?.setFieldsValue(
                {
                    empresaId: idEmpresa,
                }
            );
        }, 500);

        setEstaEditando(false)

    }, []);

    //setando dados das cidades no selected
    const buscaDadosTabelaConvidado = useCallback(() => {

        const funcBusca = async () => {
            try {
                setLoadingConvidado(true);
                let result = await api.get(urlEventoConvidadoEvento + '/' + refIdEvento.current,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDadosTabelaState(result.data);

                setLoadingConvidado(false);

            } catch (error) {
                setLoadingConvidado(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'usuarios_dkwdfaiweyi')
                }


            }
        }

        funcBusca();

    }, []);

    useEffect(() => {

        if (typeof id === 'string') {
            if (id === 'criar') {

                //adicionar
                configuracoesIniciaisDeCriar(idEmpresa)
            } else {
                refIdEvento.current = id
                setEstaEditando(true)
                buscaDadosUsuario();
                buscaDadosTabelaConvidado();

            }
        } else {

            //adicionar
            configuracoesIniciaisDeCriar(idEmpresa)
        }

    }, [id, idEmpresa]);

    const onChangeInputTelefone = useCallback((e: any) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, [
            "(99) 9999-9999",
            "(99) 9 9999-9999"
        ]);

        refForm.current.setFieldsValue(
            { telefone_responsavel: maskedValue }
        );

        if (maskedValue.length >= 14) {
            refForm.current.validateFields(["telefone_responsavel"])
        }

    }, []);

    const submitBotaoSalvar = useCallback(() => {


        const salvarDadosCriar = async (obj: any) => {
            try {
                setLoading(true);
                let result = await api.post(urlEvento, obj,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                refIdEvento.current = result.data.id
                setEstaEditando(true)
                // buscaDadosUsuario();
                buscaDadosTabelaConvidado();

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Dados criados com sucesso!',
                });

                setLoading(false);

                // alterOpcaoScreen('listar')

            } catch (error) {
                setLoading(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        refForm.current?.validateFields()
            .then((values: any) => {

                if (dateHourDoEvento) {


                    values.telefone_responsavel = unMask(values.telefone_responsavel);
                    values.data = convertDataBrHoraParaUSHora(dateHourDoEvento)

                    salvarDadosCriar(values);
                } else {
                    notification.error({
                        message: 'Erro',
                        description:
                            'Por favor selecione uma data!',
                    });
                }

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, [dateHourDoEvento]);

    const submitBotaoEditar = useCallback((id: any) => {

        const salvarDadosEditar = async (obj: any) => {
            try {
                setLoading(true);

                await api.put(urlEvento + '/' + id, obj,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Dados editados com sucesso!',
                });

                setLoading(false);
                // alterOpcaoScreen('listar')

            } catch (error) {
                setLoading(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        refForm.current?.validateFields()
            .then((values: any) => {

                if (dateHourDoEvento) {
                    console.log(dateHourDoEvento)
                    values.telefone_responsavel = unMask(values?.telefone_responsavel);
                    values.data = convertDataBrHoraParaUSHora(dateHourDoEvento)
                    salvarDadosEditar(values);
                }

            })
            .catch((errorInfo: any) => {
                console.log(errorInfo)
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, [dateHourDoEvento]);

    //---------------------------------------------------------------------------------------------------

    const abrirComponentModal = useCallback(() => {
        setAbrirModalConvidado(true)
    }, []);

    const fecharComponentModalVeiculos = useCallback(() => {
        setAbrirModalConvidado(false);
        setObjModalConvidado(null)
        buscaDadosTabelaConvidado();
    }, []);


    //setando dados das cidades no selected
    const deletarConvidado = useCallback((idConvidado: string) => {

        const funcBusca = async () => {
            try {
                setLoadingConvidado(true);
                await api.delete(urlEventoConvidado + '/' + idConvidado,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                buscaDadosTabelaConvidado();

            } catch (error) {
                setLoadingConvidado(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'usuarios_dkwdfaiweyi')
                }


            }
        }

        funcBusca();

    }, []);

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            ...useGetColumnSearchProps('nome'),
            width: '12%',
            sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            showSorterTooltip: false,
        },
        {
            title: 'Placa',
            dataIndex: 'placa',
            ...useGetColumnSearchProps('placa'),
            sorter: (a: any, b: any) => {
                return a?.placa?.localeCompare(b?.placa)
            },
            showSorterTooltip: false,
            width: '6%',
            render: (placa: any) => {
                return placa
            }
        },
        {
            title: 'Criado em',
            dataIndex: 'createdAt',
            ...useGetColumnSearchPropsData('createdAt'),
            width: '2%',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.createdAt) {
                    aMonMen = moment(a.createdAt).unix()
                } else {
                    aMonMen = 0
                }
                if (b.createdAt) {
                    bMonMen = moment(b.createdAt).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),
            render: (criado: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Text>
                        {
                            criado ? convertDataUSParaBrasil(criado) : '-'
                        }
                    </Text>
                </div>
            ),
        },
        {
            title: 'Ações',
            dataIndex: 'id',
            // ...getColumnSearchProps('created_at'),
            width: '6%',
            // sorter: (a: any, b: any) => a.created_at.localeCompare(b.created_at),
            render: (idModal: any, record: any) => (
                <>
                    <Row>
                        <Button
                            type="primary"
                            style={{ marginLeft: 5 }}
                            className="AddEventoEConvidado_BotaoEditar_dekchjcvkjhvdc"
                            onClick={() => {
                                setObjModalConvidado(record)

                                setAbrirModalConvidado(true);
                            }}
                        >
                            <EditOutlined />
                        </Button>
                        <Popconfirm
                            title="Deseja realmente excluir convidado?"
                            onConfirm={() => {
                                deletarConvidado(idModal)
                            }}
                            onCancel={() => { }}
                            okText="Sim"
                            cancelText="Não"
                        >
                            <Button
                                type="primary"
                                style={{ marginLeft: 5 }}
                                danger
                            // onClick={() => clickBotaoViewDadosContatoModal(idModal)}
                            >
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    </Row>
                </>
            ),
        },
    ];

    return (
        <>
            <Loading
                loading={loading}
            >
                <Row>
                    <Col {...colFull}>
                        <Title level={4}>
                            {/* <CarOutlined style={{ fontSize: 25, marginRight: 10 }} /> */}
                            {`${estaEditando ? 'Editar' : 'Adicionar'} Evento`}
                        </Title>
                        <div
                            style={{
                                display: 'flex'
                            }}
                        >
                            <Link
                                onClick={() => [
                                    alterOpcaoScreen('listar')
                                ]}
                                style={{
                                    marginRight: 5
                                }}
                            >
                                <Text
                                    type='secondary' underline
                                >Listagem</Text>
                            </Link>

                            <Text
                                style={{
                                    marginRight: 5
                                }} type='secondary'
                            >/</Text>

                            <Text style={{
                                marginRight: 5
                            }}>
                                {
                                    `${estaEditando ? 'Editar' : 'Adicionar'}`
                                }
                            </Text>
                        </div>
                        <Divider />
                    </Col>
                </Row>
                <Form
                    ref={refForm}
                    name="adicionarUsuario"
                    layout="vertical"
                    // onFieldsChange={onFieldsChange}
                    initialValues={{
                        remember: true,
                    }}
                    // onFinish={onFinish}
                    // onFinishFailed={() => { }}
                    autoComplete="off"
                >
                    <Row>
                        <Col {...colInputs}>
                            <Form.Item
                                style={{
                                    margin: 5
                                }}
                                name="responsavel"
                                label="Responsável"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor digite o nome do responsável!',
                                    },
                                ]}
                            >
                                <Input autoComplete="off" placeholder="Nome"
                                    onChange={() => {

                                        const text = refForm.current?.getFieldValue('responsavel')
                                        const first = text.charAt(0).toUpperCase()
                                        const rest = text.substring(1);

                                        refForm.current?.setFieldsValue(
                                            { 'responsavel': first + rest }
                                        )

                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: "Preencha o telefone!",
                                        max: 16
                                    },
                                    () => ({
                                        validator(rule, value) {
                                            return new Promise((resolve: (value?: any) => void, reject) => {
                                                //CUIDADO AO EDITAR PARA PODER ACEITAR MESMO EMAIL
                                                if (value != '' && value != null) {
                                                    value = unMask(value);
                                                    if (value.length >= 10) {
                                                        // buscarCelularSeJaExiste(value).then(valorPromessa => {
                                                        //     if (valorPromessa) {
                                                        //         reject("Celular já existe!");
                                                        //     } else {
                                                        //         resolve();
                                                        //     }
                                                        // }).catch(error => {
                                                        //     resolve();
                                                        // })
                                                        resolve();
                                                    } else {
                                                        reject("Telefone inválido");
                                                    }
                                                } else {
                                                    reject('');
                                                }
                                            });
                                        },
                                    }),
                                ]}
                                name="telefone_responsavel"
                                label="Telefone"
                                style={{ margin: 5 }}
                            >
                                <Input
                                    type="tel"
                                    autoComplete="off"
                                    onChange={(e) => onChangeInputTelefone(e)}
                                    placeholder="(44) 9 9999-9999"
                                />
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                name="data"
                                label="Data"
                                style={{ margin: 5 }}
                            >
                                <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                    <DateTimePicker
                                        value={dateHourDoEvento}
                                        onChange={setDateHourDoEvento}
                                        required={false}
                                        style={{
                                            width: '100%'
                                        }}
                                        okLabel="Ok"
                                        clearLabel="Limpar"
                                        cancelLabel="Cancelar"
                                        clearable
                                        format="dd/MM/yyyy HH:mm"
                                    />
                                </MuiPickersUtilsProvider>
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                style={{
                                    margin: 5
                                }}
                                name="observacoes"
                                label="Observação"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Por favor digite a observação!',
                                    },
                                ]}
                            >
                                <Input.TextArea rows={4} autoComplete="off" placeholder=""
                                    onChange={() => {

                                        const text = refForm.current?.getFieldValue('observacoes')
                                        const first = text.charAt(0).toUpperCase()
                                        const rest = text.substring(1);

                                        refForm.current?.setFieldsValue(
                                            { 'observacoes': first + rest }
                                        )

                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col hidden {...colInputs}>
                            <Form.Item
                                name="empresaId"
                                label="Empresa"
                                style={{ margin: 5 }}
                                hidden
                            >
                                <Input disabled hidden />
                            </Form.Item>
                        </Col>
                        <Col {...colFull}>
                            <div className="botaoSalvarStep" style={{
                                flexDirection: 'row',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                // background: 'red'
                            }}>
                                <Button
                                    // type="primary"
                                    style={{
                                        marginTop: 20,
                                        marginRight: 5
                                    }}
                                    onClick={() => {
                                        alterOpcaoScreen('listar')
                                    }}
                                >
                                    {"Voltar"}
                                </Button>

                                <Button
                                    key="submit"
                                    type="primary"
                                    style={{
                                        marginTop: 20,
                                        marginRight: 5
                                    }}
                                    onClick={() => {
                                        estaEditando ?
                                            submitBotaoEditar(refIdEvento.current ? refIdEvento.current : 'criar')
                                            :
                                            submitBotaoSalvar()
                                    }}
                                >
                                    {"Salvar"}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>

                {
                    estaEditando &&
                    <>
                        <Divider
                            style={{
                                marginTop: 30,
                                marginBottom: 30,
                                backgroundColor: '#2D9CDB'
                            }}
                        />
                        <Title level={4}>
                            {/* <CarOutlined style={{ fontSize: 25, marginRight: 10 }} /> */}
                            {`Lista de convidados`}
                        </Title>
                        <Button
                            type="primary"
                            className="ListEvento_BotaoAdd_efwAIUYGprdfgjbwp"
                            style={{ margin: 2, marginBottom: 5 }}
                            // disabled={!hasSelectedAdd}
                            onClick={() => {
                                abrirComponentModal()
                            }}
                        >
                            Adicionar convidado
                        </Button>
                        <Table
                            style={{
                                // marginTop: 50
                            }}
                            className='marginTopTables'
                            rowKey={(record) => record.id}
                            loading={loadingConvidado}
                            columns={columns} dataSource={dadosTabelaState}
                            scroll={{ x: 650 }}
                            onChange={
                                (pagination, filters, sorter, extra) => {
                                    refContagemDoFiltro.current = extra.currentDataSource
                                }
                            }
                            title={(registros) => {

                                return (
                                    <div
                                        style={{
                                            backgroundColor: theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                                            padding: 10,
                                            margin: -8,
                                            marginBottom: 0.5
                                        }}
                                    >
                                        <Text>
                                            {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                            {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                                dadosTabelaState.length :
                                                refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                                        </Text>
                                    </div>
                                )
                            }}
                            footer={(registros) => {
                                return (
                                    <div
                                        style={{
                                            textAlign: 'end'
                                        }}
                                    >
                                        <Text>
                                            {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                            {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                                dadosTabelaState.length :
                                                refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                                        </Text>
                                    </div>
                                )
                            }}
                            size="small"
                            pagination={{
                                locale: { items_per_page: '' },
                                showSizeChanger: true,
                                defaultPageSize: 50,
                                position: ['topRight', 'bottomRight'],
                                pageSizeOptions: ['10', '20', '50', '100']
                            }}
                            locale={locale.Table}
                        />
                        <Col {...colFull}>
                            <div className="botaoSalvarStep" style={{
                                flexDirection: 'row',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                // background: 'red'
                            }}>
                                <Button
                                    // type="primary"
                                    style={{
                                        // marginTop: 20,
                                        // marginRight: 5
                                    }}
                                    onClick={() => {
                                        alterOpcaoScreen('listar')
                                    }}
                                >
                                    {"Voltar"}
                                </Button>
                            </div>
                        </Col>
                        <ModalConvidadosAddOrUpdate
                            visible={abrirModalConvidado}
                            eventoId={refIdEvento.current}
                            objConvidados={objModalConvidado}
                            setFecharModal={fecharComponentModalVeiculos}
                        />
                    </>
                }
            </Loading>
        </>
    );
}
