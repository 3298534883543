import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Table, Typography, notification, Button, Popconfirm } from 'antd';
import {
    DeleteOutlined
} from '@ant-design/icons';
import moment from 'moment';

import { convertDataUSParaBrasil, convertDataUSParaBrasilEHora } from '../../../../../../services/Conversores';
import { useNavigate } from 'react-router-dom';
import locale from 'antd/es/locale/pt_BR';
import { urlUsuariosUsuarioEmpresas, urlUsuariosUsuarioGestor, urlEmpresasEmpresaUsuarioDesvincular, urlUsuariosUsuario } from '../../../../../../services/urls';
import { EmitirErro } from '../../../../../../services/EmitirErro';
import { SairDoSistema } from '../../../../../../services/LStorage/SairSistema';
import { api } from '../../../../../../services/apiAxios';
import { getToken } from '../../../../../../services/LStorage/token';
import { useGetColumnSearchProps } from '../../../../../../hooks/FilterTable/string';
import { useGetColumnSearchPropsData } from '../../../../../../hooks/FilterTable/date';
import { GlobalContext } from '../../../../../../contexts/globalContext';

import './style.css';


const { Text } = Typography;

// const largura = window.innerWidth;
// const altura = window.innerHeight;
interface IntefEmpresas {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "ativo": boolean,
    "nome": string,
    "cnpj": string,
    "telefone": string,
    "bairro": string | null,
    "cep": string | null,
    "cidade": string | null,
    "logradouro": string | null,
    "numero": string | null,
    "uf": string | null,
    "deletedAt": string | null
}

type interfOpcaoScreen = 'listar' | 'addUpdate';
interface IntefListUsersAtivosPermissao {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "nome": string,
    "descricao": string
}
interface IntefListUsersAtivosRegra {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "nome": string,
    "descricao": string,
    "permissoes": Array<IntefListUsersAtivosPermissao>
}
interface IntefDadosTable {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "ativo": boolean,
    "nome": string,
    "email": string,
    "senha": string,
    empresaUsuarios: Array<{
        empresaId: string
        gestor: boolean
        usuarioId: string
    }>,
    "lastAccess": string | null,
    "regras": Array<IntefListUsersAtivosRegra>,
    "permissoes": Array<IntefListUsersAtivosPermissao>,
    "deletedAt": null | string
}

interface interPros {
    empresaSelecionado?: IntefEmpresas,
    alterOpcaoScreen: (opcao: interfOpcaoScreen, id?: string) => void;
}

export const UsuariosListagem = ({ empresaSelecionado, alterOpcaoScreen }: interPros) => {

    const navigate = useNavigate();

    const { theme } = useContext(GlobalContext);

    const refContagemDoFiltro = React.useRef<Array<any>>([]);
    const refDadosTabelaSelect = React.useRef<Array<IntefDadosTable>>([]);
    const refUsuarioSelecionadoTabela = React.useRef<IntefDadosTable | null>(null);
    const refEmpresaSelecionada = React.useRef<IntefEmpresas | null>(null);

    const [dadosTabelaState, setDadosTabelaState] = useState<Array<IntefDadosTable>>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<string | number>>([]);
    const [hasSelectedEdit, setHasSelectedEdit] = useState(false);


    const [loadingScreen, setLoadingScreen] = useState(false);

    //setando dados das cidades no selected
    const buscaDadosList = useCallback((id?: string) => {

        setSelectedRowKeys([])
        setHasSelectedEdit(false)
        refUsuarioSelecionadoTabela.current = null

        const funcBusca = async () => {
            try {
                setLoadingScreen(true);
                let result = await api.get(urlUsuariosUsuarioEmpresas + '/' + id,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                refDadosTabelaSelect.current = result.data
                setDadosTabelaState(result.data);

                setLoadingScreen(false);

            } catch (error) {
                setLoadingScreen(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'usuarios_dkwdfaiweyi')
                }


            }
        }

        funcBusca();

    }, []);

    useEffect(() => {

        if (empresaSelecionado) buscaDadosList(empresaSelecionado.id);

    }, [empresaSelecionado]);

    const funcSelecionaUsuarioClicado = useCallback((selectedRowKeys: any): void => {

        if (selectedRowKeys.length == 1) {

            let objSelecionado: any = {}
            refDadosTabelaSelect.current.forEach((dados): void => {
                if (dados.id === selectedRowKeys[0]) {
                    objSelecionado = dados
                }
            })

            refUsuarioSelecionadoTabela.current = objSelecionado;

        }
    }, []);

    // click na linha da tabela de listagem de usuario
    const onclickTable = useCallback((record: any, row: any): void => {

        if (selectedRowKeys.includes(record.id)) {
            let filter = selectedRowKeys.filter((val) => {
                return val != record.id
            })
            if (filter.length === 1) {
                setSelectedRowKeys(filter);
                setHasSelectedEdit(true);
                funcSelecionaUsuarioClicado(filter);
            } else if (filter.length === 0) {
                setSelectedRowKeys(filter);
                setHasSelectedEdit(false);
            } else {
                setSelectedRowKeys(filter);
            }

        } else {
            if (selectedRowKeys.length == 0) {
                setSelectedRowKeys([record.id]);
                setHasSelectedEdit(true);
                funcSelecionaUsuarioClicado([record.id]);
            } else {
                let teste = selectedRowKeys.concat(record.id);
                setSelectedRowKeys(teste);
                setHasSelectedEdit(false);
            }
        }

    }, [selectedRowKeys, dadosTabelaState]);

    //checkbox da tabela de listagem
    // const onSelectChange = useCallback((selectedRowKeys: any): void => {
    const onSelectChange = useCallback((selectedRowKeys: Array<string | number>, selectedRows: Array<any>): void => {
        if (selectedRowKeys.length > 1) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedEdit(false);
        } else if (selectedRowKeys.length === 0) {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedEdit(false);
        } else {
            setSelectedRowKeys(selectedRowKeys);
            setHasSelectedEdit(true);
            funcSelecionaUsuarioClicado(selectedRowKeys);
        }

    }, [selectedRowKeys, dadosTabelaState]);

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: '1%'
    };

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            ...useGetColumnSearchProps('nome'),
            width: '10%',
            sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            showSorterTooltip: false,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            ...useGetColumnSearchProps('email'),
            width: '10%',
            sorter: (a: any, b: any) => a.email.localeCompare(b.email),
            showSorterTooltip: false,
        },
        {
            title: 'Último acesso',
            dataIndex: 'lastAccess',
            ...useGetColumnSearchPropsData('lastAccess'),
            width: '2%',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.lastAccess) {
                    aMonMen = moment(a.lastAccess).unix()
                } else {
                    aMonMen = 0
                }
                if (b.lastAccess) {
                    bMonMen = moment(b.lastAccess).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),
            render: (criado: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: 8
                    }}
                >
                    <Text>
                        {
                            criado ? convertDataUSParaBrasilEHora(criado) : '-'
                        }
                    </Text>
                </div>
            ),
        },
        {
            title: 'Criado em',
            dataIndex: 'createdAt',
            ...useGetColumnSearchPropsData('createdAt'),
            width: '2%',
            sorter: (a: any, b: any) => {

                let aMonMen: any = null
                let bMonMen: any = null
                if (a.createdAt) {
                    aMonMen = moment(a.createdAt).unix()
                } else {
                    aMonMen = 0
                }
                if (b.createdAt) {
                    bMonMen = moment(b.createdAt).unix()
                } else {
                    bMonMen = 0
                }

                return aMonMen - bMonMen
            },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),
            render: (criado: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: 8
                    }}
                >
                    <Text>
                        {
                            criado ? convertDataUSParaBrasil(criado) : '-'
                        }
                    </Text>
                </div>
            ),
        },
    ];


    const clickBotaoEditarUsuario = useCallback((): void => {
        if (selectedRowKeys.length == 1) {

            let objSelecionado: any = {}
            dadosTabelaState.forEach((dados): void => {
                if (dados.id === selectedRowKeys[0]) {
                    objSelecionado = dados
                }
            })

            localStorage.setItem('@SisPortaria:userEditForEmpresa', JSON.stringify(objSelecionado));

            alterOpcaoScreen('addUpdate', selectedRowKeys[0].toString())


        }
    }, [selectedRowKeys, dadosTabelaState]);

    const clickBotaoAddOuRemoverUsuarioGestor = useCallback((): void => {
        if (selectedRowKeys.length == 1) {

            const funcBusca = async () => {
                try {
                    setLoadingScreen(true);

                    let obj = {}

                    if (refUsuarioSelecionadoTabela.current && empresaSelecionado) {
                        obj = {
                            "usuarioId": refUsuarioSelecionadoTabela.current.id,
                            "empresaId": empresaSelecionado.id,
                            "gestor": !refUsuarioSelecionadoTabela.current.empresaUsuarios[0].gestor
                        }


                        await api.patch(urlUsuariosUsuarioGestor, obj,
                            {
                                headers: {
                                    'Authorization': 'Bearer ' + getToken()
                                }
                            });

                        buscaDadosList(empresaSelecionado.id)
                    }
                    setLoadingScreen(false);

                } catch (error) {
                    setLoadingScreen(false);
                    let msgErro: any = (error as Error);

                    if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                        SairDoSistema();
                        notification.error({
                            message: 'Sessão expirada',
                            description:
                                'Sua sessão expirou, realize o login novamente!',
                        });
                        navigate('/');
                    } else {
                        EmitirErro(error, true, 'usuarios_dkwdfaiweyi')
                    }


                }
            }

            funcBusca();

        }
    }, [selectedRowKeys, empresaSelecionado]);

    const clickBotaoDesvincularUsuario = useCallback((): void => {
        if (selectedRowKeys.length == 1) {

            const funcBusca = async () => {
                try {
                    setLoadingScreen(true);

                    let obj = {}

                    if (refUsuarioSelecionadoTabela.current && empresaSelecionado) {
                        obj = {
                            "usuarioId": refUsuarioSelecionadoTabela.current.id,
                            "empresaId": empresaSelecionado.id,
                        }

                        await api.delete(urlEmpresasEmpresaUsuarioDesvincular,
                            {
                                headers: {
                                    'Authorization': 'Bearer ' + getToken()
                                },
                                data: obj
                            });

                        buscaDadosList(empresaSelecionado.id)
                    }
                    setLoadingScreen(false);

                } catch (error) {
                    setLoadingScreen(false);
                    let msgErro: any = (error as Error);

                    if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                        SairDoSistema();
                        notification.error({
                            message: 'Sessão expirada',
                            description:
                                'Sua sessão expirou, realize o login novamente!',
                        });
                        navigate('/');
                    } else {
                        EmitirErro(error, true, 'usuarios_dkwdfaiweyi')
                    }


                }
            }

            funcBusca();

        }
    }, [selectedRowKeys, empresaSelecionado]);

    const clickBotaoDELETARUsuario = useCallback((): void => {
        if (selectedRowKeys.length == 1) {

            const funcBusca = async () => {
                try {
                    setLoadingScreen(true);

                    if (refUsuarioSelecionadoTabela.current && empresaSelecionado) {

                        await api.delete(urlUsuariosUsuario + '/' + refUsuarioSelecionadoTabela.current.id,
                            {
                                headers: {
                                    'Authorization': 'Bearer ' + getToken()
                                }
                            });

                        buscaDadosList(empresaSelecionado.id)
                    }
                    setLoadingScreen(false);

                } catch (error) {
                    setLoadingScreen(false);
                    let msgErro: any = (error as Error);

                    if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                        SairDoSistema();
                        notification.error({
                            message: 'Sessão expirada',
                            description:
                                'Sua sessão expirou, realize o login novamente!',
                        });
                        navigate('/');
                    } else {
                        EmitirErro(error, true, 'usuarios_dkwdfaiweyi')
                    }


                }
            }

            funcBusca();

        }
    }, [selectedRowKeys, empresaSelecionado]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    // justifyContent: 'flex-end',
                    // justifyContent: 'flex-end'
                    marginBottom: 15
                }}
            >
                <Button
                    type="primary"
                    className="ListUsuarios_BotaoAdd_gvorehbgo"
                    style={{ margin: 2 }}
                    // disabled={!hasSelectedAdd}
                    onClick={() => {
                        let dadosLocalStorage: string | null = localStorage.getItem('@SisPortaria:userEditForEmpresa');
                        let localUserData: any = null;
                        if (typeof dadosLocalStorage == 'string') {
                            localUserData = JSON.parse(dadosLocalStorage);
                        }

                        if (localUserData) {
                            localStorage.removeItem('@SisPortaria:userEditForEmpresa');
                        }

                        alterOpcaoScreen('addUpdate')
                    }}
                >
                    Adicionar
                </Button>
                <Button
                    type="default"
                    style={{ margin: 2 }}
                    className="ListUsuarios_BotaoEditar_ewfubwpoeg"
                    onClick={clickBotaoEditarUsuario}
                    disabled={!hasSelectedEdit}
                >
                    Editar
                </Button>

                <Button
                    type="primary"
                    style={{ margin: 2 }}
                    className={
                        refUsuarioSelecionadoTabela.current != null && refUsuarioSelecionadoTabela.current.empresaUsuarios[0].gestor ?
                            "ListUsuarios_BotaoRemoverGestor_gvorehbgo" :
                            "ListUsuarios_BotaoAdd_gvorehbgo"}
                    onClick={clickBotaoAddOuRemoverUsuarioGestor}
                    disabled={!hasSelectedEdit}
                    danger
                >
                    {refUsuarioSelecionadoTabela.current != null && refUsuarioSelecionadoTabela.current.empresaUsuarios[0].gestor ? 'Remover Gestor' : 'Add Gestor'}
                </Button>
                <Button
                    type="primary"
                    style={{ margin: 2 }}
                    onClick={clickBotaoDesvincularUsuario}
                    disabled={!hasSelectedEdit}
                    danger
                >
                    {'Desvincular usuário'}
                </Button>
                {
                    hasSelectedEdit ?
                        <Popconfirm
                            key="pop"
                            placement="topRight"
                            title={() => (
                                <div>
                                    <Text>
                                        {`Deseja realmente excluir usuário?`}
                                    </Text>
                                </div>
                            )}
                            onConfirm={() => clickBotaoDELETARUsuario()}
                            onCancel={() => { }}
                            okText="Sim"
                            cancelText="Não"
                        >
                            <Button
                                type="primary"
                                style={{ margin: 2 }}
                                disabled={!hasSelectedEdit}
                                danger
                            >
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                        :
                        <Button
                            type="primary"
                            style={{ margin: 2 }}
                            disabled={!hasSelectedEdit}
                            danger
                        >
                            <DeleteOutlined />
                        </Button>
                }
            </div>
            <Table
                style={{
                    // marginTop: 50
                }}
                className='marginTopTablesListUsuarios'
                rowKey={(record) => record.id}
                loading={loadingScreen}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => { onclickTable(record, rowIndex) }
                    };
                }}
                rowClassName={(record, index) => record?.empresaUsuarios[0]?.gestor ? 'table-row-gestor' : 'table-row-normal'}
                rowSelection={rowSelection}
                columns={columns} dataSource={dadosTabelaState}
                scroll={{ x: 650 }}
                onChange={
                    (pagination, filters, sorter, extra) => {
                        refContagemDoFiltro.current = extra.currentDataSource
                    }
                }
                title={(registros) => {

                    return (
                        <div
                            style={{
                                backgroundColor: theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                                padding: 10,
                                margin: -8,
                                marginBottom: 0.5
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                    dadosTabelaState.length :
                                    refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                            </Text>
                        </div>
                    )
                }}
                footer={(registros) => {
                    return (
                        <div
                            style={{
                                textAlign: 'end'
                            }}
                        >
                            <Text>
                                {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                    dadosTabelaState.length :
                                    refContagemDoFiltro.current.length} num total de ${dadosTabelaState.length} `}
                            </Text>
                        </div>
                    )
                }}
                size="small"
                pagination={{
                    locale: { items_per_page: '' },
                    showSizeChanger: true,
                    defaultPageSize: 50,
                    position: ['topRight', 'bottomRight'],
                    pageSizeOptions: ['10', '20', '50', '100']
                }}
                locale={locale.Table}
            />

        </>
    );
}
