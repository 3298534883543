import { useCallback, useState, useRef, useContext, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Spin, notification } from 'antd';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { api } from '../../../../services/apiAxios';

// @ts-ignore
import ImagemLogo from './../../../../assets/imagens/logo_final.png';
// @ts-ignore
import ImagemBGLogo from './../../../../assets/imagens/loginBG.png';

import { urlUsuariosUsuarioRecuperacao } from './../../../../services/urls';
import { GlobalContext } from './../../../../contexts/globalContext';
import { EmitirErro } from './../../../../services/EmitirErro';
import { Loading } from '../../../../components/Loading';
// import { VerificaRota } from '../../../../services/VerificaSePodeAcessar';

const RecuperarSenhaNovaSenha = () => {
    const navigate = useNavigate();
    const formRef = useRef<any>(null);

    let { codigo } = useParams();

    const { theme } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);
    const [obrigatorioConfirm, setObrigatorioConfirm] = useState(false);


    const onSubmit = useCallback((): void => {
        const buscaDeDados = async (dados: any) => {
            try {

                setLoading(true);

                let obj = {
                    "codigo": codigo,
                    "senha": dados.senha
                }

                let result = await api.post(urlUsuariosUsuarioRecuperacao, obj);

                formRef.current?.resetFields();

                notification.success({
                    message: 'Sucesso',
                    description: 'Sua senha foi redefinida!',
                    duration: 6,
                });
                navigate('/');


            } catch (error) {
                setLoading(false);

                EmitirErro(error, true, 'newpass_dkwdfaiweyi')
            }
        }

        formRef.current?.validateFields()
            .then((values: any) => {
                buscaDeDados(values);
            })
            .catch(() => {
                // .catch((errorInfo: any) => {

                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, []);

    return (
        <>
            <Loading
                loading={loading}
                paddingLayout={false}
            />
            <Row gutter={24} style={{ maxHeight: "100vh", margin: 0 }} align="middle">

                <Col
                    className='fotoFundoRecuperarSenha'
                    style={{
                        filter: theme === 'light' ? 'grayscale(0%)' : 'grayscale(100%)',
                        display: 'flex',
                        padding: 0,
                        flexDirection: 'column',
                        // alignItems: 'center',
                        justifyContent: 'flex-end',
                        backgroundColor: '#163561'

                    }} flex={3}
                >
                    <img style={{
                        width: '100%',
                    }} src={ImagemBGLogo}></img>
                </Col>

                <Col flex={1} style={{
                    height: '100vh',
                    // backgroundColor: '#243463',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>

                    <Form
                        name="formRecuperarSenhaNovaSenha"
                        ref={formRef}
                        initialValues={{
                            remember: true,
                        }}
                        style={{
                            width: '100%'
                        }}
                        layout="vertical"
                        onFinish={onSubmit}
                    // onFinishFailed={onFinishFailed}
                    >
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <img style={{
                                width: '200px',
                                // margin: '20px'
                            }} src={ImagemLogo}></img>
                            <h1
                                style={{
                                    fontFamily: 'Orbitron',
                                    fontWeight: '800'
                                }}
                            >iControl Portaria</h1>

                            <div
                                style={{
                                    marginTop: 10,
                                    marginBottom: -15
                                }}
                            >
                                <p>Por favor redefina sua senha!</p>
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            // alignItems: 'center',
                            // justifyContent: 'center'
                            margin: 30
                        }}>
                            <Form.Item
                                style={{
                                    marginBottom: 10
                                }}
                                name="senha"
                                label="Nova Senha"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor digite sua senha!'
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (value?.length >= 1) {
                                                setObrigatorioConfirm(true);
                                            }
                                            if (value?.length == 0) {
                                                setObrigatorioConfirm(false);
                                            }

                                            if (value.length >= 8) {
                                                return Promise.resolve();
                                            } else {
                                                return Promise.reject('A senha precisa conter 8 digitos no minimo!');
                                            }

                                        },
                                    }),
                                ]}
                            >
                                <Input.Password autoComplete="off" placeholder="Por favor digite nova senha!" />
                            </Form.Item>
                            <Form.Item
                                style={{
                                    marginBottom: 20
                                }}
                                name="confimarSenha"
                                label="Confirmação de Nova Senha"
                                rules={[
                                    {
                                        required: obrigatorioConfirm,
                                        message: 'Por favor confirme sua senha!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('senha') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('Senha deve estar igual a anterior!');
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password autoComplete="off" placeholder="Por favor digite novamente sua senha!" />
                            </Form.Item>

                            <Button
                                type="primary"
                                style={{ width: "100%" }}
                                htmlType='submit'
                            // onKeyDown={onPreesTecla}
                            // onClick={onSubmitClick}
                            >
                                Enviar
                            </Button>
                            {/* <Button
                                type="link"
                                style={{ width: "100%", marginTop: 10 }}
                                htmlType='button'
                                // onKeyDown={onPreesTecla}
                                onClick={() => {
                                    navigate('/')
                                }}
                            >
                                {`Voltar`}
                            </Button> */}
                        </div>
                    </Form>
                </Col>
            </Row>
        </>
    );
};

export default RecuperarSenhaNovaSenha;
