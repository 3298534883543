import React, { useEffect, useCallback, useState, useContext } from 'react';
import { Layout, Menu, Button, Dropdown, Drawer, Radio, Typography, Avatar } from 'antd';
import type { MenuProps, MenuTheme } from 'antd';
import {
    MenuOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    RetweetOutlined,
    FileTextOutlined,
    LogoutOutlined,
    TeamOutlined,
    AlignCenterOutlined,
    CarOutlined
} from '@ant-design/icons';
import Icon from '@ant-design/icons';
import {
    FaTruck, FaCog, FaUnlockAlt, FaTv, FaBuilding,
    FaTruckLoading
} from 'react-icons/fa';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../contexts/globalContext';
// import { GlobalPrimaryContext } from '../../context/GlobalPrimaryContext';

// @ts-ignore
import ImagemLogo from './../../assets/imagens/logo_final.png';

import { SairDoSistema } from './../../services/LStorage/SairSistema';
import { getIdUsuario, getUsuario } from '../../services/LStorage/UsuarioERefresh';
import { getEmpresaSelecionada } from '../../services/LStorage/EmpresaSelecionada';
import { validacaoPermissao } from '../../services/VerificaSePodeAcessar';


const { Header, Sider, Content, Footer } = Layout;
const { Text } = Typography;
// const { SubMenu } = Menu;
const { Item } = Menu;

// const IconHand = () => <Icon style={{ fontSize: 17 }} component={FaSitemap} ></Icon>
const IconBuilding = (props: any) => (<Icon {...props} component={FaBuilding} ></Icon>);
const IconCogs = (props: any) => (<Icon {...props} component={FaCog} ></Icon>);
const IconTv = (props: any) => (<Icon {...props} component={FaTv} ></Icon>);
const IconFaTruck = (props: any) => (<Icon {...props} component={FaTruck} ></Icon>);
// const UnlokProcessoJuridico = (props: any) => <Icon {...props} component={FaUnlockAlt} ></Icon>

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    permissao: boolean,
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    let disabled = key === 'selecionarEmpresa1';
    let popupClassName = 'chinesMenuPopUp';
    return {
        key,
        disabled,
        popupClassName,
        icon,
        children,
        label,
        type,
        permissao: permissao.toString()
    } as MenuItem;
}

export default function LayoutDashboard({ children }: any) {

    const navigate = useNavigate();
    const { theme, updateTheme } = useContext(GlobalContext);

    const [collapsed, setCollapsed] = useState(false);
    const [collapsedWidth, setCollapsedWidth] = useState<number>(77);
    const [visibleDrawer, setvisibleDrawer] = useState(false);
    const [visibleDrawerColor, setVisibleDrawerColor] = useState(false);
    const [posicaoMenu, setPosicaoMenu] = useState<Array<string>>(['1']);
    const [abrirMenuLateral, setAbrirMenuLateral] = useState<Array<string>>(['']);
    const [nomeUsuarioLogado, setNomeUsuarioLogado] = useState('');
    const [itemsMenuConfiguracaoState, setItemsMenuConfiguracaoState] = useState<MenuItem[]>([]);
    const [itemsMenuPainelState, setItemsMenuPainelState] = useState<MenuItem[]>([]);

    const onCloseDrawerColor = useCallback((): void => {
        setVisibleDrawerColor(false);
    }, []);

    const onAbrirDrawerColor = useCallback((): void => {
        setVisibleDrawerColor(true);
    }, []);

    const onchangeRadioTheme = useCallback((value: any): void => {

        updateTheme(value.target.value)

        window.location.reload();
    }, []);

    // useEffect(() => {
    //     // let arrayUrl = window.location.pathname.split("/");


    // }, []);

    useEffect(() => {

        let dataUsuario = getUsuario();
        if (dataUsuario) {
            let arrayUrl = dataUsuario.nome.split(" ")
            setNomeUsuarioLogado(arrayUrl[0])
        }

        if (typeof window !== 'undefined') {
            if (window.innerWidth < 768) {
                setCollapsedWidth(0);
            }
            let arrayUrl = window.location.pathname.split("/");

            switch (window.location.pathname) {
                case "/dashboard":
                    setPosicaoMenu(['dashboard1'])
                    setAbrirMenuLateral([''])
                    break;
                case "/empresas":
                    setPosicaoMenu(['empresasCrud'])
                    setAbrirMenuLateral([''])
                    break;
                case "/veiculos":
                    setPosicaoMenu(['veiculosCrud'])
                    setAbrirMenuLateral([''])
                    break;
                case "/colaboradores":
                    setPosicaoMenu(['colaboradoresCrud'])
                    setAbrirMenuLateral([''])
                    break;
                case "/relatorios/veiculos-proprios":
                    setPosicaoMenu(['relatVeiculosProprios'])
                    setAbrirMenuLateral(['relatoriosList'])
                    break;
                case "/relatorios/ata":
                    setPosicaoMenu(['relatAtaList'])
                    setAbrirMenuLateral(['relatoriosList'])
                    break;
                case "/relatorios/ata/" + arrayUrl[3]:
                    setPosicaoMenu(['relatAtaList'])
                    setAbrirMenuLateral(['relatoriosList'])
                    break;
                case "/relatorios/evento-convidado":
                    setPosicaoMenu(['relatConvdadosEventList'])
                    setAbrirMenuLateral(['relatoriosList'])
                    break;
                case "/relatorios/visitante":
                    setPosicaoMenu(['relatVisitante'])
                    setAbrirMenuLateral(['relatoriosList'])
                    break;
                case "/relatorios/veiculo-terceiro":
                    setPosicaoMenu(['relatVeiculoTerceiro'])
                    setAbrirMenuLateral(['relatoriosList'])
                    break;
                case "/relatorios/carro-forte":
                    setPosicaoMenu(['relatCarroForte'])
                    setAbrirMenuLateral(['relatoriosList'])
                    break;
                // case "/relatorios/movimentos-veiculos":
                //     setPosicaoMenu(['relatMovimentosVeiculos'])
                //     setAbrirMenuLateral(['relatoriosList'])
                //     break;
                case "/listagemMunicipios":
                    setPosicaoMenu(['4'])
                    setAbrirMenuLateral([''])
                    break;

                default:
                    setPosicaoMenu([''])
                    setAbrirMenuLateral([''])
                    break;
            }
        }

        const itemsMenuConfiguracao: MenuItem[] = [
            getItem(true, 'Editar usuário', 'perfil1', <UserOutlined />),
            getItem(validacaoPermissao(['admin']), 'Usuarios', 'usuarios1', <UserOutlined />),
            getItem(true, 'Tema', 'tema1', <IconTv />),
            getItem(validacaoPermissao(['admin']), 'Configurações', 'configuracoes1', <IconCogs />),
            getItem(true, 'Trocar empresa', 'alterarempresa', <RetweetOutlined />),
            getItem(true, 'Sair', 'sair1', <LogoutOutlined />),

        ];

        let lsEmpresaSelecionada = getEmpresaSelecionada();

        const itemsMenuPainel: MenuItem[] = [
            getItem(true, lsEmpresaSelecionada ? lsEmpresaSelecionada.nome : '', 'selecionarEmpresa1', <IconBuilding />),
            getItem(true, 'Dashboard', 'dashboard1', <AlignCenterOutlined />),
            getItem(true, 'Empresa', 'empresasCrud', <IconBuilding />),
            getItem(true, 'Relatórios', 'relatoriosList', <AlignCenterOutlined />, [
                getItem(true, 'Veículos Próprios', 'relatVeiculosProprios', <IconFaTruck />),
                getItem(true, 'Ata', 'relatAtaList', <FileTextOutlined />),
                getItem(true, 'Convidados Evento', 'relatConvdadosEventList', <TeamOutlined />),
                getItem(true, 'Visitante', 'relatVisitante', <TeamOutlined />),
                getItem(true, 'Veículo Terceiro', 'relatVeiculoTerceiro', <IconFaTruck />),
                getItem(true, 'Carro Forte', 'relatCarroForte', <CarOutlined />),
                // getItem(true, 'Movimentos Por Veículo', 'relatMovimentosVeiculos', <AlignCenterOutlined />)
            ]),
            // getItem(true, 'Veiculos', 'veiculosCrud', <CarOutlined />),
            // getItem(true, 'Colaboradores', 'colaboradoresCrud', <TeamOutlined />),
        ];

        const menuConfig = itemsMenuConfiguracao.filter((element: any) => element?.permissao === 'true')
        setItemsMenuConfiguracaoState(menuConfig)
        const menuPainel = itemsMenuPainel.filter((element: any) => element?.permissao === 'true')
        setItemsMenuPainelState(menuPainel)

    }, []);

    // const clickMenuUsuarios = useCallback((): void => {
    //     let arrayUrl = window.location.pathname.split("/");

    //     if (arrayUrl[1] === 'usuarios' && !arrayUrl[2]) {

    //     } else {
    //         // navigate("/usuarios");
    //     }
    //     setvisibleDrawer(false);
    // }, [])

    // const clickMenuMeterial = useCallback((): void => {
    //     navigate("/admin/materialui");
    //     setvisibleDrawer(false);
    // }, []);

    // const clickMenuDashboard = useCallback((): void => {
    //     navigate("/dashboard");
    //     setvisibleDrawer(false);
    // }, []);

    const toggle = useCallback((): void => {
        if (collapsedWidth === 0) {
            setvisibleDrawer(!visibleDrawer);
        } else {
            setCollapsed(!collapsed);
        }
    }, [collapsedWidth, visibleDrawer, collapsed]);

    const onCollapse = useCallback((collapsedVal: boolean): void => {
        if (!collapsed) {
            setCollapsed(collapsedVal);
            setCollapsedWidth(77);
        } else {
            setCollapsed(collapsedVal);
            setCollapsedWidth(0);
        }
    }, [collapsed, collapsedWidth]);

    const onCloseDrawer = useCallback((): void => {
        setvisibleDrawer(false);
    }, []);

    const clickMenuDireitoUsuarios = useCallback((): void => {
        navigate("/usuarios");
    }, []);

    const clickMenuPerfilUsuario = useCallback((): void => {
        let id = getIdUsuario();
        navigate("/perfil/" + id);
    }, []);

    const clickMenuDireitoSair = useCallback((): void => {
        SairDoSistema();
        navigate("/");
        // localStorage.removeItem('@GovFacil:token');
        // dispatch(LoginActions.loginAlterSingOut());
    }, []);

    const clickMenuConfiguracoes = useCallback((): void => {
        navigate("/configuracoes");
    }, []);

    const menuPerfil = useCallback(() => (
        <Menu items={itemsMenuConfiguracaoState} onClick={onClickMenuConfiguracoes} />
    ), [itemsMenuConfiguracaoState]);


    const clickNavigateMenuLateral = useCallback((rota: string): void => {
        navigate(rota);
        setvisibleDrawer(false);
    }, []);

    const clickMenuPrinciopal = useCallback(({ key }: any): void => {


        switch (key) {
            case 'selecionarEmpresa1':
                // clickNavigateMenuLateral("/selecionar-empresa");
                break;
            case 'dashboard1':
                setPosicaoMenu(key)
                clickNavigateMenuLateral("/dashboard");
                break;
            case 'empresasCrud':
                setPosicaoMenu(key)
                clickNavigateMenuLateral("/empresas");
                break;
            case 'veiculosCrud':
                setPosicaoMenu(key)
                clickNavigateMenuLateral("/veiculos");
                break;
            case 'colaboradoresCrud':
                setPosicaoMenu(key)
                clickNavigateMenuLateral("/colaboradores");
                break;
            case 'relatVeiculosProprios':
                setPosicaoMenu(key)
                clickNavigateMenuLateral("/relatorios/veiculos-proprios");
                break;
            case 'relatAtaList':
                setPosicaoMenu(key)
                clickNavigateMenuLateral("/relatorios/ata");
                break;
            case 'relatConvdadosEventList':
                setPosicaoMenu(key)
                clickNavigateMenuLateral("/relatorios/evento-convidado");
                break;
            case 'relatVisitante':
                setPosicaoMenu(key)
                clickNavigateMenuLateral("/relatorios/visitante");
                break;
            case 'relatVeiculoTerceiro':
                setPosicaoMenu(key)
                clickNavigateMenuLateral("/relatorios/veiculo-terceiro");
                break;
            case 'relatCarroForte':
                setPosicaoMenu(key)
                clickNavigateMenuLateral("/relatorios/carro-forte");
                break;
            // case 'relatMovimentosVeiculos':
            //     clickNavigateMenuLateral("/relatorios/movimentos-veiculos");
            //     break;

            default:
                break;
        }
    }, []);

    const onClickMenuConfiguracoes = useCallback((e: any): void => {
        switch (e.key) {
            case 'perfil1':
                clickMenuPerfilUsuario();
                break;
            case 'usuarios1':
                clickMenuDireitoUsuarios();
                break;
            case 'tema1':
                onAbrirDrawerColor();
                break;
            case 'configuracoes1':
                clickMenuConfiguracoes();
                break;
            case 'alterarempresa':
                clickNavigateMenuLateral("/selecionar-empresa");
                break;
            case 'sair1':
                clickMenuDireitoSair();
                break;

            default:
                break;
        }

    }, []);


    return (
        <>
            <Drawer
                placement="left"
                closable={false}
                onClose={onCloseDrawer}
                visible={visibleDrawer}
                key="left"
                bodyStyle={{ backgroundColor: theme === 'light' ? '#001529' : '#141414' }}
            // bodyStyle={{background:'red'}}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginLeft: -20,
                        marginTop: -20,
                        marginRight: -20,
                    }}
                >
                    <img
                        style={{
                            width: 60,
                            // marginTop: -20,
                            // marginLeft: -20,
                            // marginBottom: 5
                        }}
                        src={ImagemLogo}
                    />
                    <h1
                        style={{
                            color: '#fff',
                            fontFamily: 'Orbitron',
                            fontWeight: '800',
                            fontSize: 20
                        }}>iControl Portaria</h1>
                </div>
                <Menu
                    mode="inline"
                    onClick={position => { clickMenuPrinciopal(position) }}
                    defaultSelectedKeys={['1']}
                    selectedKeys={posicaoMenu}
                    openKeys={abrirMenuLateral}
                    forceSubMenuRender={false}
                    triggerSubMenuAction="click"
                    onOpenChange={(openKeys) => setAbrirMenuLateral(openKeys)}
                    className="DraerwMenuPrinmcipal"
                    theme="dark"
                    style={{
                        marginLeft: -24,
                        marginRight: -24,
                        // padding: 0
                    }}
                    items={itemsMenuPainelState}
                />
            </Drawer>
            <Layout style={{ minHeight: '100vh' }}>
                <Sider
                    width={280}
                    collapsedWidth={collapsedWidth}
                    breakpoint="md"
                    onBreakpoint={broken => {
                        onCollapse(broken);
                    }}
                    collapsed={collapsed}
                    style={{
                        overflow: 'auto',
                        height: '100vh',
                        position: 'fixed',
                        left: 0,
                    }}
                >
                    {collapsed ?
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <img
                                style={{
                                    width: 50,
                                    // marginTop: -20,
                                    // marginLeft: -20,
                                    // marginBottom: 5
                                }}
                                src={ImagemLogo}
                            />
                        </div>
                        :
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                margin: 5,
                                alignItems: 'center',
                            }}
                        >
                            <img
                                style={{
                                    width: 50,
                                    // marginTop: -20,
                                    // marginLeft: -20,
                                    // marginBottom: 5
                                }}
                                src={ImagemLogo}
                            />
                            <h1 style={{
                                color: '#fff',
                                marginLeft: 10,
                                fontSize: 20,
                                fontFamily: 'Orbitron',
                                fontWeight: '800'
                            }}>iControl Portaria</h1>
                        </div>
                    }
                    <Menu
                        theme="dark"
                        mode="inline"
                        onClick={position => { clickMenuPrinciopal(position) }}
                        defaultSelectedKeys={['1']}
                        selectedKeys={posicaoMenu}
                        openKeys={abrirMenuLateral}
                        onOpenChange={(openKeys) => setAbrirMenuLateral(openKeys)}
                        items={itemsMenuPainelState}
                    />
                </Sider>

                <Layout className={collapsed ? 'siteLayout2' : 'siteLayout'}>
                    <Header
                        style={{
                            position: 'fixed',
                            zIndex: 99,
                            padding: 0,
                            width: collapsedWidth === 0 ? '100%' : collapsed ? 'calc(100% - 77px)' : 'calc(100% - 280px)',
                            // backgroundColor: theme === 'light' ? '#fff' : '#141414'
                            backgroundColor: theme === 'light' ? '#fff' : '#141414',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div>
                            <div style={{ float: "left" }}>
                                {React.createElement(
                                    collapsedWidth === 0 ?
                                        MenuOutlined
                                        :
                                        collapsed ?
                                            MenuUnfoldOutlined : MenuFoldOutlined
                                    ,
                                    {
                                        className: 'trigger',
                                        onClick: toggle
                                    }
                                )}
                            </div>

                            {
                                collapsedWidth === 0 ?
                                    theme === 'light' ?
                                        // <img style={{
                                        //     // margin: 15,
                                        //     width: 165,
                                        //     height: 42
                                        // }} src={ImagemLogoHorizontalCorSemIcone} />
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <img
                                                style={{
                                                    width: 40,
                                                    // marginTop: -20,
                                                    // marginLeft: -20,
                                                    // marginBottom: 5
                                                }}
                                                src={ImagemLogo}
                                            />
                                            <h1
                                                style={{
                                                    fontFamily: 'Orbitron',
                                                    fontWeight: '800',
                                                    fontSize: 16
                                                }}>iControl Portaria</h1>
                                        </div>
                                        :
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <img
                                                style={{
                                                    width: 40,
                                                    // marginTop: -20,
                                                    // marginLeft: -20,
                                                    // marginBottom: 5
                                                }}
                                                src={ImagemLogo}
                                            />
                                            <h1
                                                style={{
                                                    color: '#fff',
                                                    fontFamily: 'Orbitron',
                                                    fontWeight: '800',
                                                    fontSize: 16
                                                }}>iControl Portaria</h1>
                                        </div>
                                    :
                                    <></>

                            }
                        </div>
                        <Dropdown.Button
                            // onClick={handleButtonClick}
                            trigger={['click']}
                            className="dropDownMenu"
                            overlay={menuPerfil}
                            buttonsRender={([leftButton, rightButton]) => ([
                                <></>,
                                <Button
                                    style={{
                                        // marginRight: 10,
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                    type="text">
                                    {
                                        collapsedWidth !== 0 &&
                                        <Text
                                            style={{
                                                marginRight: '6px'
                                            }}
                                        >{nomeUsuarioLogado}</Text>
                                    }
                                    <Avatar size={28} icon={<UserOutlined />} />
                                </Button>

                            ])}

                        />
                        {/* <Dropdown className="dropDownMenu" trigger={['click']} overlay={menuPerfil} >
                            <Button type="text">
                                Opções <DownOutlined />
                            </Button>
                        </Dropdown> */}
                    </Header>
                    <Content
                        className={'siteLayoutBackground'}
                        style={{
                            backgroundColor: theme === 'light' ? '#fff' : '#141414'
                        }}
                    >
                        {children}
                    </Content>
                    {/* <Footer
                        style={{ textAlign: 'center', marginTop: -10, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 0, paddingRight: 0 }}
                    >
                        Desenvolvido por <img style={{
                            width: 90,
                            marginLeft: 5
                        }} src={ImagemGovfacil} />
                    </Footer> */}
                    <Footer
                        style={{
                            paddingLeft: 0, paddingRight: 0, marginTop: -10,
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: collapsedWidth === 0 ? 'column' : 'row',
                        }}
                    >
                        <div
                            style={{
                                width: collapsedWidth === 0 ? '100%' : '50%',
                                display: 'flex',
                                justifyContent: collapsedWidth === 0 ? 'center' : 'flex-end',
                            }}
                        >
                            Desenvolvido por GM Sistemas
                            {/* <img style={{
                                width: 90,
                                marginLeft: 5,
                                height: 21
                            }} src={ImagemGovfacil} /> */}
                        </div>
                        {/* <div
                            style={{
                                width: collapsedWidth === 0 ? '100%' : '50%',
                                display: 'flex',
                                justifyContent: collapsedWidth === 0 ? 'center' : 'flex-end',
                                marginRight: 10
                            }}
                        >
                            <Text
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: 18,
                                    marginLeft: 10,
                                    color: 'red'
                                }}
                            >Sistema em desenvolvimento!</Text>
                        </div> */}
                    </Footer>

                </Layout>
            </Layout>
            {/* Drawer para alterar cor do sistema */}
            <Drawer
                title="Tema do sistema"
                placement="right"
                closable={false}
                onClose={onCloseDrawerColor}
                visible={visibleDrawerColor}
            >
                <Radio.Group
                    onChange={onchangeRadioTheme}
                    defaultValue={theme}
                    size="large">
                    <Radio.Button value="light">Light</Radio.Button>
                    <Radio.Button value="dark">Dark</Radio.Button>
                    {/* <Radio.Button value="c">Beijing</Radio.Button> */}
                </Radio.Group>
            </Drawer>
        </>
    );
};
