import React, { useCallback, useEffect, useState, useRef, useContext } from 'react';
import {
    Row, Col, Typography, notification, Button, Table, Divider,
    Form, Select, Spin
} from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import locale from 'antd/es/locale/pt_BR';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import ptLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns';
import { colInputs } from './GridStyle';

import { VerificaRota } from './../../../services/VerificaSePodeAcessar';
import LayoutDashboard from './../../../layout/LayoutAdmin';
import { urlAtaEmpresa, urlUsuariosUsuarioEmpresas } from './../../../services/urls';
import { api } from './../../../services/apiAxios';
import { EmitirErro } from '../../../services/EmitirErro';
import { SairDoSistema } from '../../../services/LStorage/SairSistema';
import { getToken } from './../../../services/LStorage/token';

// import './style.css';

import {
    convertDataBrParaUS,
    convertDataUSParaBrasilEHora
} from '../../../services/Conversores';
import { GlobalContext } from '../../../contexts/globalContext';
import { FileTextOutlined, SearchOutlined } from '@ant-design/icons';
import { getEmpresaSelecionada } from '../../../services/LStorage/EmpresaSelecionada';
import { removeAcento } from '../../../utils/RemoveAcentos';

const { Text, Title } = Typography;


interface InterfDadosTableAta {
    registros: Array<{
        "id": string,
        "createdAt": string,
        "updatedAt": string,
        "texto": string,
        "status": boolean,
        "empresaId": string,
        "usuario": {
            nome: string
        },
        "usuarioId": string
    }>,
    total: number
}

interface IntefListEmpresas {
    "id": string,
    "createdAt": string,
    "updatedAt": string,
    "ativo": boolean,
    "nome": string,
    "cnpj": string,
    "telefone": string,
    "bairro": string | null,
    "cep": string | null,
    "cidade": string | null,
    "logradouro": string | null,
    "numero": string | null,
    "uf": string | null,
    "deletedAt": string | null
}

interface IntefListUsuario {
    ativo: boolean
    cpf: string | null
    email: string | null
    id: string
    nome: string | null
    telefone: string | null
    updatedAt: string | null
}

const RelatorioAtaList: React.FC = () => {

    const navigate = useNavigate();

    let [searchParams, setSearchParams] = useSearchParams();
    let [queryIdEmpresa, setQuery] = React.useState(
        searchParams.get("empresaId")
    );


    const { theme } = useContext(GlobalContext);

    const refNumeroPaginate = useRef<any>(`1`);
    const refForm = useRef<any>(null);
    const refContagemDoFiltro = React.useRef<Array<any>>([]);
    const refDataListEmpresas = useRef<Array<IntefListEmpresas>>([]);
    const refEmpresaSelecionada = useRef<IntefListEmpresas | null>(null);

    const [loadingScreen, setLoadingScreen] = useState(false);
    const [empresaSelecionado, setEmpresaSelecionado] = useState<IntefListEmpresas>();

    const [dateFechamento, setDateFechamento] = useState<any>(null);
    const [dateAbertura, setDateAbertura] = useState<any>(null);

    const [dataListUsuario, setDataListUsuario] = useState<Array<IntefListUsuario>>([]);
    const [loadingListUsuario, setLoadingListUsuario] = useState(false);


    const [dadosTabelaState, setDadosTabelaState] = useState<InterfDadosTableAta | null>(null);



    const selecEmpresaPorId = useCallback((id: string): IntefListEmpresas | null => {
        let obj = null;
        refDataListEmpresas.current.forEach(val => {
            if (val.id === id) {
                obj = val
            }
        });
        return obj;

    }, []);

    const buscarDadosDaAtaPorEmpresa = useCallback((idEmpresa: string) => {

        const buscaCiaddes = async () => {
            try {
                setLoadingScreen(true);

                let result = await api.get(urlAtaEmpresa + '/' + idEmpresa,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDadosTabelaState(result.data)

                setLoadingScreen(false);

            } catch (error) {
                let msgErro: any = (error as Error);
                setLoadingScreen(false);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'municipios_dkwdfaiweyi')
                }


            }
        }

        buscaCiaddes();

    }, []);

    const buscaUsuarioPorIdEmpresa = useCallback((id: any) => {

        const funcBusca = async () => {
            try {
                setLoadingListUsuario(true);
                let result = await api.get(urlUsuariosUsuarioEmpresas + '/' + id,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDataListUsuario(result.data);

                setLoadingListUsuario(false);

            } catch (error) {
                setLoadingListUsuario(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'usuarios_dkwdfaiweyi')
                }


            }
        }

        funcBusca();
    }, []);

    useEffect(() => {

        let arrayUrl = window.location.pathname.split("/");
        VerificaRota(arrayUrl, navigate);

        const funcExecInicio = async () => {

            let lsEmpresaSelecionada = getEmpresaSelecionada();

            if (lsEmpresaSelecionada) {
                setEmpresaSelecionado(lsEmpresaSelecionada);
                refEmpresaSelecionada.current = lsEmpresaSelecionada

                buscaUsuarioPorIdEmpresa(lsEmpresaSelecionada.id)

                buscarDadosDaAtaPorEmpresa(lsEmpresaSelecionada.id)
            }
        }

        funcExecInicio();

    }, [queryIdEmpresa]);

    const submitBuscarDadosFiltrados = useCallback(() => {

        const buscarDados = async (obj: any) => {
            try {
                // setLoading(true);
                let hrsaidaIni: any = ''
                let hrchegadaIni: any = ''
                let primeiro: boolean = false

                let stringFil = `/${refEmpresaSelecionada.current?.id}`

                if (dateFechamento) {
                    primeiro = true
                    hrsaidaIni = `?data_fechamento=${convertDataBrParaUS(dateFechamento)}`
                    stringFil = stringFil + hrsaidaIni
                }
                if (dateAbertura) {
                    if (!primeiro) {
                        primeiro = true
                        hrchegadaIni = `?data_abertura=${convertDataBrParaUS(dateAbertura)}`
                        stringFil = stringFil + hrchegadaIni
                    } else {
                        hrchegadaIni = `&data_abertura=${convertDataBrParaUS(dateAbertura)}`
                        stringFil = stringFil + hrchegadaIni
                    }
                }

                let usuario = refForm.current.getFieldValue('usuario_id');

                if (usuario) {
                    if (!primeiro) {
                        primeiro = true
                        stringFil = stringFil + `?usuario_id=${usuario}`
                    } else {
                        stringFil = stringFil + `&usuario_id=${usuario}`
                    }
                }

                // paginate
                if (refNumeroPaginate.current) {
                    if (!primeiro) {
                        primeiro = true
                        stringFil = stringFil + `?pagina=${refNumeroPaginate.current}`
                    } else {
                        stringFil = stringFil + `&pagina=${refNumeroPaginate.current}`
                    }
                } else {
                    if (!primeiro) {
                        primeiro = true
                        stringFil = stringFil + `?pagina=1`
                    } else {
                        stringFil = stringFil + `&pagina=1`
                    }
                }

                const result = await api.get(urlAtaEmpresa + stringFil,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                setDadosTabelaState(result.data)

                if (result.data.length === 0) {
                    notification.error({
                        message: 'Atenção!',
                        description:
                            'Nenhum registro foi encontrado',
                    });
                }

            } catch (error) {
                // setLoading(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'perfil_dkwdfaiweyi')
                }


            }
        }

        refForm.current?.validateFields()
            .then((values: any) => {

                buscarDados(values);

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, [dateFechamento, dateAbertura]);

    const columns = [
        {
            title: 'Usuário',
            dataIndex: 'usuario',
            // ...useGetColumnSearchProps('nome'),
            width: '6%',
            // sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            showSorterTooltip: false,
            render: (value: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: 8
                    }}
                >
                    <Text>
                        {
                            value?.nome ? value?.nome : ''
                        }
                    </Text>
                </div>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            // ...useGetColumnSearchProps('nome'),
            width: '4%',
            // sorter: (a: any, b: any) => a.nome.localeCompare(b.nome),
            showSorterTooltip: false,
            render: (value: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: 8
                    }}
                >
                    <Text>
                        {
                            value ? 'Ata aberta' : 'Ata fechada'
                        }
                    </Text>
                </div>
            ),
        },
        {
            title: 'Criado em',
            dataIndex: 'createdAt',
            // ...useGetColumnSearchPropsData('createdAt'),
            width: '2%',
            // sorter: (a: any, b: any) => {

            //     let aMonMen: any = null
            //     let bMonMen: any = null
            //     if (a.createdAt) {
            //         aMonMen = moment(a.createdAt).unix()
            //     } else {
            //         aMonMen = 0
            //     }
            //     if (b.createdAt) {
            //         bMonMen = moment(b.createdAt).unix()
            //     } else {
            //         bMonMen = 0
            //     }

            //     return aMonMen - bMonMen
            // },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),
            render: (criado: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: 8
                    }}
                >
                    <Text>
                        {
                            criado ? convertDataUSParaBrasilEHora(criado) : '-'
                        }
                    </Text>
                </div>
            ),
        },
        {
            title: 'Finalizado em',
            dataIndex: 'updatedAt',
            // ...useGetColumnSearchPropsData('createdAt'),
            width: '2%',
            // sorter: (a: any, b: any) => {

            //     let aMonMen: any = null
            //     let bMonMen: any = null
            //     if (a.updatedAt) {
            //         aMonMen = moment(a.updatedAt).unix()
            //     } else {
            //         aMonMen = 0
            //     }
            //     if (b.updatedAt) {
            //         bMonMen = moment(b.updatedAt).unix()
            //     } else {
            //         bMonMen = 0
            //     }

            //     return aMonMen - bMonMen
            // },
            showSorterTooltip: false,
            // sorter: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),
            render: (criado: any, record: any) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: 8
                    }}
                >
                    {
                        !record.status &&
                        <Text>
                            {
                                criado ? convertDataUSParaBrasilEHora(criado) : '-'
                            }
                        </Text>
                    }
                </div>
            ),
        },
        {
            title: 'Ações',
            dataIndex: 'id',
            // ...getColumnSearchProps('created_at'),
            width: '1%',
            // sorter: (a: any, b: any) => a.created_at.localeCompare(b.created_at),
            render: (idAta: any, record: any) => (
                <>
                    <Row
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: 8
                        }}
                    >
                        <Button
                            type="primary"
                            style={{ marginLeft: 5 }}
                            onClick={() => {
                                navigate('/relatorios/ata/' + idAta)
                            }}
                        >
                            <SearchOutlined />
                        </Button>
                    </Row>
                </>
            ),
        },
    ];

    return (
        <LayoutDashboard>
            {/* <Row style={{
                display: 'flex',
                alignItems: 'flex-end'
            }}>
                <Col {...colSelect} style={{ marginBottom: 5, marginRight: 5 }}>
                    <div
                        style={{ marginBottom: 5 }}
                    >
                        <Text style={{ margin: 1 }} >{'Selecione uma empresa:'}</Text>
                    </div>
                    <Select
                        showSearch
                        placeholder="Digite o nome de uma empresa"
                        optionFilterProp="children"
                        // className="botaoListVendedorUser"
                        onChange={onChangeSelectEmpresas}
                        value={selectIdEmpresa}
                        loading={loadingListEmpresas}
                        notFoundContent={loadingListEmpresas ? <Spin size="small" /> : null}
                        // disabled={!hasSelectedAdd}
                        // defaultValue='Usuários'
                        style={{
                            width: '100%',
                        }}
                        filterOption={(input: any, option: any) => {
                            let textDigit = removeAcento(input)
                            let listCidade = removeAcento(option?.children);
                            return listCidade.indexOf(textDigit) >= 0
                        }}
                    >
                        {
                            dataListEmpresas.map((item) => {
                                return (
                                    <Select.Option
                                        value={item.id}
                                        key={item.id}
                                    >
                                        {item.nome}
                                    </Select.Option>
                                )
                            })
                        }
                    </Select>
                </Col>
                <Col style={{ marginBottom: 5 }}>
                </Col>
            </Row> */}
            <Row>
                <Col span={24}>
                    <Title level={4}>
                        <FileTextOutlined style={{ fontSize: 25, marginRight: 10 }} />
                        {`Ata`}
                    </Title>
                    <Divider />
                </Col>
            </Row>
            {empresaSelecionado &&
                <Form
                    ref={refForm}
                    name="formRelatorioVeiculosProprios"
                    layout="vertical"
                    initialValues={{
                        remember: true,
                    }}
                    onFinishFailed={() => { }}
                >
                    <Row>
                        <Col {...colInputs}>
                            <Form.Item
                                name="data_abertura"
                                label="Data de Abertura"
                                style={{ margin: 5 }}
                            >
                                <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                    <DatePicker
                                        value={dateAbertura}
                                        onChange={setDateAbertura}
                                        required={false}
                                        style={{
                                            width: '100%'
                                        }}
                                        okLabel="Ok"
                                        clearLabel="Limpar"
                                        cancelLabel="Cancelar"
                                        clearable
                                        format="dd/MM/yyyy"
                                    />
                                </MuiPickersUtilsProvider>
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                name="data_fechamento"
                                label="Data de Fechamento"
                                style={{ margin: 5 }}
                            >
                                <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                    <DatePicker
                                        value={dateFechamento}
                                        onChange={setDateFechamento}
                                        required={false}
                                        style={{
                                            width: '100%'
                                        }}
                                        okLabel="Ok"
                                        clearLabel="Limpar"
                                        cancelLabel="Cancelar"
                                        clearable
                                        format="dd/MM/yyyy"
                                    />
                                </MuiPickersUtilsProvider>
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                        <Form.Item
                                name="usuario_id"
                                label="Usuário"
                                style={{ margin: 5 }}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder="Digite o nome do usuario"
                                    optionFilterProp="children"
                                    // className="botaoListVendedorUser"
                                    // onChange={onChangeSelectVeiculo}
                                    // value={veiculoSecionado}
                                    loading={loadingListUsuario}
                                    notFoundContent={loadingListUsuario ? <Spin size="small" /> : null}
                                    // disabled={!hasSelectedAdd}
                                    // defaultValue='Usuários'
                                    style={{
                                        width: '100%',
                                    }}
                                    filterOption={(input: any, option: any) => {
                                        let textDigit = removeAcento(input)
                                        let listCidade = removeAcento(option?.children);
                                        return listCidade.indexOf(textDigit) >= 0
                                    }}
                                >
                                    {
                                        dataListUsuario.map((item) => {
                                            return (
                                                <Select.Option
                                                    value={item.id}
                                                    key={item.id}
                                                >
                                                    {item.nome}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <div className="botaoSalvarStep" style={{
                                flexDirection: 'row',
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}>
                                <Button
                                    key="submit"
                                    type="primary"
                                    style={{
                                        marginTop: 20
                                    }}
                                    onClick={() => {
                                        submitBuscarDadosFiltrados()
                                    }}
                                >
                                    {"Filtrar"}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            }
            <Table
                className='marginTopTablesListUsuarios'
                rowKey={(record) => record.id}
                loading={loadingScreen}
                // onRow={(record, rowIndex) => {
                //     return {
                //         onClick: event => { onclickTable(record, rowIndex) }
                //     };
                // }}
                // rowClassName={(record, index) => record?.empresaUsuarios[0]?.gestor ? 'table-row-gestor' : 'table-row-normal'}
                // rowSelection={rowSelection}
                columns={columns}
                dataSource={dadosTabelaState?.registros ? dadosTabelaState.registros : []}
                scroll={{ x: 650 }}
                onChange={
                    (pagination, filters, sorter, extra) => {
                        refContagemDoFiltro.current = extra.currentDataSource
                    }
                }
                title={(registros) => {

                    return (
                        <div
                            style={{
                                backgroundColor: theme === 'light' ? '#FAFAFA' : '#1D1D1D',
                                padding: 10,
                                margin: -8,
                                marginBottom: 0.5
                            }}
                        >
                            {
                                dadosTabelaState && dadosTabelaState?.registros &&
                                <Text>
                                    {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                    {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                        dadosTabelaState.registros.length :
                                        refContagemDoFiltro.current.length} num total de ${dadosTabelaState.registros.length} `}
                                </Text>
                            }
                        </div>
                    )
                }}
                footer={(registros) => {
                    return (
                        <div
                            style={{
                                textAlign: 'end'
                            }}
                        >
                            {
                                dadosTabelaState && dadosTabelaState?.registros &&
                                <Text>
                                    {/* {`Total: ${dadosTabelaState.length} registros.`} */}
                                    {`Mostrando: ${registros.length} de ${refContagemDoFiltro.current.length === 0 ?
                                        dadosTabelaState.registros.length :
                                        refContagemDoFiltro.current.length} num total de ${dadosTabelaState.registros.length} `}
                                </Text>
                            }
                        </div>
                    )
                }}
                size="small"
                pagination={{
                    locale: { items_per_page: '' },
                    // showSizeChanger: true,//isso faz que o usuario possa escolher quantos ele quer por pg
                    defaultPageSize: 50,
                    total: dadosTabelaState?.total,//mandar o total de registros
                    pageSize: 50,//quantos por pagina
                    onChange(page, pageSize) {
                        // console.log(page)
                        refNumeroPaginate.current = page
                        submitBuscarDadosFiltrados()
                        // if (page == 1) {
                        //     setDadosTabelaState(constData1)
                        // } else {
                        //     setDadosTabelaState(constData2)

                        // }
                    },
                    position: ['topRight', 'bottomRight'],
                    pageSizeOptions: ['5', '10', '20', '50', '100']
                }}
                locale={locale.Table}
            />


        </LayoutDashboard>
    );
}

export default RelatorioAtaList;
