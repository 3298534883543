import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Login from './../pages/Login';

import Usuarios from './../pages/Usuarios';
import UsuariosAddOrUpdate from './../pages/Usuarios/components/AddOrUpdate';
import PrimeiroAcesso from './../pages/PrimeiroAcesso';
import RecuperarSenha from './../pages/RecuperarSenha';
import RecuperarSenhaNovaSenha from './../pages/RecuperarSenha/components/NovaSenha';
import RecuperarSenhaNovaSenhaAplicativo from './../pages/RecuperarSenha/components/NovaSenhaAplicativo';
import PerfilUsuario from './../pages/PerfilUsuario';

import Dashboard from './../pages/Dashboard';
import ListEmpresasGroup from './../pages/ListEmpresasGroup';
import VeiculosProprios from './../pages/Relatorios/VeiculosProprios';
import { Configuracoes } from '../pages/Configuracoes';
import { ConfiguracoesPage } from '../pages/Configuracoes/components';
import RelatorioAtaList from '../pages/Relatorios/Ata';
import RelatorioAtaView from '../pages/Relatorios/Ata/Visualizar';
import ConvidadoEventoByEmpresa from '../pages/Relatorios/Convidado';
import Visitante from '../pages/Relatorios/Visitante';
import VeiculoTerceiro from '../pages/Relatorios/VeiculoTerceiro';
import CarroForte from '../pages/Relatorios/CarroForte';
import SelecionarEmpresa from '../pages/SelecionarEmpresa';
import { VeiculosPropriosUpdate } from '../pages/Relatorios/VeiculosProprios/Editar';

const TodasRotas: React.FC = () => {

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/dashboard" element={<Dashboard />} />

                    <Route path="/usuarios" element={<Usuarios />} />
                    <Route path="/usuarios/:id" element={<UsuariosAddOrUpdate />} />
                    <Route path="/primeiro-acesso" element={<PrimeiroAcesso />} />
                    <Route path="/perfil/:id" element={<PerfilUsuario />} />
                    <Route path="/recuperar-senha" element={<RecuperarSenha />} />
                    <Route path="/recuperar-senha/:codigo" element={<RecuperarSenhaNovaSenha />} />
                    <Route path="/recuperar-senha/app/:codigo" element={<RecuperarSenhaNovaSenhaAplicativo />} />

                    <Route path="/empresas" element={<ListEmpresasGroup />} />
                    <Route path="/relatorios/veiculos-proprios" element={<VeiculosProprios />} />
                    <Route path="/relatorios/veiculos-proprios/editar" element={<VeiculosPropriosUpdate />} />
                    <Route path="/relatorios/evento-convidado" element={<ConvidadoEventoByEmpresa />} />
                    <Route path="/configuracoes" element={<Configuracoes />} />
                    <Route path="/configuracoes/:page" element={<ConfiguracoesPage />} />
                    <Route path="/relatorios/ata" element={<RelatorioAtaList />} />
                    <Route path="/relatorios/ata/:id" element={<RelatorioAtaView />} />
                    <Route path="/relatorios/visitante" element={<Visitante />} />
                    <Route path="/relatorios/veiculo-terceiro" element={<VeiculoTerceiro />} />
                    <Route path="/relatorios/carro-forte" element={<CarroForte />} />

                    <Route path="/selecionar-empresa" element={<SelecionarEmpresa />} />

                </Routes>
            </BrowserRouter>
        </>
    )
}

export default TodasRotas;
