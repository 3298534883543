import { NavigateFunction } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import { getToken } from "./../LStorage/token";
import { getRefreshToken, getUsuario } from "../LStorage/UsuarioERefresh";
import { getEmpresaSelecionada } from "../LStorage/EmpresaSelecionada";
// import { getRefreshToken } from "../LStorage/UsuarioERefresh";

export const VerificaRota = (
    path: Array<string>,
    navigate: NavigateFunction,
    redirecionar = true,
    temPermissao = true
) => {

    // console.log(path[1])
    if (getToken() && getRefreshToken()) {
        // navigate('/dashboard');

        if (getUsuario()?.primeiro_acesso) {

            if (path[1] !== 'primeiro-acesso') {
                navigate('/primeiro-acesso');
            }

            return false
        }

        if (!getEmpresaSelecionada()) {

            if (path[1] !== 'selecionar-empresa') {
                navigate('/selecionar-empresa');
            }

            return false
        }

        if (!temPermissao) {

            navigate('/dashboard');

            return false
        }

        return true;
    } else {

        redirecionar && navigate('/');

        return false;

        // setRotasPermitidas(Array(AllRotas[0]))
    }
}

export const validacaoPermissao = (permissao: Array<string>): boolean => {
    const token = getToken();

    if (token) {
        // sub é o uuid
        const user = jwt_decode<{ permissoes: Array<string>, sub: string }>(token);

        if (user.permissoes.length > 0) {

            //se o usuario tem algumas dessas permissoes isso é o some
            const hasAllPermissions = user.permissoes.some(permission => {
                return permissao.includes(permission);
            });

            //caso nao incluir
            if (!hasAllPermissions) {
                return false;
            }

            return true;
        }

        return false;
    }
    return true;
};
