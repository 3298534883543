import { useCallback, useEffect, useRef, useState } from 'react';

import ptLocale from "date-fns/locale/pt-BR";
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { Col, Row, Typography, Button, Form, notification, Input, Select, FormInstance, Divider, Modal } from 'antd';
import { mask, unMask } from '../../../../utils/MascaraDeCampos';
import { Loading } from '../../../../components/Loading';
import { SairDoSistema } from '../../../../services/LStorage/SairSistema';
import { EmitirErro } from '../../../../services/EmitirErro';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../../services/apiAxios';
import { getToken } from '../../../../services/LStorage/token';
import { urlVeiculoProprioTipo, urlVeiculoProprio, urlVeiculoProprioMovimentoEdit } from '../../../../services/urls';
import { colFull, colInputs } from './GridStyle';
import { removeAcento } from '../../../../utils/RemoveAcentos';
import { CarOutlined } from '@ant-design/icons';
import { validacaoPermissao } from '../../../../services/VerificaSePodeAcessar';
import LayoutDashboard from '../../../../layout/LayoutAdmin';
import { convertDataBrHoraParaUSHora } from '../../../../services/Conversores';

const { Text, Title, Link } = Typography;

interface interfaceTipoVeiculo {
    tipo: string,
    id: number
}


// export const VeiculosPropriosUpdate = ({ id, voltarPg(), idEmpresa }: PropsModal) => {
export const VeiculosPropriosUpdate = () => {

    const navigate = useNavigate();

    const refIdMovimento = useRef<any>(null);
    const refForm = useRef<any>(null);

    const [estaEditando, setEstaEditando] = useState(true);

    const [loading, setLoading] = useState(false);

    const [dateHourSaida, setDateHourSaida] = useState<any>(null);
    const [dateHourChegada, setDateHourChegada] = useState<any>(null);

    //desmontar ou destruir component
    useEffect(() => () => {

        let dadosLocalStorage: string | null = localStorage.getItem('@SisPortaria:vpEdit');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }

        if (localUserData) {
            localStorage.removeItem('@SisPortaria:vpEdit');
        }

    }, []);

    const voltarPg = useCallback(() => {
        navigate('/relatorios/veiculos-proprios')
    }, [])

    const configuracoesIniciaisDeCriar = useCallback(() => {

        //pega dados do localStorage se existir
        let dadosLocalStorage: string | null = localStorage.getItem('@SisPortaria:vpEdit');
        let localUserData: any = null;
        if (typeof dadosLocalStorage == 'string') {
            localUserData = JSON.parse(dadosLocalStorage);
        }
        // localUserData?.step4.horarioDasNotificacoes? localUserData?.step4.horarioDasNotificacoes: undefined,

        if (localUserData && localUserData?.id) {

            refIdMovimento.current = localUserData?.id

            refForm.current?.setFieldsValue(
                {
                    km_chegada: localUserData?.km_chegada ? localUserData?.km_chegada : undefined,
                    km_saida: localUserData?.km_saida ? localUserData?.km_saida : undefined,
                    destino: localUserData?.destino ? localUserData?.destino : undefined,
                    // data_hora_chegada: Date;
                    // data_hora_saida: Date;
                }
            )

            if (localUserData?.data_hora_saida) {
                setDateHourSaida(new Date(localUserData.data_hora_saida))
            }
            if (localUserData?.data_hora_chegada) {
                setDateHourChegada(new Date(localUserData.data_hora_chegada))
            }
        }

    }, []);


    useEffect(() => {

        configuracoesIniciaisDeCriar()
        // if (typeof id === 'string') {
        //     if (id === 'criar') {

        //         //adicionar
        //     } else {
        //         setEstaEditando(true)
        //         buscaDadosUsuario();

        //     }
        // } else {

        //     //adicionar
        //     configuracoesIniciaisDeCriar(idEmpresa)
        // }

    }, []);


    const submitBotaoEditar = useCallback(() => {


        const salvarDadosEditar = async (obj: any) => {
            try {
                setLoading(true);

                await api.put(urlVeiculoProprioMovimentoEdit + '/' + refIdMovimento.current,
                    obj,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getToken()
                        }
                    });

                notification.success({
                    message: 'Sucesso',
                    description:
                        'Dados editados com sucesso!',
                });

                setLoading(false);
                voltarPg()

            } catch (error) {
                setLoading(false);
                let msgErro: any = (error as Error);

                if (msgErro?.message === 'USUáRIO NãO AUTORIZADO.') {
                    SairDoSistema();
                    notification.error({
                        message: 'Sessão expirada',
                        description:
                            'Sua sessão expirou, realize o login novamente!',
                    });
                    navigate('/');
                } else {
                    EmitirErro(error, true, 'addoredituser_dkwdfaiweyi')
                }


            }
        }

        refForm.current?.validateFields()
            .then((values: any) => {

                if (!refIdMovimento.current) {
                    notification.error({
                        message: 'Erro',
                        description:
                            'Volte e selecione um veiculo novamente!',
                    });

                    return false
                }


                if (values.km_saida) {
                    if (isNaN(Number(values.km_saida))) {
                        notification.error({
                            message: 'Erro',
                            description:
                                'Km saída invalido!',
                        });

                        return false
                    }
                }
                if (values.km_chegada) {
                    if (isNaN(Number(values.km_chegada))) {
                        notification.error({
                            message: 'Erro',
                            description:
                                'Km chegada invalido!',
                        });
                        return false
                    }
                }

                if (dateHourSaida) {
                    values.data_hora_saida = convertDataBrHoraParaUSHora(dateHourSaida)
                } else {
                    values.data_hora_saida = undefined
                }
                if (dateHourChegada) {
                    values.data_hora_chegada = convertDataBrHoraParaUSHora(dateHourChegada)
                } else {
                    values.data_hora_chegada = undefined
                }

                salvarDadosEditar(values);

            })
            .catch((errorInfo: any) => {
                notification.error({
                    message: 'Erro',
                    description:
                        'Preencha os campos obrigatorios!',
                });
            });


    }, [dateHourSaida, dateHourChegada]);


    return (
        <LayoutDashboard>
            <Loading
                loading={loading}
            >
                <Row>
                    <Col {...colFull}>
                        <Title level={4}>
                            <CarOutlined style={{ fontSize: 25, marginRight: 10 }} />
                            {`${estaEditando ? 'Editar' : 'Adicionar'} Veículos Próprios`}
                        </Title>
                        <div
                            style={{
                                display: 'flex'
                            }}
                        >
                            <Link
                                onClick={() => [
                                    voltarPg()
                                ]}
                                style={{
                                    marginRight: 5
                                }}
                            >
                                <Text
                                    type='secondary' underline
                                >Relatórios Veíc. Próprios</Text>
                            </Link>

                            <Text
                                style={{
                                    marginRight: 5
                                }} type='secondary'
                            >/</Text>

                            <Text style={{
                                marginRight: 5
                            }}>
                                {
                                    `${estaEditando ? 'Editar' : 'Adicionar'}`
                                }
                            </Text>
                        </div>
                        <Divider />
                    </Col>
                </Row>
                <Form
                    ref={refForm}
                    name="adicionarUsuario"
                    layout="vertical"
                    // onFieldsChange={onFieldsChange}
                    initialValues={{
                        remember: true,
                    }}
                    // onFinish={onFinish}
                    // onFinishFailed={() => { }}
                    autoComplete="off"
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                style={{
                                    margin: 5
                                }}
                                name="destino"
                                label="Destino"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor digite o destino!',
                                    },
                                ]}
                            >
                                <Input autoComplete="off"
                                    placeholder="Destino"
                                    onChange={() => {

                                        const text = refForm.current?.getFieldValue('destino')
                                        const first = text.charAt(0).toUpperCase()
                                        const rest = text.substring(1);

                                        refForm.current?.setFieldsValue(
                                            { 'destino': first + rest }
                                        )

                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                style={{
                                    margin: 5
                                }}
                                name="km_saida"
                                label="Km saída"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor digite o km saída!',
                                    },
                                ]}
                            >
                                <Input
                                    autoComplete="off"
                                    placeholder="km saída"
                                />
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                style={{
                                    margin: 5
                                }}
                                name="km_chegada"
                                label="Km chegada"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Por favor digite o km chegada!',
                                    },
                                ]}
                            >
                                <Input
                                    autoComplete="off"
                                    placeholder="km chegada"
                                />
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                name="data_hora_saida"
                                label="Data saída"
                                style={{ margin: 5 }}
                            >
                                <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                    <DateTimePicker
                                        value={dateHourSaida}
                                        onChange={setDateHourSaida}
                                        required={false}
                                        style={{
                                            width: '100%'
                                        }}
                                        okLabel="Ok"
                                        clearLabel="Limpar"
                                        cancelLabel="Cancelar"
                                        clearable
                                        format="dd/MM/yyyy HH:mm"
                                    />
                                </MuiPickersUtilsProvider>
                            </Form.Item>
                        </Col>
                        <Col {...colInputs}>
                            <Form.Item
                                name="data_hora_chegada"
                                label="Data chegada"
                                style={{ margin: 5 }}
                            >
                                <MuiPickersUtilsProvider locale={ptLocale} utils={DateFnsUtils} >
                                    <DateTimePicker
                                        value={dateHourChegada}
                                        onChange={setDateHourChegada}
                                        required={false}
                                        style={{
                                            width: '100%'
                                        }}
                                        okLabel="Ok"
                                        clearLabel="Limpar"
                                        cancelLabel="Cancelar"
                                        clearable
                                        format="dd/MM/yyyy HH:mm"
                                    />
                                </MuiPickersUtilsProvider>
                            </Form.Item>
                        </Col>
                        <Col {...colFull}>
                            <div className="botaoSalvarStep" style={{
                                flexDirection: 'row',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                // background: 'red'
                            }}>
                                <Button
                                    // type="primary"
                                    style={{
                                        marginTop: 20,
                                        marginRight: 5
                                    }}
                                    onClick={() => {
                                        voltarPg()
                                    }}
                                >
                                    {"Cancelar"}
                                </Button>

                                <Button
                                    key="submit"
                                    type="primary"
                                    style={{
                                        marginTop: 20,
                                        marginRight: 5
                                    }}
                                    onClick={() => {
                                        submitBotaoEditar();
                                    }}
                                >
                                    {"Salvar"}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Loading>
        </LayoutDashboard>
    );
}
